import React from 'react';
import LoginComponent from "../../components/LoginComponent/LoginComponent";


class Login extends React.Component{
    render(){
        return(
            <React.Fragment>
                <LoginComponent/>
            </React.Fragment>
        )
    }
}

export default Login;