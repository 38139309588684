import React, { useState, useEffect, Fragment } from 'react';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import axiosClient from "../../services/apiClient";
import BootstrapTable from "react-bootstrap-table-next";
import NumberFormat from "react-number-format";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from '@material-ui/core/styles';
import {
  API_URL,
  GET_REQUESTED_ORDER_RECEIPTS_PDF,
  GET_CASH_RECEIPT_BY_ORDER
} from "../../API";
import { makeStyles } from '@material-ui/core/styles';
import { formatDate } from "../../utils/dates";
import InputAdornment from "@material-ui/core/InputAdornment";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import Logo from '../../images/Logo_flomecol.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#336636',
  },
  tabsRoot: {
    borderBottom: '1px solid #1d1d1d',
  },
  tabsIndicator: {
    backgroundColor: '#ffffff',
    height: "4px",
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: "\"Montserrat\", sans-serif !important",
    fontSize: "18px",
    '&:hover': {
      color: '#ffffff',
      opacity: 5,
    },
    '&:focus': {
      color: '#ffffff',
    },
  },
  tabSelected: {},
}));

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#336636',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#336636',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#336636',
        borderWidth: '2px',
      },
      '&:hover fieldset': {
        borderColor: '#336636',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#336636',
      },
    },
  },
})(TextField);


const CreateCashReceiptComponentModal = ({
  value,
  isReceiptModalOpen,
  setIsReceiptModalOpen,
  currentRequestedOrder,
  rowStyle2,
  paginationFactory,
  newReceipt,
  handleNewReceiptTotalAmountChange,
  NumberFormatCustom,
  handleNewReceiptChange,
  doCreateCashReceipt,
  permissions
}) => {

  const classes = useStyles()
  const [receipts, setReceipts] = useState([])
  const [loading, setLoading] = useState(false)

  const receiptColumns = [
    {
      dataField: 'id',
      text: '# recibo de caja',
      sort: true
    }, {
      dataField: 'received_from',
      text: 'Cliente',
      sort: true
    }, {
      dataField: 'business_name',
      text: 'Razón Social',
      sort: true
    }, {
      dataField: 'business_city',
      text: 'Ciudad',
      sort: true
    }, {
      dataField: 'date',
      text: 'Fecha',
    }, {
      dataField: 'total_amount',
      text: 'Valor',
    }, {
      dataField: 'remaining',
      text: 'Saldo',
    }, {
      dataField: 'actions',
      text: 'Acciones',
      classes: 'actions-custom-cell'
    }
  ];

  useEffect(() => {
    getOrderReceipts()
  }, [currentRequestedOrder])

  const getOrderReceipts = () => {
    setLoading(true)
    axiosClient.get(`${GET_CASH_RECEIPT_BY_ORDER}${currentRequestedOrder.id}`).then((response) => {
      formatReceipts(response?.data?.data || [])
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      if (error.response && error.response.data && error.response.data.message) {
        alert(error.response.data.message)
      } else {
        alert("ERROR: error de servidor cargando listado de ordenes de pedido.")
      }
    })
  }

  const formatReceipts = (receipts) => {
    let total_amount_receipts = 0;
    receipts.forEach((receipt) => {
      receipt.seller = currentRequestedOrder.seller;
      total_amount_receipts += receipt.total_amount;

      receipt.total_amount = <NumberFormat
        value={receipt.total_amount}
        displayType={'text'}
        prefix={'$'}
        decimalScale={0}
        thousandSeparator={","}
        decimalSeparator={"."}
        fixedDecimalScale={true}
      />;

      receipt.remaining = <NumberFormat
        value={receipt.remaining}
        displayType={'text'}
        prefix={'$'}
        decimalScale={0}
        thousandSeparator={","}
        decimalSeparator={"."}
        fixedDecimalScale={true}
      />;

      receipt.actions = <React.Fragment>
        {
          permissions.canView &&
          <a
            href={API_URL + GET_REQUESTED_ORDER_RECEIPTS_PDF + receipt.id}
            target='_blank'
            rel='noopener noreferrer'
            className='download-order-pdf-eye'
          >
            <Button
              className='coin-button-table-disable'
            >
              <RemoveRedEye />
            </Button>

          </a>
        }
      </React.Fragment>
    })
    setReceipts(receipts)
  }

  return (
    <Fragment>
      <LoadingComponent loadingImage={Logo} loadingText={'Cargando recibos de caja.'} loadingState={loading}/>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isReceiptModalOpen}
        onClose={() => setIsReceiptModalOpen(false)}
        className='flomecolModalContainer'
      >
        <div className='flomecolModalContent'>

          <Grid container spacing={0}>

            <AppBar position="static" className={classes.root}>
              <Tabs variant="fullWidth" value={value} classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}>
                <Tab label={`LISTADO DE RECIBOS DE CAJA - ORDEN DE PEDIDO # ${currentRequestedOrder ? currentRequestedOrder.id : ''}`} classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
              </Tabs>
            </AppBar>

            <Grid container spacing={0}>
              <Grid item xs={12}>
                <BootstrapTable
                  keyField='id'
                  data={receipts || {}}
                  columns={receiptColumns}
                  rowStyle={rowStyle2}
                  classes='bootstrap-table'
                  wrapperClasses='bootstrap-table-wrapper'
                  headerClasses='bootstrap-table-header'
                  pagination={paginationFactory()}
                />
              </Grid>
            </Grid>

          </Grid>

          {
            currentRequestedOrder && value === 0 &&
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className='modalTitle'><h2>CREAR NUEVO RECIBO DE CAJA - ORDEN DE PEDIDO #{currentRequestedOrder.id}</h2></div>
              </Grid>

              <Grid item xs={12} md={12} className='inputTextFieldContainer'>

                <Grid container spacing={0}>

                  <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                    <TextField
                      id="outlined-city"
                      label="Ciudad"
                      className='inputTextField'
                      name='city'
                      value={currentRequestedOrder.client_city}
                      margin="normal"
                      variant="outlined"
                      disabled={true}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                    <TextField
                      id="outlined-date"
                      label="Fecha"
                      className='inputTextField'
                      name='date'
                      value={formatDate(new Date())}
                      margin="normal"
                      variant="outlined"
                      disabled={true}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} className='inputTextFieldContainer'>
                    <TextField
                      id="outlined-client-name"
                      label="Recibido de"
                      className='inputTextField'
                      name='client_name'
                      value={currentRequestedOrder.client_name}
                      margin="normal"
                      variant="outlined"
                      disabled={true}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                    <TextField
                      id="outlined-business-name"
                      label="Razón Social"
                      className='inputTextField'
                      name='business_name'
                      value={currentRequestedOrder.business_name}
                      margin="normal"
                      variant="outlined"
                      disabled={true}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                    <TextField
                      id="outlined-nit"
                      label="NIT"
                      className='inputTextField'
                      name='nit'
                      value={currentRequestedOrder.nit}
                      margin="normal"
                      variant="outlined"
                      disabled={true}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                    <TextField
                      id="outlined-client-department"
                      label="Departamento"
                      className='inputTextField'
                      name='client_department'
                      value={currentRequestedOrder.department}
                      margin="normal"
                      variant="outlined"
                      disabled={true}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                    <TextField
                      id="outlined-client-city"
                      label="Ciudad"
                      className='inputTextField'
                      name='client_city'
                      value={currentRequestedOrder.client_city}
                      margin="normal"
                      variant="outlined"
                      disabled={true}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                    <TextField
                      id="outlined-client-neighborhood"
                      label="Barrio"
                      className='inputTextField'
                      name='client_neighborhood'
                      value={currentRequestedOrder.neighborhood}
                      margin="normal"
                      variant="outlined"
                      disabled={true}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                    <TextField
                      id="outlined-invoice-number"
                      label="Número de factura"
                      className='inputTextField'
                      name='invoice_number'
                      value={currentRequestedOrder.invoice_number}
                      margin="normal"
                      variant="outlined"
                      disabled={true}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                    <TextField
                      id="outlined-real-amount"
                      label="Valor real de la factura"
                      className='inputTextField'
                      name='real_amount_aux∫'
                      value={currentRequestedOrder.real_amount_aux}
                      margin="normal"
                      variant="outlined"
                      disabled={true}
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        endAdornment: <InputAdornment position="end">COP</InputAdornment>,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                    <TextField
                      id="outlined-remaining"
                      label="Saldo"
                      className='inputTextField'
                      name='aux_remaining'
                      value={currentRequestedOrder.aux_remaining}
                      margin="normal"
                      variant="outlined"
                      disabled={true}
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        endAdornment: <InputAdornment position="end">COP</InputAdornment>,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                    <CssTextField
                      id="outlined-name"
                      label="Valor"
                      className='inputTextField'
                      name='total_amount'
                      value={newReceipt.total_amount}
                      onBlur={handleNewReceiptTotalAmountChange}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        endAdornment: <InputAdornment position="end">COP</InputAdornment>,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                    <CssTextField
                      id="outlined-ramining"
                      label="Nuevo Saldo"
                      className='inputTextField'
                      name='ramining'
                      value={currentRequestedOrder.aux_remaining - newReceipt.total_amount}
                      margin="normal"
                      variant="outlined"
                      disabled={true}
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        endAdornment: <InputAdornment position="end">COP</InputAdornment>,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} className='inputTextFieldContainer'>
                    <CssTextField
                      id="outlined-multiline-static"
                      label="Por concepto de:"
                      multiline
                      rows="4"
                      className='inputTextField'
                      margin="normal"
                      variant="outlined"
                      name='description'
                      value={newReceipt.description}
                      onChange={handleNewReceiptChange}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={12} className='inputTextFieldContainer'>
                    <CssTextField
                      id="outlined-multiline-static"
                      label="Observaciones Generales:"
                      multiline
                      rows="4"
                      className='inputTextField'
                      margin="normal"
                      variant="outlined"
                      name='notes'
                      value={newReceipt.notes}
                      onChange={handleNewReceiptChange}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} className='inputTextFieldContainer'>
                    <Button variant="contained" color="primary" onClick={doCreateCashReceipt} fullWidth>
                      CREAR NUEVO RECIBO DE CAJA
                    </Button>
                  </Grid>

                </Grid>

              </Grid>

            </Grid>
          }


        </div>
      </Modal>
    </Fragment>
  )
}

export default CreateCashReceiptComponentModal;