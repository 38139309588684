import { getUser } from '../utils/auth';

const AccessControl = ({
  allowedRoles,
  children,
  renderNoAccess,
}) => {

  const user = getUser();

  if (allowedRoles.includes(user.rol)) {
    return children;
  }

  return renderNoAccess();
};

export default AccessControl;