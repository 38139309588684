import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getAuthToken } from './auth'

const PrivateRoute = ({ children, ...rest }) => {
  const isAuthenticated = getAuthToken();
  return (
    <Route {...rest}>
      {isAuthenticated
        ?
        children
        :
        <Redirect to="/" />
      }
    </Route>
  )
}

export default PrivateRoute;