import React, { useEffect, useState } from 'react';
import axiosClient from "../../services/apiClient";
import { API_URL, READ_PRODUCTS, CREATE_PRODUCT, CHANGE_PRODUCT_STATUS, UPDATE_PRODUCT, DELETE_PRODUCT } from "../../API";
import {Redirect} from "react-router-dom";
import Logo from "../../images/Logo_flomecol.svg";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import AddCircleIcon from "@material-ui/icons/AddCircleOutline";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";


function NumberFormatCustom(props){
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalScale={0}
            thousandSeparator={","}
            decimalSeparator={"."}
            fixedDecimalScale={true}
            prefix="$ "
        />
    );
}


const AdminProductsComponent = (props) => {

    

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true
        },{
            dataField: 'product_name',
            text: 'Nombre',
            sort: true
        },{
            dataField: 'product_presentation',
            text: 'Presentación',
        },{
            dataField: 'product_price',
            text: 'Precio',
        },{
            dataField: 'status',
            text: 'Estado'
        },{
            dataField: 'actions',
            text: 'Acciones',
        }
    ];

    const rowStyle2 = (row, rowIndex) => {
        const style = {};
        if (rowIndex % 2 === 0) {
            style.backgroundColor = '#FFFBCE';
            style.color = '#000000';
            style.height = '45px';
        } else {
            style.backgroundColor = 'white';
            style.height = '45px';
        }

        style.cursor = 'pointer';

        return style;
    };

    const [loadingProducts, setLoadingProducts] = useState(false)
    const [products, setProducts] = useState([])
    const [viewPermission, setViewPermission] = useState(false)
    const [editPermission, setEditPermission] = useState(false)
    const [deletePermission, setDeletePermission] = useState(false)
    const [redirectToLogin, setRedirectToLogin] = useState(false)
    const [editCreateModalOpen, setEditCreateModalOpen] = useState(false)
    const [modalType, setmodalType] = useState('')
    const [productName, setProductName] = useState('')
    const [productPresentation, setProductPresentation] = useState('')
    const [productPrice, setProductPrice] = useState('')
    const [loadingCreatingProduct, setLoadingCreatingProduct] = useState(false)
    const [loadingChangeStatus, setLoadingChangeStatus] = useState(false)
    const [loadingEditingProduct, setLoadingEditingProduct] = useState(false)
    const [loadingDeletingProduct, setLoadingDeletingProduct] = useState(false)
    const [currentProductId, setCurrentProductId] = useState(null)
  
    useEffect(() => {
        handlePermissions()
    }, [])

    useEffect(() => {
      if(viewPermission || editPermission || deletePermission){
        getProducts()
      }
    }, [viewPermission, editPermission, deletePermission])

    const handlePermissions = () => {
        if(localStorage.getItem('data')){
            let data = JSON.parse(localStorage.getItem('data'));
            switch (data.rol) {
                case 'administracion':
                    setViewPermission(true)
                    setEditPermission(true)
                    setDeletePermission(true)
                    break;
                case 'ventas':
                    setViewPermission(true)
                    setEditPermission(false)
                    break;
                case 'facturacion':
                    setViewPermission(true)
                    setEditPermission(false)
                    break;
                case 'control':
                    setViewPermission(true)
                    setEditPermission(false)
                    break;
                default:
                    break;
            }
        }else{
            setRedirectToLogin(true)
        }
    }

    const getProducts = () => {
        setLoadingProducts(true)
        axiosClient.get(API_URL + READ_PRODUCTS).then((response) => {
            response.data.forEach((product) =>  {
                product.product_price = <NumberFormat
                    value={product.product_price}
                    displayType={'text'}
                    prefix={'$'}
                    decimalScale={0}
                    thousandSeparator={","}
                    decimalSeparator={"."}
                    fixedDecimalScale={true}
                />;


                if(product.status === 'active') {
                    let newStatus = 'inactive'
                    product.status = <div className="product-status-container">
                        <div className="product-status-active"></div>
                        &nbsp;&nbsp;<div>ACTIVO</div>
                        <AutorenewIcon className="product-status-change-icon" onClick={() => changeProductStatus(product.id, newStatus)}/>
                    </div>
                }

                if(product.status === 'inactive') {
                    let newStatus = 'active'
                    product.status = <div className="product-status-container">
                            <div className="product-status-inactive"></div>
                            <div>&nbsp;INACTIVO</div>
                            <AutorenewIcon className="product-status-change-icon" onClick={() => changeProductStatus(product.id, newStatus)}/>
                        </div>
                } 

                product.actions = <React.Fragment>
                    {
                        editPermission &&
                            <Button
                                className='coin-button-table-disable'
                                onClick={() => editProduct(product)}
                            >
                                <Edit/>
                            </Button>
                    }
                    {
                        deletePermission &&
                            <Button
                                className='coin-button-table-disable'
                                onClick={() => deleteProduct(product)}
                            >
                                <Delete/>
                            </Button>
                    }
                </React.Fragment>
            })
            setProducts(response.data)
            setLoadingProducts(false)
        }).catch((error) => {
            setLoadingProducts(false)
            if(error.response && error.response.data && error.response.data.message){
                alert(error.response.data.message)
            }else{
                alert("ERROR: error de servidor cargando listado de productos")
            }
        })
    }

    const changeProductStatus = (productId, status) => {

        let formData = {
            status,
        }

        setLoadingChangeStatus(true)

        axiosClient.put(API_URL + CHANGE_PRODUCT_STATUS + productId, formData).then((response) => {
            setLoadingChangeStatus(false)
            if(!response.data.success){
                alert("ERROR: error de servidor cambiando estado de producto.")
            }
            getProducts()
        }).catch((error) => {
            setLoadingChangeStatus(false)
            if(error.response && error.response.data && error.response.data.message){
                alert(error.response.data.message)
            }else{
                alert("ERROR: error de servidor cambiando estado de producto.")
            }
        })
    }

    const handleEditCreateModalOpen = () => {
        setEditCreateModalOpen(true)
        setProductName('')
        setProductPresentation('')
        setProductPrice('')
        setmodalType('create')
    }

    const clearProductState = () => {
        setProductName('')
        setProductPresentation('')
        setProductPrice('')
        setCurrentProductId(null)
    }

    const handleEditCreateModalClose = () => {
        setEditCreateModalOpen(false)
    }

    const handleProductNameChange = (e) => {
        setProductName(e.target.value)
    }

    const handleProductPresentationChange = (e) => {
        setProductPresentation(e.target.value)
    }

    const doCreateProduct = () => {
        if(validateFields()){

            setLoadingCreatingProduct(true)
            
            let formData = {
                product_name: productName, 
                product_presentation: productPresentation,
                product_price: productPrice,
                status: 'active'
            }

            axiosClient.post(API_URL + CREATE_PRODUCT, formData).then((response) => {
                setLoadingCreatingProduct(false)
                if(response.data.success){
                    alert("Producto agregado exitosamente.")
                    setEditCreateModalOpen(false)
                    clearProductState()
                    getProducts()
                }else{
                    alert("ERROR: error de servidor agregando producto.")
                }
                setProducts(response.data)
                setLoadingProducts(false)
            }).catch((error) => {
                setLoadingCreatingProduct(false)
                if(error.response && error.response.data && error.response.data.message){
                    alert(error.response.data.message)
                }else{
                    alert("ERROR: error de servidor agregando producto.")
                }
            })
        }   
    }

    const doEditProduct = () => {
        if(validateFields()){

            setLoadingEditingProduct(true)
            
            let formData = {
                product_name: productName, 
                product_presentation: productPresentation,
                product_price: productPrice,
            }

            axiosClient.put(API_URL + UPDATE_PRODUCT + currentProductId, formData).then((response) => {
                setLoadingEditingProduct(false)
                if(response.data.success){
                    alert("Producto editado exitosamente.")
                    setEditCreateModalOpen(false)
                    clearProductState()
                    getProducts()
                }else{
                    alert("ERROR: error de servidor editando producto.")
                }
                setProducts(response.data)
                setLoadingProducts(false)
            }).catch((error) => {
                setLoadingEditingProduct(false)
                if(error.response && error.response.data && error.response.data.message){
                    alert(error.response.data.message)
                }else{
                    alert("ERROR: error de servidor agregando producto.")
                }
            })
        }   
    }

    const editProduct = (product) => {
        setEditCreateModalOpen(true)
        setmodalType('edit')
        setProductName(product.product_name)
        setProductPresentation(product.product_presentation)
        setProductPrice(product.product_price.props.value)
        setCurrentProductId(product.id)
    }

    const deleteProduct = (product) => {
      if(window.confirm("¿Está seguro de que desea eliminar este producto?. Esta es una operación destructiva y no es posible deshacerla.")){
        setLoadingDeletingProduct(true)
        axiosClient.delete(`${API_URL}${DELETE_PRODUCT}${product.id}`).then((response) => {
          setLoadingDeletingProduct(false)
            if(response.data.success){
                alert("Producto eliminado exitosamente.")
                getProducts()
            }else{
                alert("ERROR: error de servidor eliminando producto.")
            }
            setLoadingProducts(false)
        }).catch((error) => {
          setLoadingDeletingProduct(false)
            if(error.response && error.response.data && error.response.data.message){
                alert(error.response.data.message)
            }else{
                alert("ERROR: error de servidor eliminando producto.")
            }
        })
      }
    }

    const handleChange = () => event => {
        let number = parseFloat(event.target.value.substring(2).replace(/,/g, ''));
        setProductPrice(number);
    };

    const validateFields = () => {
        return validateProductName() && validateProductPresentation() && validateProductPrice();
    }

    const validateProductName = () => {
        if(productName === ''){
            alert("Debes ingresar el nombre del producto.")
            return false;
        }else{
            return true;
        }
    }

    const validateProductPresentation = () => {
        if(productPresentation === ''){
            alert("Debes ingresar la presentación del producto.")
            return false;
        }else{
            return true;
        }
    }

    const validateProductPrice = () => {
        if(productPrice === ''){
            alert("Debes ingresar el valor del producto.")
            return false;
        }else{
            return true;
        }
    }


    return (
        <>
            { redirectToLogin && <Redirect push to='/' /> }
            <LoadingComponent loadingImage={Logo} loadingText={'Cargando productos.'} loadingState={loadingProducts}/>
            <LoadingComponent loadingImage={Logo} loadingText={'Creando nuevo producto.'} loadingState={loadingCreatingProduct}/>
            <LoadingComponent loadingImage={Logo} loadingText={'Cambiando estado del producto.'} loadingState={loadingChangeStatus}/>
            <LoadingComponent loadingImage={Logo} loadingText={'Editando producto.'} loadingState={loadingEditingProduct}/>
            <LoadingComponent loadingImage={Logo} loadingText={'Eliminando producto.'} loadingState={loadingDeletingProduct}/>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={editCreateModalOpen}
                onClose={() => handleEditCreateModalClose()}
                className='flomecolModalContainer'
            >
                <div className='flomecolModalContent'>

                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            { modalType === 'create' &&  <div className='modalTitle'><h2>NUEVO PRODUCTO</h2></div> }
                            { modalType === 'edit' &&  <div className='modalTitle'><h2>EDITAR PRODUCTO</h2></div> }
                        </Grid>

                        <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                            <TextField
                                id="outlined-name"
                                label="Nombre del Producto"
                                className='inputTextField'
                                name='productName'
                                value={productName}
                                onChange={handleProductNameChange}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                            <TextField
                                id="outlined-name"
                                label="Presentación del Producto"
                                className='inputTextField'
                                name='productPresentation'
                                value={productPresentation}
                                onChange={handleProductPresentationChange}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                            <TextField
                                id="outlined-name"
                                label="Precio del Producto"
                                className='inputTextField'
                                name='productPrice'
                                value={productPrice}
                                onBlur={handleChange('numberformat')}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    endAdornment: <InputAdornment position="end">COP</InputAdornment>,
                                }}
                            />
                        </Grid>

                        {
                            modalType === 'create' &&
                                <Grid item xs={12} className='inputTextFieldContainer'>
                                    <Button variant="contained" color="primary" onClick={() => doCreateProduct()}>
                                        <AddCircleIcon/> &nbsp; CREAR NUEVO PRODUCTO
                                    </Button>
                                </Grid>
                        }
                        {   modalType === 'edit' &&
                                <Grid item xs={12} className='inputTextFieldContainer'>
                                    <Button variant="contained" color="primary" onClick={() => doEditProduct()}>
                                        <Edit/> &nbsp; EDITAR PRODUCTO
                                    </Button>
                                </Grid>
                        }
                    </Grid>
                </div>
            </Modal>

            <Grid container spacing={0} className='ModuleMainContainer'>
                {
                    editPermission && <React.Fragment>
                        <Button variant="contained" color="primary" onClick={() => handleEditCreateModalOpen()}>
                            <AddCircleIcon/> &nbsp; CREAR NUEVO PRODUCTO
                        </Button>
                    </React.Fragment>
                }
                <Grid container spacing={0} className='tables-container'>
                    <Grid item xs={12}>
                        <h3 className='table-title'>LISTADO DE PRODUCTOS</h3>
                    </Grid>
                    <Grid item xs={12}>
                        <BootstrapTable
                            keyField='id'
                            data={ products }
                            columns={ columns }
                            rowStyle={ rowStyle2 }
                            classes='bootstrap-table'
                            wrapperClasses='bootstrap-table-wrapper'
                            headerClasses='bootstrap-table-header'
                            pagination={ paginationFactory()}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
export default AdminProductsComponent