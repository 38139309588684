import React from 'react';
import './HeaderComponent.css';
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DashboardIcon from '@material-ui/icons/Dashboard';
import {Link, Redirect} from "react-router-dom";

class HeaderComponent extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            redirectToLogin: false
        }
    }

    closeSession(){
        this.setState({redirectToLogin: true})
        localStorage.clear();
    }

    redirectToLogin = () => {
        if (this.state.redirectToLogin) {
            return <Redirect push to='/' />
        }
    }

    render(){
        return(
            <React.Fragment>
                {this.redirectToLogin()}
                <Grid container spacing={0}>
                    <AppBar position="static">
                        <Toolbar>
                            <Link to="/dashboard" className='headerHomeIcon'>
                                <IconButton edge="start" color="inherit" aria-label="Menu">
                                    <DashboardIcon />
                                </IconButton>
                            </Link>
                            <Typography variant="h6" className='headerComponentTitle'>
                                SISTEMA DE INFORMACION FLOMECOL&reg;
                            </Typography>
                            <Button onClick={this.closeSession.bind(this)} color="inherit">Cerrar Sesión</Button>
                        </Toolbar>
                    </AppBar>
                </Grid>
            </React.Fragment>
        )
    }
}

export default HeaderComponent;