import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axiosClient from "../../services/apiClient";
import {ALLY_LOGIN, API_URL} from "../../API";
import {Redirect} from "react-router-dom";
import {message_responses} from "../../MESSAGE_RESPONSES";
import Logo from "../../images/Logo_flomecol.svg";
import LoadingComponent from "../LoadingComponent/LoadingComponent";

const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class AllyLoginComponent extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            nit: '',
            password: '',
            redirectToAllyDashboard: false,
            loadingLogin : false,
        }
    }

    componentDidMount() {
        localStorage.clear();
    }

    doLogin(){
        let formData = {
            nit: this.state.nit,
            password: this.state.password,
        }
        this.setState({loadingLogin:true});
        axiosClient.post(API_URL + ALLY_LOGIN, formData).then((response) => {
            if(response.data.success){
                let serializedData = JSON.stringify(response.data.data);
                localStorage.setItem('ally', serializedData);
                localStorage.setItem('data', serializedData);
                this.setState({redirectToAllyDashboard: true})
            }else{
                alert(message_responses[response.data.message]);
            }
            this.setState({loadingLogin:false})
        }).catch((error) => {
            if(error.response && error.response.data){
                alert(error.response.data.message === 'VALIDATOR_ERROR' ? error.response.data.data.error_message : message_responses[error.response.data.message])
            }else{
                alert("ERROR DE SERVIDOR: Hemos tenido un error con esta petición al servidor, si el problema continua por favor contactar al área encargada.")
            }
            this.setState({loadingLogin:false})
        })
    }

    handleChange(event){
        this.setState({[event.target.name]:event.target.value})
    }

    redirectToAllyDashboard = () => {
        if (this.state.redirectToAllyDashboard) {
            return <Redirect push to='/alidos-panel' />
        }
    }

    render(){
        const { classes } = this.props;

        return (
            <React.Fragment>
                {this.redirectToAllyDashboard()}
                <LoadingComponent loadingImage={Logo} loadingText={'Iniciando sesión.'} loadingState={this.state.loadingLogin}/>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5" align='center' >
                            FLOMECOL® ALIADOS
                        </Typography>
                        <form className={classes.form} noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="nit"
                                label="Usuario"
                                name="nit"
                                autoComplete="nit"
                                autoFocus
                                value={this.state.nit}
                                onChange={this.handleChange.bind(this)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Contraseña"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={this.state.password}
                                onChange={this.handleChange.bind(this)}
                            />

                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                size="large"
                                onClick={this.doLogin.bind(this)}
                            >
                                Iniciar Sesión
                            </Button>
                            <Grid container>
                                <Grid item xs onClick={() => alert("Si olvidaste la contraseña de tu usuario, puedes contactar al área encargada para recuperar tu contraseña.")} >
                                    <Link href="#" variant="body2">
                                        Olvidaste tu contraseña?
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(AllyLoginComponent);