import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";

class PdfViewerComponent extends Component {
    render() {
        return (
            <Dialog
                onClose={this.props.handleClose}
                aria-labelledby="simple-dialog-title"
                open={this.props.open}
                classes={{
                    paper:'pdf-dialog-paper'
                }}
            >
                <React.Fragment>
                    <Grid container spacing={0} >
                        <iframe src={"data:application/pdf;base64,"+this.props.pdfURL} title='pdf'/>
                    </Grid>
                </React.Fragment>
            </Dialog>
        );
    }
}
export default PdfViewerComponent;