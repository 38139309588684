import React, { useEffect, useState } from 'react';
import axiosClient from "../../services/apiClient";
import { GET_EMPLOYEES, GET_EMPLOYEE_REPORT, GET_EMPLOYEES_REPORT } from "../../API";
import {message_responses} from "../../MESSAGE_RESPONSES";

const StaffAccessReportController = () => {

    let todayDate_ = new Date();
    let yesterdayDate_ = new Date();
    yesterdayDate_.setDate(yesterdayDate_.getDate()-1);
    const todayDate = new Date(todayDate_.setHours(0,0,0,0));

    const [ dateStartPicker, setDateStartPicker ] = useState(new Date(yesterdayDate_.setHours(0,0,0,0)));
    const [ dateEndPicker, setDateEndPicker ] = useState(new Date(todayDate_.setHours(0,0,0,0)));

    const [ selectedEmployee, setSelectedEmployee ] = useState('');
    const [ employees, setEmployees ] = useState([]);
    const [ report, setReport ] = useState([]);
    const [ loadingEmployees, setLoadingEmployees ] = useState(false);
    const [ loadingReport, setLoadingReport ] = useState(false);
    const [ paginationData, setPaginationData ] = useState({});
    const [ options, setOptions ] = useState({
        hideSizePerPage: true,
        page: 1,
        totalSize: 1,
        sizePerPage: 15,
        paginationSize: 1,
    });

    const columns = [
        {
            dataField: 'id',
            text: '#',
        }, {
            dataField: 'firstName',
            text: 'Nombres',
        }, {
            dataField: 'lastName',
            text: 'Apellidos',
        }, {
            dataField: 'date',
            text: 'Fecha',
        }, {
            dataField: 'type',
            text: 'Tipo de registro',
        }
    ];

    const rowStyle = (row, rowIndex) => {

        if(!row) return null;
        
        const style = {};

        if (rowIndex % 2 === 0) {
            style.backgroundColor = '#FFFBCE';
            style.color = '#000000';
            style.height = '45px';
        } else {
            style.backgroundColor = 'white';
            style.height = '45px';
        }

        style.cursor = 'pointer';
        
        return style
    };

    useEffect(() => {
        getEmployees();
        getReport();
    }, [])

    useEffect(() => {
        getEmployees();
        getReport();
    }, [dateStartPicker, dateEndPicker, selectedEmployee])

    const getReport = () => {
        if(selectedEmployee && selectedEmployee.value){
            getEmployeeReport();
        } else {
            getEmployeesReport();
        }
    }

    const formatType = type => {
        switch(type){
            case 'START_WORKING_DAY':
                return 'INICIO DE JORNADA'
            case 'HANDS_CLEANING':
                return 'LAVADO DE MANOS'
            case 'START_ACTIVE_BREAKS':
                return 'INICIO DE PAUSA ACTIVA'
            case 'END_ACTIVE_BREAKS':
                return 'FINALIZACIÓN DE PAUSA ACTIVA'
            case 'START_BREAK':
                return 'INICIO DE BREAK'
            case 'END_BREAK':
                return 'FINALIZACIÓN DE BREAK'
            case 'START_LUNCH':
                return 'INICIO DE ALMUERZO'
            case 'END_LUNCH':
                return 'FINALIZACIÓN DE ALMUERZO'
            case 'END_WORKING_DAY':
                return 'FINALIZACIÓN DE JORNADA'
            case '':
                return ''
            default:
                return ''
        }
    }

    const formatReport = array => {
        return array.map((e) => {
            e.type = formatType(e.type)
            return e;
        })
    }

    const getEmployeesReport = () => {
        setLoadingReport(true)
        axiosClient.get(`${GET_EMPLOYEES_REPORT}/${dateStartPicker.toISOString()}/${dateEndPicker.toISOString()}`).then((response) => {
            setLoadingReport(false)
            if(response.data.success){
                updatePagintationData(response)
                setReport(formatReport(response.data.data.data));                
            }else{
                console.error(response.data.message)
                alert(message_responses[response.data.message]);
            }
        }).catch((error) => {
            console.error(error)
            setLoadingReport(false)
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message)
            } else {
                alert("ERROR: error de servidor cargando reporte de ingreso.")
            }
        })
    }

    const getEmployeeReport = () => {
        setLoadingReport(true)
        axiosClient.get(`${GET_EMPLOYEE_REPORT}/${dateStartPicker.toISOString()}/${dateEndPicker.toISOString()}/${selectedEmployee.value}`).then((response) => {
            setLoadingReport(false)
            if(response.data.success){
                updatePagintationData(response)
                setReport(formatReport(response.data.data.data));                
            }else{
                console.error(response.data.message)
                alert(message_responses[response.data.message]);
            }
        }).catch((error) => {
            console.error(error)
            setLoadingReport(false)
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message)
            } else {
                alert("ERROR: error de servidor cargando reporte de ingreso.")
            }
        })
    }

    const onTableChange = (type, newState) => {
        setLoadingReport(true)
        axiosClient.get(`${paginationData.path}?page=${newState.page}`).then((response) => {
            updatePagintationData(response)
            setReport(formatReport(response.data.data.data));    
            setLoadingReport(false)
        }).catch((error) => {
            console.error(error)
            setLoadingReport(false)
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message)
            } else {
                alert("ERROR: error de servidor cargando reporte de ingreso.")
            }
        })
    }

    const onChangeStartDate = date => {
        let oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
        let diff = Math.round(Math.abs((dateEndPicker.getTime() - date.getTime())/(oneDay)));

        if(diff === 0){
            alert("No puedes tener la misma fecha en los campos 'Desde:' y 'Hasta'");
        }else if(diff < 0){
            alert("El campo de fecha 'Desde:' debe ser menor al campo de fecha 'Hasta'");
        }else{
            setDateStartPicker(date)
        }
    }

    const onChangeEndDate = date => {
        let oneDay = 24*60*60*1000;
        let diff = Math.round(Math.abs((date.getTime() - dateStartPicker.getTime())/(oneDay)));
        if(diff === 0){
            alert("No puedes tener la misma fecha en los campos 'Desde:' y 'Hasta'");
        }else if(diff < 0){
            alert("El campo de fecha 'Desde:' debe ser menor al campo de fecha 'Hasta'");
        }else{
            setDateEndPicker(date)
        }
    }

    const handleSelectedEmployeeChange = selectedEmployee => setSelectedEmployee(selectedEmployee);
    

    const formatEmployeesArray = array => {

        let employees_ = array.map((employee) => {
            return {
                label: `${employee.firstName} ${employee.lastName}`,
                value: employee.id
            }
        })

        employees_.unshift({
            label: 'Todos',
            value: null
        });

        return employees_;

    }

    const getEmployees = () => {
        setLoadingEmployees(true)
        axiosClient.get(GET_EMPLOYEES).then((response) => {
            setLoadingEmployees(false)
            if(response.data.success){
                setEmployees(formatEmployeesArray(response.data.data));                
            }else{
                console.error(response.data.message)
                alert(message_responses[response.data.message]);
            }
        }).catch((error) => {
            console.error(error)
            setLoadingEmployees(false)
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message)
            } else {
                alert("ERROR: error de servidor cargando empleados.")
            }
        })
    }

    const updatePagintationData = (response) => {
        setPaginationData(response.data.data)
        setOptions({ 
            ...options, 
            ...{
                page: response.data.data.current_page,
                paginationSize: response.data.data.last_page < 10 ? response.data.data.last_page : 10,
                totalSize: response.data.data.total
            }
        })
    }

    return {
        onChangeStartDate,
        onChangeEndDate,
        todayDate,
        dateStartPicker,
        dateEndPicker,
        selectedEmployee,
        handleSelectedEmployeeChange,
        employees,
        loadingEmployees,
        columns,
        rowStyle,
        onTableChange,
        report,
        options,
        loadingReport
    }
}

export default StaffAccessReportController;