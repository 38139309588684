import React from 'react';
import HeaderComponent from "../../components/HeaderComponent/HeaderComponent";
import FooterComponent from "../../components/FooterComponent/FooterComponent";
import RequestedOrdersPortfolioComponent from "../../components/RequestedOrdersPortfolioComponent/RequestedOrdersPortfolioComponent";
import AccessControl from "../../hooks/AccessControl.js"
import NoAccessComponent from "../../components/NoAccessComponent/NoAccessComponent.js";

class RequestedOrdersPortfolio extends React.Component{
  render(){
    return(
      <React.Fragment>
        <AccessControl
          allowedRoles={['cartera', 'administracion']}
          renderNoAccess={() => <NoAccessComponent/>}
        >
          <React.Fragment>
            <HeaderComponent/>
            <RequestedOrdersPortfolioComponent/>
            <FooterComponent/>
          </React.Fragment>
        </AccessControl>
      </React.Fragment>
    )
  }
}

export default RequestedOrdersPortfolio;