import React from 'react';
import HeaderComponent from "../../components/HeaderComponent/HeaderComponent";
import FooterComponent from "../../components/FooterComponent/FooterComponent";
import AdminProductsComponent from "../../components/AdminProductsComponent/AdminProductsComponent";


const AdminProducts = (props) => {
    return (
        <>
            <HeaderComponent/>
            <AdminProductsComponent/>
            <FooterComponent/>
        </>
    )
}

export default AdminProducts