import React, { useState, useEffect, useRef } from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircleOutline";
import {Redirect} from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import Logo from "../../images/Logo_flomecol.svg";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Edit from "@material-ui/icons/Edit";
import DatePicker from 'react-date-picker';
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import NumberFormat from "react-number-format";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import axiosClient from "../../services/apiClient";
import { 
    API_URL, 
    CREATE_VIATICS_REQUEST, 
    GET_VIATICS_REQUEST, 
    GET_VIATICS_REQUEST_BY_OWNER, 
    GET_VIATICS_REQUEST_ZONES,
    DO_MANAGEMENT_SIGN_VIATICS_REQUEST,
    DO_SALES_SIGN_VIATICS_REQUEST,
    DO_ACCOUNTING_SIGN_VIATICS_REQUEST,
    GET_VIATICS_REQUEST_PDF
} from "../../API";
import { getUser } from '../../utils/auth';

function NumberFormatCustom(props){
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalScale={0}
            thousandSeparator={","}
            decimalSeparator={"."}
            fixedDecimalScale={true}
            prefix="$ "
        />
    );
}

const ViaticosComponent = (props) => {

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true
        },{
            dataField: 'applicant_name',
            text: 'Vendedor',
            sort: true
        },{
            dataField: 'zone',
            text: 'Zona',
            sort: true
        },{
            dataField: 'start_date',
            text: 'Día inicio',
        },{
            dataField: 'end_date',
            text: 'Día Finalización',
        },{
            dataField: 'total_cost',
            text: 'Total'
        },{
            dataField: 'actions',
            text: 'Estado',
        },{
            dataField: 'view',
            text: 'Ver',
        }
    ];

    const rowStyle2 = (row, rowIndex) => {
        const style = {};
        if (rowIndex % 2 === 0) {
            style.backgroundColor = '#FFFBCE';
            style.color = '#000000';
            style.height = '45px';
        } else {
            style.backgroundColor = 'white';
            style.height = '45px';
        }

        style.cursor = 'pointer';

        return style;
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            padding: 20,
            cursor: 'pointer',
            color: state.isFocused ? '#336636' : 'black',
            backgroundColor: state.isFocused ? '#FFFBCE' : 'white',
        }),
        menu: () => ({
            // none of react-select's styles are passed to <Control />
            backgroundColor: 'white',
            position: 'absolute',
            zIndex: '1000',
            width: '100%',
        }),
        input: () => ({
            // none of react-select's styles are passed to <Control />
            height: '52px',
        }),
    }

    const inputLabel = useRef(null);
    const [loadingRequests, setLoadingRequests] = useState(false)
    const [requestsInProccess, setRequestsInProccess] = useState([])
    const [requestsAuthorized, setRequestsAuthorized] = useState([])
    const [user, setUser] = useState(getUser())
    const [viewPermission, setViewPermission] = useState(false)
    const [editPermission, setEditPermission] = useState(false)
    const [listAllPermission, setListAllPermission] = useState(false)
    const [redirectToLogin, setRedirectToLogin] = useState(false)
    const [editCreateModalOpen, setEditCreateModalOpen] = useState(false)
    const [modalType, setmodalType] = useState('')
    const [loadingCreatingRequest, setLoadingCreatingRequest] = useState(false)
    const [loadingEditingRequest, setLoadingEditingRequest] = useState(false)
    const [loadingZones, setLoadingZones] = useState(false)
    const [zones, setZones] = useState([])
    const [selectedZone, setSelectedZone] = useState('')
    const [requestName, setRequestName] = useState('')
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [daysList, setDaysList] = useState([])
    const [newDayDate, setNewDayDate] = useState(new Date())
    const [newDayCities, setNewDayCities] = useState('')
    const [newDayObservations, setNewDayObservations] = useState('')
    const [goal, setGoal] = useState('')
    const [transportType, setTransportType] = useState('')
    const [transportationCost, setTransportationCost] = useState('')
    const [foodCost, setFoodCost] = useState('')
    const [hostingCost, setHostingCost] = useState('')
    const [totalCost, setTotalCost] = useState('')
    const [othersCost, setOthersCost] = useState('')
    const [tabValue, setTabValue] = useState(0)
    
    const [canSignManagement, setCanSignManagement] = useState(false)
    const [canSignSales, setCanSignSales] = useState(false)
    const [canSignAccounting, setCanSignAccounting] = useState(false)
    const [signingRequest, setSigningRequest] = useState(false)
    
    
    useEffect(() => {
        handlePermissions()
    }, [])

    useEffect(() => {
      if(!user) return
      getViaticsRequests()
      getZones()
    }, [user])

    useEffect(() => {
        let transportation = 0;
        let food = 0;
        let hosting = 0;
        let others = 0;

        (transportationCost === '' || transportationCost === undefined || transportationCost === null) ? transportation = 0 : transportation = transportationCost;
        (foodCost === '' || foodCost === undefined || foodCost === null) ? food = 0 : food = foodCost;
        (hostingCost === '' || hostingCost === undefined || hostingCost === null) ? hosting = 0 : hosting = hostingCost;
        (othersCost === '' || othersCost === undefined || othersCost === null) ? others = 0 : others = othersCost;
        setTotalCost(transportation + food + hosting + others)
    }, [transportationCost, foodCost, hostingCost, othersCost])

    const handlePermissions = () => {
        if(localStorage.getItem('data')){
            let data = JSON.parse(localStorage.getItem('data'));

            if(data.id === 9 || data.id === 1){ setCanSignManagement(true) }

            if(data.id === 12 || data.id === 1){ setCanSignSales(true) }

            switch (data.rol) {
                case 'administracion':
                    setViewPermission(true)
                    setEditPermission(true)
                    setListAllPermission(true)
                    setCanSignAccounting(false)
                    break;
                case 'ventas':
                    setViewPermission(true)
                    setEditPermission(true)
                    setListAllPermission(false)
                    setCanSignAccounting(false)
                    break;
                case 'facturacion':
                    setViewPermission(true)
                    setEditPermission(false)
                    setListAllPermission(true)
                    setCanSignAccounting(false)
                    break;
                case 'control':
                    setViewPermission(true)
                    setEditPermission(false)
                    setListAllPermission(true)
                    setCanSignAccounting(false)
                    break;
                case 'contabilidad':
                    setViewPermission(true)
                    setEditPermission(false)
                    setListAllPermission(true)
                    setCanSignAccounting(true)
                    break;
                default:
                    break;
            }
            getUserInfo()
        }else{
            setRedirectToLogin(true)
        }
    }

    const getUserInfo = () => {
        if(localStorage.getItem('data')){
            let user = JSON.parse(localStorage.getItem('data'))
            setUser(user)
            setRequestName(user.name)
        }
    }

    const getViaticsRequests = () => {
        setLoadingRequests(true)
        let url = '';
        listAllPermission ? url = API_URL + GET_VIATICS_REQUEST : url = API_URL + GET_VIATICS_REQUEST_BY_OWNER + user.id;
        axiosClient.get( url ).then((response) => {
            setLoadingRequests(false)
            if(response.data.success){
                formatRequests(response.data.data)
            }else{
                alert("ERROR: error de servidor cargando solicitudes de viáticos.")
            }
        }).catch((error) => {
            setLoadingRequests(false)
            if(error.response && error.response.data && error.response.data.message){
                alert(error.response.data.message)
            }else{
                alert("ERROR: error de servidor cargando solicitudes de viáticos.")
            }
        })
    }

    const getZones = () => {
        setLoadingZones(true)
        //setZones
        axiosClient.get( API_URL + GET_VIATICS_REQUEST_ZONES + user.id  ).then((response) => {
            setLoadingZones(false)
            if(response.data.success){
                formatZones(response.data.data)
            }else{
                alert("ERROR: error de servidor cargando zonas.")
            }
        }).catch((error) => {
            setLoadingZones(false)
            if(error.response && error.response.data && error.response.data.message){
                alert(error.response.data.message)
            }else{
                alert("ERROR: error de servidor cargando zonas.")
            }
        })
    }

    const formatZones = (data) => {
        let localZones = []
        data.forEach((zone, index) => {
            let newZone = {
                id: index,
                name: zone.zones
            }
            localZones.push(newZone)
        })
        setZones(localZones)
    }

    const formatDateToDisplay = (date) => {
        if(date){
            let monthNames = [
                "Enero", "Febrero", "Marzo",
                "Abril", "Mayo", "Junio", "Julio",
                "Agosto", "Septiembre", "Octubre",
                "Noviembre", "Diciembre"
            ];

            let day = date.getDate();
            let monthIndex = date.getMonth();
            let year = date.getFullYear();
            return day + ' ' + monthNames[monthIndex] + ' ' + year;
        }else{
            return '';
        }
    }

    const formatRequests = (data) => {

        let authorized = []
        let inProccess = []
        data.forEach((request) => {
            request.total_cost = <NumberFormat
                value={request.total_cost}
                displayType={'text'}
                prefix={'$'}
                decimalScale={0}
                thousandSeparator={","}
                decimalSeparator={"."}
                fixedDecimalScale={true}
            />;

            request.start_date = formatDateToDisplay(new Date(request.start_date))
            request.end_date = formatDateToDisplay(new Date(request.end_date))

            request.view = <React.Fragment>
                            {
                                viewPermission &&
                                    <a
                                        href={API_URL + GET_VIATICS_REQUEST_PDF + request.id}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        className='download-order-pdf-eye'
                                    >
                                        <Button
                                            className='coin-button-table-disable'
                                        >
                                            <RemoveRedEye/>
                                        </Button>

                                    </a>
                            }
            </React.Fragment>
            request.actions = <React.Fragment>
                            {
                                <React.Fragment>
                                    {
                                        request.management_sign === "1" ? 
                                            <Tooltip title="Autorizado por Gerencia"><CheckIcon className="viatics-check-icon"/></Tooltip>
                                            :
                                            canSignManagement ?
                                                <Tooltip title="Falta Autorización Gerencia"><CloseIcon className="viatics-close-icon" onClick={() => doSign(request.id, 'MANAGEMENT')}/></Tooltip>
                                                :
                                                <Tooltip title="Falta Autorización Gerencia"><CloseIcon className="viatics-close-icon" onClick={() => alert("No tienes permisos para realizar esta acción")}/></Tooltip>
                                    }
                                </React.Fragment>
                            }

                            {
                                <React.Fragment>
                                    {
                                        request.sales_sign === "1" ? 
                                            <Tooltip title="Autorizado por Ventas"><CheckIcon className="viatics-check-icon"/></Tooltip>
                                            :
                                            canSignSales ?
                                                <Tooltip title="Falta Autorización Ventas"><CloseIcon className="viatics-close-icon" onClick={() => doSign(request.id, 'SALES')}/></Tooltip>
                                                :
                                                <Tooltip title="Falta Autorización Ventas"><CloseIcon className="viatics-close-icon" onClick={() => alert("No tienes permisos para realizar esta acción")}/></Tooltip>
                                    }
                                </React.Fragment>
                            }

                            {
                                <React.Fragment>
                                    {
                                        request.accounting_sign === "1" ? 
                                            <Tooltip title="Autorizado por Contabilidad"><CheckIcon className="viatics-check-icon"/></Tooltip>
                                            :
                                            canSignAccounting ?
                                                <Tooltip title="Falta Autorización Contabilidad"><CloseIcon className="viatics-close-icon" onClick={() => doSign(request.id, 'ACCOUNTING')}/></Tooltip>
                                                :
                                                <Tooltip title="Falta Autorización Contabilidad"><CloseIcon className="viatics-close-icon" onClick={() => alert("No tienes permisos para realizar esta acción")}/></Tooltip>
                                    }
                                </React.Fragment>
                            }

                        </React.Fragment>

            if(request.status === 'EN_PROCESO'){
                inProccess.push(request)
            }

            if(request.status === 'AUTORIZADA'){
                authorized.push(request)
            }
        
        })

        setRequestsInProccess(inProccess.reverse())
        setRequestsAuthorized(authorized.reverse())

    }

    const doSign = (id, sign) => {

        let url = '';

        switch(sign){
            case 'MANAGEMENT':
                url = DO_MANAGEMENT_SIGN_VIATICS_REQUEST;
                break;
            case 'SALES':
                url = DO_SALES_SIGN_VIATICS_REQUEST;
                break;
            case 'ACCOUNTING':
                url = DO_ACCOUNTING_SIGN_VIATICS_REQUEST;
                break;
            default:
                break;
        }

        setSigningRequest(true)
        let formData = {
            id,
            signed_by: user.id,
        }

        axiosClient.post(API_URL + url, formData).then((response) => {
            setSigningRequest(false)
            if(response.data.success){
                getViaticsRequests()
            }else{
                alert("ERROR: error autorizando solicitud.")
            }
        }).catch((error) => {
            setSigningRequest(false)
            if(error.response && error.response.data && error.response.data.message){
                alert(error.response.data.message)
            }else{
                alert("ERROR: error autorizando solicitud.")
            }
        })            
    }

    const clearEditCreateForm = () => {
        setSelectedZone('')
        setStartDate(new Date())
        setEndDate(new Date())
        setDaysList([])
        setNewDayDate(new Date())
        setNewDayCities('')
        setNewDayObservations('')
        setGoal('')
        setTransportType('')
        setTransportationCost('')
        setFoodCost('')
        setHostingCost('')
        setTotalCost('')
        setOthersCost('')
    }

    const handleEditCreateModalClose = () => {
        setEditCreateModalOpen(false)
    }

    const handleEditCreateModalOpen = () => {
        setEditCreateModalOpen(true)
        clearEditCreateForm();
        setmodalType('create')
        
    }

    const formatDetails = () => {
        let details = []
        daysList.forEach((detail) => {
            let newDetail = {}
            newDetail.day_date = formatDate(detail.date)
            newDetail.places = detail.cities
            newDetail.observations = detail.observations
            details.push(newDetail)
        })
        return details
    }

    const formatDate = (date) => {
        let iso = date.toISOString().match(/(\d{4}\-\d{2}\-\d{2})T(\d{2}:\d{2}:\d{2})/)
        return iso[1] + ' ' + iso[2]
    }

    const doCreateRequest = () => {
        if(validateFields()){
            setLoadingCreatingRequest(true)

            const details = formatDetails()

            let formData = {
                zone : selectedZone,
                applicant_name : requestName,
                request_date: formatDate(new Date()),
                start_date : formatDate(startDate),
                end_date : formatDate(endDate),
                goal : goal,
                transportation_type: transportType,
                transportation_cost : transportationCost,
                food_cost : foodCost,
                hosting_cost : hostingCost,
                others_cost : othersCost,
                total_cost : totalCost,
                created_by : user.id,
                status : 'EN_PROCESO',
                accounting_sign : false,
                accounting_signed_by : null,
                sales_sign : false,
                sales_signed_by : null,
                management_sign : false,
                management_signed_by : null,
                viatics_request_details : details
            }

            axiosClient.post( API_URL + CREATE_VIATICS_REQUEST, formData).then((response) => {
                setLoadingCreatingRequest(false)
                if(response.data.success){
                    alert("Solicitud de viáticos creada exitosamente.")
                    setEditCreateModalOpen(false)
                    clearEditCreateForm()
                    getViaticsRequests()
                    getZones()
                }else{
                    alert("ERROR: error de servidor creando solicitud de viáticos.")
                }
            }).catch((error) => {
                setLoadingCreatingRequest(false)
                if(error.response && error.response.data && error.response.data.message){
                    alert(error.response.data.message)
                }else{
                    alert("ERROR: error de servidor agregando producto.")
                }
            })
        }
    }

    const validateFields = () => {
        return validateZone() &&
        validateRequestName() &&
        validateStarDate() &&
        validateEndDate() &&
        validateDaysList() &&
        validateGoal() &&
        validateTranpostType() &&
        validateTransportationCost() &&
        validateFoodCost() &&
        validateHostingCost() && 
        validateTotalCost() &&
        validateOthersCost();
    }

    const validateZone = () => {
        if(selectedZone === '' || selectedZone === null || selectedZone === undefined){
            alert("El campo zona es obligatorio")
            return false
        }else{
            return true
        }
    }

    const validateRequestName = () => {
        if(requestName === '' || requestName === null || requestName === undefined){
            alert("El campo nombre es obligatorio")
            return false
        }else{
            return true
        }
    }

    const validateStarDate = () => {
        if(startDate === '' || startDate === null || startDate === undefined){
            alert("El campo fecha de inicio es obligatorio")
            return false
        }else{
            return true
        }
    }

    const validateEndDate = () => {
        if(endDate === '' || endDate === null || endDate === undefined){
            alert("El campo fecha de finalizacion es obligatorio")
            return false
        }else{
            return true
        }
    }

    const validateDaysList = () => {
        if(daysList.length <= 0){
            alert("Debes tener al menos un registro en detalles.")
            return false
        }else{
            return true
        }
    }

    const validateGoal = () => {
        if(goal === '' || goal === null || goal === undefined){
            alert("El campo objetivo de la ruta es obligatorio")
            return false
        }else{
            return true
        }
    }

    const validateTranpostType = () => {
        if(transportType === '' || transportType === null || transportType === undefined){
            alert("El campo tipo de transporte es obligatorio")
            return false
        }else{
            return true
        }
    }

    const validateTransportationCost = () => {
        if(transportationCost === '' || transportationCost === null || transportationCost === undefined || isNaN(transportationCost)){
            alert("El campo costo de transporte es obligatorio")
            return false
        }else{
            return true
        }
    }

    const validateFoodCost = () => {
        if(foodCost === '' || foodCost === null || foodCost === undefined || isNaN(foodCost)){
            alert("El campo costo de alimentación es obligatorio")
            return false
        }else{
            return true
        }
    }

    const validateHostingCost = () => {
        if(hostingCost === '' || hostingCost === null || hostingCost === undefined || isNaN(hostingCost)){
            alert("El campo costo de alojamiento es obligatorio")
            return false
        }else{
            return true
        }
    }

    const validateOthersCost = () => {
        if(othersCost === '' || othersCost === null || othersCost === undefined || isNaN(othersCost)){
            alert("El campo de otros costos es obligatorio")
            return false
        }else{
            return true
        }
    }

    const validateTotalCost = () => {
        if(totalCost === '' || totalCost === null || totalCost === undefined || isNaN(totalCost)){
            alert("El campo costo total es obligatorio")
            return false
        }else{
            return true
        }
    }

    const doEditRequest = () => {

    }

    const handleStartDateChange = (date) => {
        setStartDate(date === null ? new Date() : date)
    }

    const handleEndDateChange = (date) => {
        setEndDate(date === null ? new Date() : date)
    }

    const handleNewDayDateChange = (date) => {
        setNewDayDate(date === null ? new Date() : date)
    }

    const deleteDay = (currentIndex) => {
        let list = daysList.filter((day, index) => index !== currentIndex)
        setDaysList(list)
    }

    const addDay = () => {
        if(validateNewDateData()){
            let newDay = {
                date: newDayDate,
                cities: newDayCities,
                observations: newDayObservations
            }
            let list = daysList
            list.push(newDay) 
            setDaysList(list)
            setNewDayDate(new Date())
            setNewDayCities('')
            setNewDayObservations('')
        }
    }

    const validateNewDateData = () => {
        return validateNewDayDate() && validateNewDayCities()
    }

    const validateNewDayDate = () => {
        if(newDayDate === null ||  newDayDate === ''){
            alert('El campo de fecha es obligatorio.')
            return false
        }else{
            return true
        }
    }

    const validateNewDayCities = () => {
        if(newDayCities === ''){
            alert('El campo de ciudades y/o lugares es obligatorio.')
            return false
        }else{
            return true
        }
    }

    const handleNewDayCitiesChange = (e) => {
        setNewDayCities(e.target.value)
    }

    const handleNewDayObservationsChange = (e) => {
        setNewDayObservations(e.target.value)
    }

    const handleSetGoalChange = (e) => {
        setGoal(e.target.value)
    }

    const handleTransportTypeChange = (e) => {
        setTransportType(e.target.value)
    }

    const handleTransportationCostChange = e => {
        let number = parseFloat(e.target.value.substring(2).replace(/,/g, ''));
        setTransportationCost(number)
    }

    const handleFoodCostChange = e => {
        let number = parseFloat(e.target.value.substring(2).replace(/,/g, ''));
        setFoodCost(number)
    }

    const handleHostingCostChange = e => {
        let number = parseFloat(e.target.value.substring(2).replace(/,/g, ''));
        setHostingCost(number)
    }

    const handleOthersCostChange = e => {
        let number = parseFloat(e.target.value.substring(2).replace(/,/g, ''));
        setOthersCost(number)
    }

    const handleTabValueChange = (event, value) => {
        setTabValue(value);
    };

    if(!user) return null

    return (
        <>
            { redirectToLogin && <Redirect push to='/' /> }

            <LoadingComponent loadingImage={Logo} loadingText={'Cargando solicitudes.'} loadingState={loadingRequests}/>
            <LoadingComponent loadingImage={Logo} loadingText={'Creando nueva solicitud.'} loadingState={loadingCreatingRequest}/>
            <LoadingComponent loadingImage={Logo} loadingText={'Editando solicitud.'} loadingState={loadingEditingRequest}/>
            <LoadingComponent loadingImage={Logo} loadingText={'Cargando zonas del vendedor.'} loadingState={loadingZones}/>
            <LoadingComponent loadingImage={Logo} loadingText={'Autorizando solicitud.'} loadingState={signingRequest}/>
            
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={editCreateModalOpen}
                onClose={() => handleEditCreateModalClose()}
                className='flomecolModalContainer'
            >
                <div className='flomecolModalContent'>

                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            { modalType === 'create' &&  <div className='modalTitle'><h2>NUEVA SOLICITUD DE VIÁTICOS</h2></div> }
                            { modalType === 'edit' &&  <div className='modalTitle'><h2>EDITAR SOLICITUD DE VIÁTICOS</h2></div> }
                        </Grid>

                        <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                            <Autocomplete
                                id="combo-box-demo"
                                style={{ width: '100%' }}
                                freeSolo
                                options={zones.map(zone => zone.name)}
                                getOptionLabel={option => option}
                                value={selectedZone}
                                onChange={(event, newValue) => { setSelectedZone(newValue) }}
                                onInputChange={(event, newValue) =>  { setSelectedZone(newValue) }}
                                autoHighlight
                                renderInput={params => (
                                    <TextField 
                                        {...params} 
                                        label="Zona" 
                                        variant="outlined" 
                                        fullWidth
                                        required
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={8} className='inputTextFieldContainer'>
                            <TextField
                                id="outlined-name"
                                label="Nombre del solicitante"
                                className='inputTextField'
                                name='requestName'
                                value={user !== null && user.name !== undefined ? user.name : ''}
                                margin="normal"
                                variant="outlined"
                                disabled={true}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                            <span className='date-picker-label'>Fecha de Solicitud</span>
                            <DatePicker
                                dateFormat="MMMM - d / yyyy"
                                value={startDate}
                                minDate={new Date()}
                                disabled={true}
                                locale="es-ES"
                                calendarIcon={<CalendarTodayIcon/>}
                            />
                        </Grid>

                        <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                            <span className='date-picker-label'>Fecha de Inicio</span>
                            <DatePicker
                                dateFormat="MMMM - d / yyyy"
                                value={startDate}
                                onChange={handleStartDateChange}
                                minDate={new Date()}
                                locale="es-ES"
                                calendarIcon={<CalendarTodayIcon/>}
                            />
                        </Grid>

                        <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                            <span className='date-picker-label'>Fecha de Finalización</span>
                            <DatePicker
                                dateFormat="MMMM - d / yyyy"
                                value={endDate}
                                onChange={handleEndDateChange}
                                minDate={startDate}
                                locale="es-ES"
                                calendarIcon={<CalendarTodayIcon/>}
                            />
                        </Grid>

                        <div className='modalTitle'><h3> - DETALLES DE LA RUTA - </h3></div>

                        {daysList.map((day, index) => (
                                <Grid container spacing={0} className='request-oder-list-product' key={index}>

                                    <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                        <span className='date-picker-label'>Día</span>
                                        <DatePicker
                                            dateFormat="MMMM - d / yyyy"
                                            value={day.date}
                                            minDate={startDate}
                                            maxDate={endDate}
                                            locale="es-ES"
                                            calendarIcon={<CalendarTodayIcon/>}
                                            disabled={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Ciudades / Lugar"
                                            className='inputTextField'
                                            value={day.cities}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            disabled={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={5} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Observaciones"
                                            className='inputTextField'
                                            value={day.observations}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            disabled={true}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={1} className='inputTextFieldContainer'>
                                        <Button variant="contained" className='flomecol-danger-button' fullWidth onClick={() => deleteDay(index)} >
                                            <DeleteForeverIcon/>
                                        </Button>
                                    </Grid>

                                </Grid>
                            ))}

                        <Grid container spacing={0} className='request-oder-list-product'>

                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                <span className='date-picker-label'>Día</span>
                                <DatePicker
                                    dateFormat="MMMM - d / yyyy"
                                    value={newDayDate}
                                    minDate={startDate}
                                    maxDate={endDate}
                                    onChange={handleNewDayDateChange}
                                    locale="es-ES"
                                    calendarIcon={<CalendarTodayIcon/>}
                                />
                            </Grid>

                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Ciudades / Lugar"
                                    className='inputTextField'
                                    value={newDayCities}
                                    onChange={handleNewDayCitiesChange}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={5} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Observaciones"
                                    className='inputTextField'
                                    value={newDayObservations}
                                    onChange={handleNewDayObservationsChange}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={1} className='inputTextFieldContainer'>
                                <Button variant="contained" color="primary" onClick={() => addDay()}  className='inputTextFieldContainerFull' fullWidth >
                                    <AddCircleOutlineIcon/>
                                </Button>
                            </Grid>

                        </Grid>

                        <Grid item xs={12} md={12} className='inputTextFieldContainer'>
                            <TextField
                                id="outlined-multiline-static"
                                label="Objetivo de la ruta"
                                multiline
                                rows="4"
                                className='inputTextField'
                                margin="normal"
                                variant="outlined"
                                onChange={handleSetGoalChange}
                                value={goal}
                                fullWidth
                            />
                        </Grid>

                        <div className='modalTitle'><h3>- COSTOS DE LA RUTA - </h3></div>

                        <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                            <FormControl variant="outlined" className='inputSelectContainer'>
                                <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
                                    Tipo de transporte
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="transportType-simple-select-outlined"
                                    value={transportType}
                                    onChange={handleTransportTypeChange}
                                    labelWidth={145}
                                    fullWidth
                                >
                                <MenuItem value="">
                                    <em>Seleccione tipo de transporte</em>
                                </MenuItem>
                                    <MenuItem value={'AVION'}>AVION</MenuItem>
                                    <MenuItem value={'BUS - FLOTA'}>BUS - FLOTA</MenuItem>
                                    <MenuItem value={'VEHICULO PROPIO'}>VEHICULO PROPIO</MenuItem>
                                    <MenuItem value={'VEHICULO DE LA EMPRESA'}>VEHICULO DE LA EMPRESA</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={2} className='inputTextFieldContainer'>
                            <TextField
                                id="outlined-name"
                                label="Costos de transporte"
                                className='inputTextField'
                                value={transportationCost}
                                onBlur={handleTransportationCostChange}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    endAdornment: <InputAdornment position="end">COP</InputAdornment>,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={2} className='inputTextFieldContainer'>
                            <TextField
                                id="outlined-name"
                                label="Costos de alimentación"
                                className='inputTextField'
                                value={foodCost}
                                onBlur={handleFoodCostChange}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    endAdornment: <InputAdornment position="end">COP</InputAdornment>,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={2} className='inputTextFieldContainer'>
                            <TextField
                                id="outlined-name"
                                label="Costos de hospedaje"
                                className='inputTextField'
                                value={hostingCost}
                                onBlur={handleHostingCostChange}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    endAdornment: <InputAdornment position="end">COP</InputAdornment>,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={2} className='inputTextFieldContainer'>
                            <TextField
                                id="outlined-name"
                                label="Otros costos"
                                className='inputTextField'
                                value={othersCost}
                                onBlur={handleOthersCostChange}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    endAdornment: <InputAdornment position="end">COP</InputAdornment>,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                            <TextField
                                id="outlined-name"
                                label="Costo total de la solicitud"
                                className='inputTextField'
                                value={totalCost}
                                disabled={true}
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    endAdornment: <InputAdornment position="end">COP</InputAdornment>,
                                }}
                            />
                        </Grid>

                        {
                            modalType === 'create' &&
                                <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                                    <Button variant="contained" color="primary" onClick={() => doCreateRequest()} fullWidth className="flomecolButtonHeight">
                                        <AddCircleIcon/> &nbsp; CREAR NUEVA SOLICITUD
                                    </Button>
                                </Grid>
                        }
                        {   modalType === 'edit' &&
                                <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                                    <Button variant="contained" color="primary" onClick={() => doEditRequest()} fullWidth>
                                        <Edit/> &nbsp; EDITAR SOLICITUD
                                    </Button>
                                </Grid>
                        }
                    </Grid>
                </div>
            </Modal>

            <Grid container spacing={0} className='ModuleMainContainer'>
                {
                    editPermission && <React.Fragment>
                        <Button variant="contained" color="primary" onClick={() => handleEditCreateModalOpen()} className="flomecolButtonHeight">
                            <AddCircleIcon/> &nbsp; CREAR NUEVA SOLICITUD DE VIÁTICOS
                        </Button>
                    </React.Fragment>
                }
                <Grid container spacing={0} className='tables-container'>
                    <Grid item xs={12}>
                        <h3 className='table-title'>LISTADO DE SOLICITUDES DE VIÁTICOS</h3>
                    </Grid>

                    <AppBar position="static" className="tabs-root">
                        <Tabs variant="fullWidth" value={tabValue} onChange={handleTabValueChange} classes={{ root: "tabs-tabs-root", indicator: "tabs-indicator" }}>
                            <Tab label="EN PROCESO" classes={{ root: "tabs-tabs-root"}}></Tab>
                            <Tab label="AUTORIZADAS" classes={{ root: "tabs-tabs-root"}}></Tab>
                        </Tabs>
                    </AppBar>

                    {tabValue === 0 &&

                        <Grid item xs={12}>
                            <BootstrapTable
                                keyField='id'
                                data={ requestsInProccess }
                                columns={ columns }
                                rowStyle={ rowStyle2 }
                                classes='bootstrap-table'
                                wrapperClasses='bootstrap-table-wrapper'
                                headerClasses='bootstrap-table-header'
                                pagination={ paginationFactory()}
                            />
                        </Grid>
                    }

                    {tabValue === 1 &&

                        <Grid item xs={12}>
                            <BootstrapTable
                                keyField='id'
                                data={ requestsAuthorized }
                                columns={ columns }
                                rowStyle={ rowStyle2 }
                                classes='bootstrap-table'
                                wrapperClasses='bootstrap-table-wrapper'
                                headerClasses='bootstrap-table-header'
                                pagination={ paginationFactory()}
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>

        </>
    )
}

export default ViaticosComponent