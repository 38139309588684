export const formatDate = (date) => {
    if(date){
        let monthNames = [
            "Enero", "Febrero", "Marzo",
            "Abril", "Mayo", "Junio", "Julio",
            "Agosto", "Septiembre", "Octubre",
            "Noviembre", "Diciembre"
        ];
        let day = date.getDate();
        let monthIndex = date.getMonth();
        let year = date.getFullYear();
        return day + ' ' + monthNames[monthIndex] + ' ' + year;
    }else{
        return '';
    }
}

export const fromDateToString = (date) => {
    if(date){
        let date_ = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
        let time_ = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
        return date_ + ' ' + time_;
    }else{
        return null;
    }
}

export default {
    formatDate,
    fromDateToString,
};