import React from 'react';
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import Logo from "../../images/Logo_flomecol.svg";
import PdfViewerComponent from "../PdfViewerComponent/PdfViewerComponent";
import DialogComponent from "../DialogComponent/DialogComponent";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import TextField from "@material-ui/core/TextField";
import DatePicker from "react-date-picker";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { withStyles } from '@material-ui/core/styles';
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import axiosClient from "../../services/apiClient";
import {
    API_URL,
    CREATE_REQUEST_ORDER, GET_ALLY_REQUESTED_ORDERS,
    GET_REQUESTED_ORDERS_PDF, GET_USER_BY_NAME, READ_PRODUCTS, GET_ALL_CITIES
} from "../../API";
import NumberFormat from "react-number-format";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import {FLOMECOL_IS_USERNAME} from "../../SETTINGS";
import {message_responses} from "../../MESSAGE_RESPONSES";
import Autocomplete from '@material-ui/lab/Autocomplete';

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#336636',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#336636',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#336636',
                borderWidth: '2px',
            },
            '&:hover fieldset': {
                borderColor: '#336636',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#336636',
            },
        },
    },
})(TextField);

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#336636',
    },
    tabsRoot: {
        borderBottom: '1px solid #1d1d1d',
    },
    tabsIndicator: {
        backgroundColor: '#ffffff',
        height: "4px",
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        fontFamily: "\"Montserrat\", sans-serif !important",
        fontSize: "18px",
        '&:hover': {
            color: '#ffffff',
            opacity: 5,
        },
        '&:focus': {
            color: '#ffffff',
        },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing.unit * 3,
    },
    button: {
        margin: theme.spacing.unit,
    },
    input: {
        display: 'none',
    },
});

class AllyRequestedOrdersComponent extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            editCreateModalOpen: false,
            selectedOption: {
                direct_discount: 0,
                id: '',
                client_name: '',
                business_name: '',
                nit: '',
                department: '',
                city: '',
                neighborhood: '',
                email: '',
                address: '',
                mobile1: '',
                mobile2: '',
            },
            selectedProduct: {},
            order_date: new Date(),
            shipping_date: new Date(),
            client_name: '',
            business_name: '',
            nit:  '',
            department: '',
            city: '',
            neighborhood:  '',
            address: '',
            mobile1:  '',
            mobile2:  '',
            email: '',
            client: '',
            seller: '',
            order_total_cost: 0,
            general_observations:'',
            direct_discount: 0,
            discount_prompt_payment: 0,
            invoice_term: '',
            payment_date: '',
            payment_date_value: '',
            total_discount: 0,
            openOrderCreatedSuccess: false,
            messageOrderCreatedSuccess: '',

            products: [],
            orders: [],
            ended_orders: [],

            loadedProducts: [],

            invoiceTerms: [
                {value: 'Contado', label: 'Contado'},
                {value: '5 días', label: '5 días'},
                {value: '10 días', label: '10 días'},
                {value: '15 días', label: '15 días'},
                {value: '30 días', label: '30 días'},
            ],

            transporters: [
                {value: 'Servientrega', label: 'Servientrega'},
                {value: 'Envia', label: 'Envia'},
                {value: 'Surenvios', label: 'Surenvios'},
                {value: 'Encoexpress', label: 'Encoexpress'},
                {value: 'Domicilios Bogota', label: 'Domicilios Bogota'},
                {value: 'Terminal', label: 'Terminal'},
            ],

            value: 0,

            sendOrderModalOpen: false,
            selectedSendOrder: {
                products: [],
                order_date: new Date(),
            },

            pdfViewer: false,
            pdfURL: '',

            loadingCreatingOrder: false,
            cities: [],
            dispatchCity: '',

        }
    }

    componentDidMount() {
        this._setAllyData();
        this._updateAllyOrders();
        this._updateProducts();
        this._updateCities();
    }

    _setAllyData(){
        let data = JSON.parse(localStorage.getItem('ally'));
        let selectedOption = {
            direct_discount: data.direct_discount ? data.direct_discount === "" ? 0 : data.direct_discount : 0,
            id: data.id,
            client_name: data.client_name,
            business_name: data.business_name,
            nit: data.nit,
            department: data.department,
            city: data.city,
            neighborhood: data.neighborhood,
            email: data.email,
            address: data.address,
            mobile1: data.mobile1,
            mobile2: data.mobile2,
        }
        this.setState({selectedOption})
    }

    deleteProduct(id){
        this.setState({
            products: this.state.products.filter((product) => product.id !== id)
        })
    }

    handleProductListChange(event, field, id){

        if(field === 'quantity'){
            let products = this.state.products;
            let index = products.findIndex((product) => product.id === id);
            products[index][field] = parseInt(event.target.value) < 0 ? 0 : parseInt(event.target.value);
            products[index]['total_price'] = parseInt(event.target.value) < 0 ? 0 : parseInt(event.target.value)*products[index]['product_price'];
            this.setState({ products }, () => {
                this.calculateTotalCost();
            })
        }else{
            let products = this.state.products;
            let index = products.findIndex((product) => product.id === id);
            products[index][field] = parseInt(event.target.value) < 0 ? 0 : parseInt(event.target.value);
            this.setState({ products })
        }
    }

    _updateCities(){
      axiosClient.get(API_URL + GET_ALL_CITIES).then((response) => {
            this.setState({
                cities: response.data
            })
        }).catch((error) => {
            if(error.response && error.response.data && error.response.data.message){
                alert(error.response.data.message)
            }else{
                alert("ERROR: error de servidor cargando listado de ciudades.")
            }
        })
    }

    calculateTotalCost(){
        let order_total_cost = 0;
        this.state.products.forEach((product) => { order_total_cost += product.total_price })
        let direct_discount = this.state.selectedOption.direct_discount ? parseInt(this.state.selectedOption.direct_discount) : 0;
        let discount_prompt_payment = this.state.discount_prompt_payment.length > 0 ? parseInt(this.state.discount_prompt_payment) : 0;
        let total_discount =  direct_discount + discount_prompt_payment;
        order_total_cost -= (order_total_cost*total_discount/100);
        this.setState({order_total_cost})
    }

    _updateProducts(){
      axiosClient.get(API_URL + READ_PRODUCTS).then((response) => {
            response.data.forEach(function(product) {
                product.label = product.product_name + ' ' + product.product_presentation + ' - $' + product.product_price;
            });

            let products = response.data.filter((product) => product.status === 'active')

            this.setState({
                loadedProducts:products
            })
        }).catch((error) => {
            if(error.response && error.response.data && error.response.data.message){
                alert(error.response.data.message)
            }else{
                alert("ERROR: error de servidor cargando listado de productos")
            }
        })
    }

    _updateAllyOrders(){
        if(localStorage.getItem('ally')){
            let data = JSON.parse(localStorage.getItem('ally'));
            let id = data.id;
            axiosClient.get(API_URL + GET_ALLY_REQUESTED_ORDERS + id).then((response) => {
                let orders = [];
                let ended_orders = [];
                response.data.forEach((requestedOrder) => {
                    requestedOrder.order_date = new Date(requestedOrder.order_date);
                    requestedOrder.order_date_to_show = this.formatDate(new Date(requestedOrder.order_date));

                    requestedOrder.tentative_shipping_date = new Date(requestedOrder.tentative_shipping_date);
                    requestedOrder.tentative_shipping_date_to_show = this.formatDate(new Date(requestedOrder.tentative_shipping_date));

                    requestedOrder.total_price_with_discount_text = requestedOrder.total_price_with_discount;
                    requestedOrder.total_price_with_discount = <NumberFormat
                        value={requestedOrder.total_price_with_discount}
                        displayType={'text'}
                        prefix={'$'}
                        decimalScale={0}
                        thousandSeparator={","}
                        decimalSeparator={"."}
                        fixedDecimalScale={true}
                    />;

                    if(requestedOrder.status === 'SIN_DESPACHAR'){
                        let actions = <React.Fragment>
                            <a
                                href={API_URL + GET_REQUESTED_ORDERS_PDF + requestedOrder.id}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='download-order-pdf-eye'
                            >
                                <Button
                                    className='coin-button-table-disable'
                                >
                                    <RemoveRedEye/>
                                </Button>

                            </a>
                        </React.Fragment>
                        requestedOrder.actions = actions;
                        orders.push(requestedOrder);
                    }

                    if(requestedOrder.status === 'DESPACHADA'){
                        let actions = <React.Fragment>
                            <a
                                href={API_URL + GET_REQUESTED_ORDERS_PDF + requestedOrder.id}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='download-order-pdf-eye'
                            >
                                <Button
                                    className='coin-button-table-disable'
                                >
                                    <RemoveRedEye/>
                                </Button>

                            </a>
                        </React.Fragment>
                        requestedOrder.actions = actions;
                        ended_orders.push(requestedOrder);
                    }

                })

                this.setState({
                    orders:orders.reverse(),
                    ended_orders:ended_orders.reverse(),
                })
            }).catch((error) => {
                if(error.response && error.response.data && error.response.data.message){
                    alert(error.response.data.message)
                }else{
                    alert("ERROR: error de servidor cargando listado de ordenes de pedido.")
                }
            })
        }
    }

    closePdfViewer(){
        this.setState({pdfViewer:false})
    }

    handleCloseOrderCreatedSuccess(){
        this.setState({openOrderCreatedSuccess:false})
    }

    handleEditCreateModalClose(){
        this.setState({
            editCreateModalOpen:false
        })
    }

    handleChange = event => {

        let name = event.target.name;

        if(name === 'discount_prompt_payment'){
            this.setState({ [event.target.name] : event.target.value }, () => {
                this.calculateTotalCost();
            })
        }else{
            this.setState({ [event.target.name] : event.target.value })
        }
    };

    handleSelectedProductChange = selectedProduct => {
        this.setState({ selectedProduct });
    }

    formatDate(date){
        if(date){
            let monthNames = [
                "Enero", "Febrero", "Marzo",
                "Abril", "Mayo", "Junio", "Julio",
                "Agosto", "Septiembre", "Octubre",
                "Noviembre", "Diciembre"
            ];
            let day = date.getDate();
            let monthIndex = date.getMonth();
            let year = date.getFullYear();
            return day + ' ' + monthNames[monthIndex] + ' ' + year;
        }else{
            return '';
        }

    }

    addProduct(){

        if( ! (Object.keys(this.state.selectedProduct).length === 0 && this.state.selectedProduct.constructor === Object)){

            let product = this.state.products.find((product) => product.id === this.state.selectedProduct.id);

            if(product === undefined){
                let product_list = this.state.products;
                let new_product = {
                    id: this.state.selectedProduct.id,
                    product_name: this.state.selectedProduct.product_name,
                    product_presentation: this.state.selectedProduct.product_presentation,
                    product_price: this.state.selectedProduct.product_price,
                    quantity: '',
                    bonus_quantity: 0,
                    total_price: '',
                    /*pending_quantity: '',
                    product_batch: '',*/
                }


                product_list.push(new_product);
                this.setState({
                    products: product_list,
                    selectedProduct: {}
                })
            }else{
                alert("Este producto ya ha sido agregado a la lista.")
            }
        }else{
            alert("Debes selecionar un producto de la lista.")
        }
    }

    handleTermSelectChange = invoice_term => {
        this.setState({ invoice_term }, () => {
            let value = this.state.invoice_term.label;
            switch (value){
                case 'Contado':
                    value = 0;
                    break;
                case '5 días':
                    value = 5;
                    break;
                case '10 días':
                    value = 10;
                    break;
                case '15 días':
                    value = 15;
                    break;
                case '30 días':
                    value = 30;
                    break;
                default:
                    value = 0;
                    break;
            }

            let add = value < 0 ? 0 : parseInt(value);
            let date = new Date(this.state.order_date);
            date.setDate(date.getDate() + add);

            this.setState({
                payment_date: date,
                payment_date_value: date,
            })

        });
    };

    handleShippingDateChange = (date) => {
        this.setState({
            shipping_date: date === null ? new Date() : date,
        });
    }

    static fromDateToString(my_date){
        if(my_date){
            let date = my_date.getFullYear()+'-'+(my_date.getMonth()+1)+'-'+my_date.getDate();
            let time = my_date.getHours() + ":" + my_date.getMinutes() + ":" + my_date.getSeconds();
            return date + ' ' + time;
        }else{
            return null;
        }
    }

    doCreateOrder(){
        this.setState({loadingCreatingOrder:true})
        if(this.validateFields()){
            let order_date = AllyRequestedOrdersComponent.fromDateToString(this.state.order_date);
            let tentative_shipping_date = AllyRequestedOrdersComponent.fromDateToString(this.state.shipping_date);
            let payment_date = AllyRequestedOrdersComponent.fromDateToString(this.state.payment_date);

            let formDataUserName = {
                name: FLOMECOL_IS_USERNAME,
            }

            axiosClient.post(API_URL + GET_USER_BY_NAME, formDataUserName).then((response) => {
                if(response.data.success){
                    let flomecol_is_username_id = response.data.data.id;
                    let formData = {
                        client_id: this.state.selectedOption.id,
                        order_date,
                        tentative_shipping_date,
                        seller: FLOMECOL_IS_USERNAME,
                        direct_discount: this.state.selectedOption.direct_discount,
                        discount_prompt_payment: parseInt(this.state.discount_prompt_payment),
                        invoice_term: this.state.invoice_term.value,
                        payment_date,
                        general_observations: this.state.general_observations,
                        created_by: flomecol_is_username_id,
                        products: this.state.products,
                        total_discount: parseInt(this.state.discount_prompt_payment)+this.state.selectedOption.direct_discount,
                        total_price: (parseInt(this.state.order_total_cost)*(parseInt(this.state.discount_prompt_payment) + parseInt(this.state.selectedOption.direct_discount))/(100-(parseInt(this.state.discount_prompt_payment) + parseInt(this.state.selectedOption.direct_discount))))+(parseInt(this.state.order_total_cost)),
                        total_price_with_discount: parseInt(this.state.order_total_cost),
                        status: 'SIN_DESPACHAR',
                    }

                    axiosClient.post(API_URL + CREATE_REQUEST_ORDER, formData).then((response) =>{
                        this.setState({loadingCreatingOrder:false})
                        if(response.data){
                            if(response.data.success){
                                let order_id = response.data.data.order_id;

                                this.setState({
                                    openOrderCreatedSuccess: true,
                                    messageOrderCreatedSuccess: <div>ORDEN DE PEDIDO CREADA CORRECTAMENTE: <br/> <strong># {order_id}</strong></div>,
                                    editCreateModalOpen: false,
                                    selectedOption: {
                                        direct_discount: 0
                                    },
                                    selectedProduct: {},
                                    order_date: new Date(),
                                    shipping_date: new Date(),
                                    client_name: '',
                                    business_name: '',
                                    nit:  '',
                                    department: '',
                                    city: '',
                                    neighborhood:  '',
                                    address: '',
                                    mobile1:  '',
                                    mobile2:  '',
                                    email: '',
                                    client: '',
                                    seller: '',
                                    order_total_cost: 0,
                                    general_observations:'',
                                    direct_discount: 0,
                                    discount_prompt_payment: 0,
                                    invoice_term: '',
                                    payment_date: '',
                                    payment_date_value: '',
                                    total_discount: 0,
                                    products: [],
                                    shipping_observations: '',
                                })
                                this._updateAllyOrders();
                            }else{
                                alert("ERROR")
                            }
                        }else{
                            alert("ERROR")
                        }
                    }).catch((error) => {
                        this.setState({loadingCreatingOrder:false})
                        alert(error)
                    })
                }else{
                    alert(message_responses[response.data.message]);
                    this.setState({loadingCreatingOrder:false})
                }
            }).catch((error) => {
                if(error.response && error.response.data){
                    alert(error.response.data.message === 'VALIDATOR_ERROR' ? error.response.data.data.error_message : message_responses[error.response.data.message])
                }else{
                    alert("ERROR DE SERVIDOR: Hemos tenido un error con esta petición al servidor, si el problema continua por favor contactar al área encargada.")
                }
                this.setState({loadingCreatingOrder:false})
            })




        }else{
            this.setState({loadingCreatingOrder:false})
        }
    }

    validateFields(){
        return this.validateProducts() &&
            this.validateDirectDiscount() &&
            this.validateDiscountPromptPayment() &&
            this.validateCity() &&
            this.validateInvoiceTerm() &&
            this.validateCityLevel() &&
            this.validateGeneralObservations();
    }

    validateProducts(){
        if(this.state.products.length === 0){
            alert("Debes seleccionar al menos un producto.")
            return false;
        }else{

            let validation = true;

            this.state.products.forEach((product) => {

                if(product.bonus_quantity === ''){
                    alert("Falta llenar el campo de cantidad de bono del producto: " + product.product_name + ' ' + product.product_presentation)
                    validation = false;
                    return false;
                }

                if(product.quantity === ''){
                    alert("Falta llenar el campo de cantidad del producto: " + product.product_name + ' ' + product.product_presentation)
                    validation = false;
                    return false;
                }



                /*if(product.pending_quantity === ''){
                    alert("Falta llenar el campo de cantidad pendiente del producto: " + product.product_name + ' ' + product.product_presentation)
                    validation = false;
                    return false;
                }

                if(product.product_batch === ''){
                    alert("Falta llenar el campo de lote del producto: " + product.product_name + ' ' + product.product_presentation)
                    validation = false;
                    return false;
                }*/
            })

            return validation;
        }
    }

    validateDirectDiscount(){
        if(this.state.direct_discount === ''){
            alert("Debes llenar un porcentaje de descuento directo.")
            return false;
        }else{
            return true;
        }
    }

    validateDiscountPromptPayment(){
        if(this.state.discount_prompt_payment === ''){
            alert("Debes llenar un porcentaje de descuento por pronto pago.")
            return false;
        }else{
            return true;
        }
    }

    validateCity(){
        if(this.state.dispatchCity === '' || this.state.dispatchCity === undefined || this.state.dispatchCity === null){
            alert("Debes seleccionar la ciudad de destino.")
            return false;
        }else{
            return true;
        }
    }

    validateInvoiceTerm(){
        if(this.state.invoice_term === ''){
            alert("Debes llenar los dias de plazo para el pago.")
            return false;
        }else{
            return true;
        }
    }

    validateGeneralObservations(){
        if(this.state.general_observations === ''){
            return window.confirm('Esta seguro de enviar esta orden de pedido sin observaciones?');
        }else{
            return true;
        }
    }

    validateCityLevel(){
        let total_cost = this.state.order_total_cost;
        let level = this.state.dispatchCity.level;

        if(level === 0 && total_cost <= 35000){
            alert("Para domicilios el monto mínimo con descuentos del pedido debe ser de $35.000.")
            return false;
        }

        if(level === 1 && total_cost <= 150000){
            alert("Para ciudades principales el monto mínimo con descuentos del pedido debe ser de $150.000.")
            return false;
        }

        if(level === 2 && total_cost <= 400000){
            alert("Para ciudades secundarias el monto mínimo con descuentos del pedido debe ser de $400.000.")
            return false;
        }

        if(level === 3 && total_cost <= 500000){
            alert("Para ciudades terciarias el monto mínimo con descuentos del pedido debe ser de $500.000.")
            return false;
        }

        return true;
    }

    handleEditCreateModalOpen(){
        this.setState({
            editCreateModalOpen:true
        })
    }

    handleChangeValue = (event, value) => {
        this.setState({ value });
    };

    handleDateChange = (date) => {
        this.setState({
            order_date: date === null ? new Date() : date,
        });
    }

    setSelectedDispatchCity(value){
        this.setState({dispatchCity: value})
    }

    render(){

        const { classes } = this.props;

        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                padding: 20,
                cursor: 'pointer',
                color: state.isFocused ? '#336636' : 'black',
                backgroundColor: state.isFocused ? '#FFFBCE' : 'white',
            }),
            menu: () => ({
                // none of react-select's styles are passed to <Control />
                backgroundColor: 'white',
                position: 'absolute',
                zIndex: '1000',
                width: '100%',
            }),
            input: () => ({
                // none of react-select's styles are passed to <Control />
                height: '52px',
            }),
        };

        const columns = [
            {
                dataField: 'id',
                text: '#',
                sort: true
            },{
                dataField: 'client_name',
                text: 'Cliente',
                sort: true
            },{
                dataField: 'business_name',
                text: 'Razón Social',
                sort: true
            },{
                dataField: 'seller',
                text: 'Vendedor',
                sort: true
            },{
                dataField: 'order_date_to_show',
                text: 'Orden',
            },{
                dataField: 'tentative_shipping_date_to_show',
                text: 'Despacho',
            },{
                dataField: 'total_price_with_discount',
                text: 'Precio Total',
            },{
                dataField: 'actions',
                text: 'Acciones',
                classes: 'actions-custom-cell'
            }
        ];

        const rowStyle2 = (row, rowIndex) => {
            const style = {};


            style.color = '#000000';
            style.height = '45px';
            style.backgroundColor = 'white';
            style.cursor = 'pointer';

            return style;
        };

        return(
            <React.Fragment>
                <LoadingComponent loadingImage={Logo} loadingText={'Creando nueva orden'} loadingState={this.state.loadingCreatingOrder}/>
                <PdfViewerComponent
                    handleClose={() => this.closePdfViewer()}
                    open={this.state.pdfViewer}
                    pdfURL={this.state.pdfURL}
                />

                <DialogComponent
                    handleClose={() => this.handleCloseOrderCreatedSuccess()}
                    open={this.state.openOrderCreatedSuccess}
                    message={this.state.messageOrderCreatedSuccess}
                    messageType='SUCCESS'
                />

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.editCreateModalOpen}
                    onClose={this.handleEditCreateModalClose.bind(this)}
                    className='flomecolModalContainer'
                >
                    <div className='flomecolModalContent'>

                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <div className='modalTitle'><h2>NUEVA ORDEN DE PEDIDO</h2></div>
                            </Grid>

                            <Grid item xs={12} md={12} className='inputTextFieldContainer'>
                                <ExpansionPanel className='flomecol-expansion-panel'>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.heading}>Detalles de cliente</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>

                                        <Grid container spacing={0}>

                                            <Grid item xs={12} md={9} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Cliente"
                                                    className='inputTextField'
                                                    name='client_name'
                                                    value={this.state.selectedOption.client_name}
                                                    onChange={this.handleChange.bind(this)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <span className='date-picker-label'>Fecha de orden</span>
                                                <DatePicker
                                                    calendarAriaLabel='sdfsd'
                                                    dateFormat="MMMM - d / yyyy"
                                                    value={this.state.order_date}
                                                    onChange={this.handleDateChange.bind(this)}
                                                    minDate={new Date()}
                                                    locale="es-ES"
                                                    calendarIcon={<CalendarTodayIcon/>}
                                                    disabled
                                                    disableCalendar={true}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={9} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Razón Social"
                                                    className='inputTextField'
                                                    name='business_name'
                                                    value={this.state.selectedOption.business_name}
                                                    onChange={this.handleChange.bind(this)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="NIT"
                                                    className='inputTextField'
                                                    name='nit'
                                                    value={this.state.selectedOption.nit}
                                                    onChange={this.handleChange.bind(this)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Departamento"
                                                    className='inputTextField'
                                                    name='department'
                                                    value={this.state.selectedOption.department}
                                                    onChange={this.handleChange.bind(this)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Ciudad"
                                                    className='inputTextField'
                                                    name='city'
                                                    value={this.state.selectedOption.city}
                                                    onChange={this.handleChange.bind(this)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Barrio"
                                                    className='inputTextField'
                                                    name='neighborhood'
                                                    value={this.state.selectedOption.neighborhood}
                                                    onChange={this.handleChange.bind(this)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Correo"
                                                    className='inputTextField'
                                                    name='email'
                                                    value={this.state.selectedOption.email}
                                                    onChange={this.handleChange.bind(this)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Dirección"
                                                    className='inputTextField'
                                                    name='address'
                                                    value={this.state.selectedOption.address}
                                                    onChange={this.handleChange.bind(this)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Telefono 1"
                                                    className='inputTextField'
                                                    name='mobile1'
                                                    value={this.state.selectedOption.mobile1}
                                                    onChange={this.handleChange.bind(this)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Telefono 2"
                                                    className='inputTextField'
                                                    name='mobile2'
                                                    value={this.state.selectedOption.mobile2}
                                                    onChange={this.handleChange.bind(this)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                        </Grid>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>

                            {this.state.products.map((product) => (
                                <Grid container spacing={0} className='request-oder-list-product' key={product.id}>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Nombre producto"
                                            className='inputTextField'
                                            name='list_product_name'
                                            value={product.product_name}
                                            margin="normal"
                                            variant="outlined"
                                            disabled={true}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Presentación"
                                            className='inputTextField'
                                            name='list_product_presentation'
                                            value={product.product_presentation}
                                            margin="normal"
                                            variant="outlined"
                                            disabled={true}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Precio"
                                            className='inputTextField'
                                            name='list_product_price'
                                            value={product.product_price}
                                            margin="normal"
                                            variant="outlined"
                                            disabled={true}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Precio Total"
                                            className='inputTextField'
                                            name='total_price'
                                            type='number'
                                            value={product.total_price}
                                            disabled={true}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={1} className='inputTextFieldContainer'>
                                        <CssTextField
                                            id="outlined-name"
                                            label="Bono"
                                            className='inputTextField'
                                            name='list_product_bonus_quantity'
                                            type='number'
                                            value={product.bonus_quantity}
                                            disabled={true}
                                            onChange={(event) => this.handleProductListChange(event,'bonus_quantity', product.id)}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <CssTextField
                                            id="outlined-name"
                                            label="Cantidad"
                                            className='inputTextField'
                                            name='list_product_quantity'
                                            type='number'
                                            value={product.quantity}
                                            onChange={(event) => this.handleProductListChange(event,'quantity', product.id)}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={1} className='inputTextFieldContainer'>
                                        <Button variant="contained" className='flomecol-danger-button' fullWidth onClick={() => this.deleteProduct(product.id)} >
                                            <DeleteForeverIcon/>
                                        </Button>
                                    </Grid>

                                </Grid>
                            ))}

                            <Grid item xs={12} sm={9} className='inputTextFieldContainer'>
                                <strong className='create-requested-order-product-select'>SELECCIONE UN PRODUCTO PARA AGREGAR A LA ORDEN</strong>
                                <Select
                                    styles={customStyles}
                                    value={this.state.selectedProduct}
                                    onChange={this.handleSelectedProductChange}
                                    options={this.state.loadedProducts}
                                />

                            </Grid>

                            <Grid item xs={12} sm={3} className='inputTextFieldContainer'>
                                <Button variant="contained" color="primary" onClick={this.addProduct.bind(this)} className='inputTextFieldContainerFull'>
                                    AGREGAR PRODUCTO
                                </Button>
                            </Grid>

                            <hr className='hr-divider' />

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Descuento directo (%)"
                                    className='inputTextField'
                                    name='direct_discount'
                                    type='number'
                                    value={this.state.selectedOption.direct_discount}
                                    disabled={true}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Descuento pronto pago (%)"
                                    className='inputTextField'
                                    name='discount_prompt_payment'
                                    type='number'
                                    value={this.state.discount_prompt_payment}
                                    disabled={true}
                                    onChange={this.handleChange.bind(this)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Descuento total (%)"
                                    className='inputTextField'
                                    name='total_discount'
                                    type='number'
                                    value={parseInt(this.state.discount_prompt_payment) + parseInt(this.state.selectedOption.direct_discount)}
                                    disabled={true}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Costo total con descuento"
                                    className='inputTextField'
                                    name='order_total_cost'
                                    value={this.state.order_total_cost}
                                    margin="normal"
                                    variant="outlined"
                                    disabled={true}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Costo total sin descuento"
                                    className='inputTextField'
                                    name='order_total_cost'
                                    value={(parseInt(this.state.order_total_cost)*(parseInt(this.state.discount_prompt_payment) + parseInt(this.state.selectedOption.direct_discount))/(100-(parseInt(this.state.discount_prompt_payment) + parseInt(this.state.selectedOption.direct_discount))))+(parseInt(this.state.order_total_cost)) }
                                    margin="normal"
                                    variant="outlined"
                                    disabled={true}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    style={{ width: '100%' }}
                                    freeSolo={false}
                                    options={this.state.cities}
                                    getOptionLabel={option => option.name}
                                    value={this.state.dispatchCity}
                                    onChange={(event, newValue) => { this.setSelectedDispatchCity(newValue, event) }}
                                    autoHighlight
                                    renderInput={params => (
                                        <TextField 
                                            {...params} 
                                            label="Ciudad" 
                                            variant="outlined" 
                                            fullWidth
                                            required
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>

                                <Select
                                    styles={customStyles}
                                    value={this.state.invoice_term}
                                    onChange={this.handleTermSelectChange}
                                    options={this.state.invoiceTerms}
                                    placeholder='Plazo de pago (dias)'
                                    className='react-select-container'
                                />
                            </Grid>

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                <span className='date-picker-label'>Fecha de pago</span>
                                <DatePicker
                                    dateFormat="MMMM - d / yyyy"
                                    value={this.state.payment_date}
                                    minDate={new Date()}
                                    locale="es-ES"
                                    calendarIcon={<CalendarTodayIcon/>}
                                    disabled
                                    disableCalendar={true}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                <span className='date-picker-label'>Fecha de despacho</span>
                                <DatePicker
                                    dateFormat="MMMM - d / yyyy"
                                    value={this.state.shipping_date}
                                    onChange={this.handleShippingDateChange.bind(this)}
                                    minDate={new Date()}
                                    locale="es-ES"
                                    calendarIcon={<CalendarTodayIcon/>}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} className='inputTextFieldContainer'>
                                <CssTextField
                                    id="outlined-multiline-static"
                                    label="Observaciones Generales"
                                    multiline
                                    rows="4"
                                    className='inputTextField'
                                    margin="normal"
                                    variant="outlined"
                                    name='general_observations'
                                    onBlur={this.handleChange.bind(this)}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} className='inputTextFieldContainer'>
                                <Button variant="contained" color="primary" onClick={this.doCreateOrder.bind(this)} fullWidth>
                                    CREAR NUEVA ORDEN
                                </Button>
                            </Grid>

                        </Grid>

                    </div>
                </Modal>

                <Grid container spacing={0} className='ModuleMainContainer'>

                    <Button variant="contained" color="primary" onClick={this.handleEditCreateModalOpen.bind(this)}>
                        CREAR NUEVA ORDEN
                    </Button>

                    <Grid item xs={12}>
                        <h3 className='table-title'>LISTADO DE ORDENES DE PEDIDO</h3>
                    </Grid>

                    <AppBar position="static" className={classes.root}>
                        <Tabs variant="fullWidth" value={this.state.value} onChange={this.handleChangeValue} classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}>
                            <Tab label="SIN DESPACHAR" classes={{ root: classes.tabRoot, selected: classes.tabSelected }}/>
                            <Tab label="DESPACHADAS" classes={{ root: classes.tabRoot, selected: classes.tabSelected }}  />
                        </Tabs>
                    </AppBar>

                    {this.state.value === 0 &&

                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <BootstrapTable
                                keyField='id'
                                data={this.state.orders}
                                columns={columns}
                                rowStyle={rowStyle2}
                                classes='bootstrap-table'
                                wrapperClasses='bootstrap-table-wrapper'
                                headerClasses='bootstrap-table-header'
                                pagination={paginationFactory()}
                            />
                        </Grid>
                    </Grid>
                    }

                    {this.state.value === 1 &&

                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <BootstrapTable
                                keyField='id'
                                data={this.state.ended_orders}
                                columns={columns}
                                rowStyle={rowStyle2}
                                classes='bootstrap-table'
                                wrapperClasses='bootstrap-table-wrapper'
                                headerClasses='bootstrap-table-header'
                                pagination={paginationFactory()}
                            />
                        </Grid>
                    </Grid>
                    }

                </Grid>


            </React.Fragment>
        )
    }
}

export default withStyles(styles)(AllyRequestedOrdersComponent);