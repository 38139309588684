import React, { Fragment } from 'react';
import HeaderComponent from "../../components/HeaderComponent/HeaderComponent";
import FooterComponent from "../../components/FooterComponent/FooterComponent";
import ProductionControlComponent from "../../components/ProductionControlComponent/ProductionControlComponent";

const ProductionControl = () => {
    
    return(
        <Fragment>
            <HeaderComponent/>
            <ProductionControlComponent/>
            <FooterComponent/>
        </Fragment>
    )
    
}

export default ProductionControl;