import React from 'react';
import HeaderComponent from "../../components/HeaderComponent/HeaderComponent";
import FooterComponent from "../../components/FooterComponent/FooterComponent";
import RequestedOrdersComponent from "../../components/RequestedOrdersComponent/RequestedOrdersComponent";

class RequestedOrders extends React.Component{
    render(){
        return(
            <React.Fragment>
                <HeaderComponent/>
                <RequestedOrdersComponent/>
                <FooterComponent/>
            </React.Fragment>
        )
    }
}

export default RequestedOrders;