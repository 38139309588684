import React from 'react';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";
import AddCircleIcon from "@material-ui/icons/AddCircleOutline";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import CreateIcon from "@material-ui/icons/Create";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import {
    ACTIVATE_CLIENT_LOGIN_ACCESS,
    API_URL,
    CREATE_CLIENT,
    READ_CLIENTS,
    READ_CLIENTS_BY_SELLER_NAME, READ_SELLERS,
    REVOKE_CLIENT_LOGIN_ACCESS,
    UPDATE_CLIENT
} from "../../API";
import axiosClient from "../../services/apiClient";
import {Redirect} from "react-router-dom";
import {message_responses} from "../../MESSAGE_RESPONSES";
import Logo from "../../images/Logo_flomecol.svg";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import Select from "react-select";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from "react-number-format";
import InputAdornment from "@material-ui/core/InputAdornment";

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#336636',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#336636',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#336636',
                borderWidth: '2px',
            },
            '&:hover fieldset': {
                borderColor: '#336636',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#336636',
            },
        },
    },
})(TextField);

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
  }
class ClientsComponent extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            editCreateModalOpen: false,
            activateLoginOpen:false,
            modalType: '',
            clients: [],
            sellers: [],
            clients_filtered: [],

            /*Client info*/
            client_name: '',
            business_name: '',
            nit: '',
            mobile1: '',
            mobile2: '',
            email: '',
            direct_discount: '',
            city: '',
            department: '',
            neighborhood: '',
            address: '',
            client_id: null,
            redirectToLogin: false,

            canView: false,
            canEdit: false,
            canActivateLogin: false,
            canRevokeLogin: false,
            canSelectSeller: false,

            loadingRevokeLogin: false,
            loadingActivateLogin: false,

            selectedSeller: {},

            searchText: '',

            balance: 0,
        }
    }

    componentDidMount() {
        this._handlePermissions();
        this._updateClients();
        this._updateSellers();
    }

    _updateSellers(){
      axiosClient.get(API_URL + READ_SELLERS).then((response) => {
            let sellers = [];

            response.data.forEach((seller) => {
                let new_seller = {
                    label:seller,
                    value:seller,
                }

                sellers.push(new_seller)
            })

            this.setState({sellers})
        })
    }

    _handlePermissions(){

        if(localStorage.getItem('data')){
            let data = JSON.parse(localStorage.getItem('data'));
            switch (data.rol) {
                case 'administracion':
                    this.setState({
                        canView: true,
                        canEdit: true,
                        canActivateLogin: true,
                        canRevokeLogin: true,
                        canSelectSeller: true,
                    })
                    break;
                case 'ventas':
                    this.setState({
                        canView: true,
                        canEdit: true,
                        canActivateLogin: true,
                        canRevokeLogin: true,
                        canSelectSeller: false,
                    })
                    break;
                case 'facturacion':
                    this.setState({
                        canView: true,
                        canEdit: false,
                        canSelectSeller: false,
                    })
                    break;
                case 'control':
                    this.setState({
                        canView: true,
                        canEdit: false,
                        canSelectSeller: false,
                    })
                    break;
                default:
                    break;
            }
        }else{
            this.setState({redirectToLogin: true})
        }
    }

    _updateClients(){

        if(localStorage.getItem('data')){
            let data = JSON.parse(localStorage.getItem('data'));
            if (data.rol === 'administracion'){
              axiosClient.get(API_URL + READ_CLIENTS).then((response) => {

                    let clients = [];

                    response.data.forEach((client) => {

                        if(client.can_do_login === 0){
                            client.can_do_login = false;
                        }

                        if(client.can_do_login === 1){
                            client.can_do_login = true;
                        }

                        let actions = <React.Fragment>
                            {
                                this.state.canEdit ?
                                    <Button
                                        className='coin-button-table-disable'
                                        onClick={this.editClient.bind(this, client)}
                                    >
                                        <CreateIcon/>
                                    </Button>
                                    :
                                    ''
                            }

                            {
                                this.state.canActivateLogin && !client.can_do_login ?
                                    <Button
                                        className='coin-button-table-disable'
                                        onClick={this.activateLogin.bind(this, client)}
                                    >
                                        <PersonAddIcon/>
                                    </Button>
                                    :
                                    ''
                            }

                            {
                                this.state.canRevokeLogin && client.can_do_login ?
                                    <Button
                                        className='coin-button-table-disable'
                                        onClick={this.revokeLogin.bind(this, client)}
                                    >
                                        <PersonAddDisabledIcon/>
                                    </Button>
                                    :
                                    ''
                            }

                        </React.Fragment>


                        let new_client = client;
                        new_client.actions = actions;
                        clients.push(new_client);
                    })

                    this.setState({
                        clients:response.data,
                        clients_filtered: response.data
                    })
                }).catch((error) => {
                    if(error.response && error.response.data && error.response.data.message){
                        alert(error.response.data.message)
                    }else{
                        alert("ERROR: error de servidor cargando listado de clientes")
                    }
                })
            }else{
                let formData = {
                    seller_name: data.name
                }

                axiosClient.post(API_URL + READ_CLIENTS_BY_SELLER_NAME, formData).then((response) => {
                    let clients = [];

                    response.data.forEach((client) => {

                        let actions = <React.Fragment>
                            {
                                this.state.canEdit ?
                                    <Button
                                        className='coin-button-table-disable'
                                        onClick={this.editClient.bind(this, client)}
                                    >
                                        <CreateIcon/>
                                    </Button>
                                    :
                                    ''
                            }
                        </React.Fragment>


                        let new_client = client;
                        new_client.actions = actions;
                        clients.push(new_client);
                    })

                    this.setState({
                        clients:response.data,
                        clients_filtered: response.data
                    })
                }).catch((error) => {
                    if(error.response && error.response.data && error.response.data.message){
                        alert(error.response.data.message)
                    }else{
                        alert("ERROR: error de servidor cargando listado de clientes")
                    }
                })
            }
        }
    }

    editClient(client){

        let current_seller = {
            label:client.seller_name,
            value:client.seller_name,
        }

        this.setState({
            editCreateModalOpen:true,
            modalType: 'edit',
            client_name: client.client_name,
            business_name: client.business_name,
            nit:  client.nit,
            mobile1: client.mobile1,
            mobile2: client.mobile2,
            email: client.email,
            direct_discount: client.direct_discount,
            city: client.city,
            department: client.department,
            neighborhood: client.neighborhood,
            address: client.address,
            client_id: client.id,
            selectedSeller: current_seller,
            balance: client.balance,
        })
    }

    handleSelectedSellerChange = selectedSeller => {
        this.setState({ selectedSeller });
    }

    activateLogin(client){
        //activateRevokeLogin
        this.setState({
            activateLoginOpen:true,
            current_client: client,
        })
    }

    doActivateLogin(){
        //current_client
        this.setState({loadingActivateLogin:true})

        let formData = {
            password: this.state.clientPassword,
        }

        axiosClient.put(API_URL + ACTIVATE_CLIENT_LOGIN_ACCESS + this.state.current_client.id, formData).then((response) => {
            if(response.data.success){
                alert(message_responses[response.data.message]);
                this.handleActivateLoginModalClose();
                this._updateClients();
            }else{
                alert(message_responses[response.data.message]);
            }
            this.setState({loadingActivateLogin:false})
        }).catch((error) => {
            if(error.response && error.response.data){
                alert(error.response.data.message === 'VALIDATOR_ERROR' ? error.response.data.data.error_message : message_responses[error.response.data.message])
            }else{
                alert("ERROR DE SERVIDOR: Hemos tenido un error con esta petición al servidor, si el problema continua por favor contactar al área encargada.")
            }
            this.setState({loadingActivateLogin:false})
        })
    }

    revokeLogin(client){
        if(window.confirm("Está seguro de quitar el acceso al cliente ?")){
            this.setState({loadingRevokeLogin:true})
            axiosClient.put(API_URL + REVOKE_CLIENT_LOGIN_ACCESS + client.id).then((response) => {
                if(response.data.success){
                    alert(message_responses[response.data.message]);
                    this._updateClients();
                }else{
                    alert(message_responses[response.data.message]);
                }
                this.setState({loadingRevokeLogin:false})
            }).catch((error) => {
                if(error.response && error.response.data){
                    alert(error.response.data.message === 'VALIDATOR_ERROR' ? error.response.data.data.error_message : message_responses[error.response.data.message])
                }else{
                    alert("ERROR DE SERVIDOR: Hemos tenido un error con esta petición al servidor, si el problema continua por favor contactar al área encargada.")
                }
                this.setState({loadingRevokeLogin:false})
            })
        }
    }

    doEditClient(){

        let formData = {
            client_name: this.state.client_name !== ' ' && this.state.client_name !== '' && this.state.client_name ? this.state.client_name : undefined,
            business_name: this.state.business_name !== ' ' && this.state.business_name !== '' && this.state.business_name ? this.state.business_name : undefined,
            nit: this.state.nit !== ' ' && this.state.nit !== '' && this.state.nit ? this.state.nit : undefined,
            mobile1: this.state.mobile1 !== ' ' && this.state.mobile1 !== '' && this.state.mobile1 ? this.state.mobile1 : undefined,
            mobile2: this.state.mobile2 !== ' ' && this.state.mobile2 !== '' && this.state.mobile2 ? this.state.mobile2 : undefined,
            email: this.state.email !== ' ' && this.state.email !== '' && this.state.email ? this.state.email : undefined,
            direct_discount: this.state.direct_discount !== ' ' && this.state.direct_discount !== '' && this.state.direct_discount ? this.state.direct_discount : undefined,
            city: this.state.city !== ' ' && this.state.city !== '' && this.state.city ? this.state.city : undefined,
            department: this.state.department !== ' ' && this.state.department !== '' && this.state.department ? this.state.department : undefined,
            neighborhood: this.state.neighborhood !== ' ' && this.state.neighborhood !== '' && this.state.neighborhood ? this.state.neighborhood : undefined,
            address: this.state.address !== ' ' && this.state.address !== '' && this.state.address ? this.state.address : undefined,
            seller_name: this.state.selectedSeller.value !== ' ' && this.state.selectedSeller.value !== '' && this.state.selectedSeller.value && this.state.canSelectSeller ? this.state.selectedSeller.value : undefined,
            balance: this.state.balance,
        }

        axiosClient.put(API_URL + UPDATE_CLIENT + this.state.client_id, formData).then(() => {
            alert("CLIENTE EDITADO EXITOSAMENTE.");
            this.setState({
                editCreateModalOpen: false,
                client_name: '',
                business_name: '',
                nit: '',
                mobile1: '',
                mobile2: '',
                email: '',
                direct_discount: '',
                city: '',
                department: '',
                neighborhood: '',
                address: '',
                client_id: null,
                selectedSeller: {},
                balance: 0,
                searchText: '',
            })
            this._updateClients();
        }).catch((error) => {
            if(error.response){
                alert("SERVER ERROR => " + error.response.data.error);
            }else{
                alert("SERVER ERROR")
            }
        })
    }

    handleEditCreateModalOpen(){
        this.setState({
            editCreateModalOpen:true,
            modalType: 'create',
        })
    }

    handleEditCreateModalClose(){
        this.setState({
            editCreateModalOpen:false,
            client_name: '',
            business_name: '',
            nit: '',
            mobile1: '',
            mobile2: '',
            email: '',
            direct_discount: '',
            city: '',
            department: '',
            neighborhood: '',
            address: '',
            selectedSeller: {},
        })
    }

    handleActivateLoginModalClose(){
        this.setState({
            activateLoginOpen:false,
            clientPassword: '',
        })
    }

    handleChange = event => {
        this.setState({
            [event.target.name]:event.target.value
        })
    };

    doCreateClient(){
        if(localStorage.getItem('data')) {
            let data = JSON.parse(localStorage.getItem('data'));
            let seller_name = data.name;
            if(this.validateFields()){
                let formData = {
                    client_name: this.state.client_name,
                    business_name: this.state.business_name,
                    nit: this.state.nit,
                    mobile1: this.state.mobile1,
                    mobile2: this.state.mobile2,
                    email: this.state.email,
                    direct_discount: this.state.direct_discount,
                    city: this.state.city,
                    department: this.state.department,
                    neighborhood: this.state.neighborhood,
                    address: this.state.address,
                    seller_name: seller_name,
                    balance: this.state.balance
                }

                axiosClient.post(API_URL + CREATE_CLIENT, formData).then(() => {
                    alert("CLIENTE CREADO EXITOSAMENTE.");
                    this.setState({
                        editCreateModalOpen: false,
                        client_name: '',
                        business_name: '',
                        nit: '',
                        mobile1: '',
                        mobile2: '',
                        email: '',
                        direct_discount: '',
                        city: '',
                        department: '',
                        neighborhood: '',
                        address: '',
                        balance: 0,
                    })
                    this._updateClients();
                }).catch((error) => {
                    if(error.response){
                        alert("SERVER ERROR => " + error.response.data.error);
                    }else{
                        alert("SERVER ERROR")
                    }
                })
            }
        }else{
            alert("ERROR: Error creando cliente, porfavor salir de la aplicación y volver a ingresar.")
        }

    }

    validateFields(){
        return this.validateClientName() &&
            this.validateBusinessName() &&
            this.validateNit() &&
            this.validateMobile1() &&
            this.validateMobile2() &&
            this.validateEmail() &&
            this.validateDirectDiscount() &&
            this.validateCity() &&
            this.validateDepartment() &&
            this.validateNeighborhood() &&
            this.validateAddress();
    }

    validateClientName(){
        let client_name = this.state.client_name;

        let regExp = /^[a-z ,.'-]+$/i;
        let validation = regExp.test(client_name) && (client_name.length < 254 && client_name.length > 0);
        if (!validation){
            if(client_name.length > 0){
                this.setState({validateFormError : "Tienes un error escribiendo el Nombre"}, () => {alert(this.state.validateFormError)})
            }else{
                this.setState({validateFormError : "El campo nombre obligatorio"}, () => {alert(this.state.validateFormError)})
            }
            return false;
        }else{
            this.setState({validateFormError : ""})
            return true;
        }
    }

    validateBusinessName(){
        let business_name = this.state.business_name;

        if (business_name.length > 0 && business_name.length < 255){
            this.setState({validateFormError : ""})
            return true;
        }else{
            this.setState({validateFormError : "El campo razón social es obligatorio"}, () => {alert(this.state.validateFormError)})
            return false;
        }
    }

    validateNit(){
        let nit = this.state.nit;

        if (nit.length > 0 && nit.length < 255){
            this.setState({validateFormError : ""})
            return true;
        }else{
            this.setState({validateFormError : "El campo NIT es obligatorio"}, () => {alert(this.state.validateFormError)})
            return false;
        }
    }

    validateMobile1(){
        let mobile1 = this.state.mobile1;

        // eslint-disable-next-line no-useless-escape
        let regExp = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
        let validation = regExp.test(mobile1) && (mobile1.length < 254 && mobile1.length > 6);
        if (!validation){
            if(mobile1.length > 0){
                this.setState({validateFormError : "Tienes un error escribiendo el mobile 1"}, () => {alert(this.state.validateFormError)})
            }else{
                this.setState({validateFormError : "El campo mobile 1 es obligatorio"}, () => {alert(this.state.validateFormError)})
            }
            return false;
        }else{
            this.setState({validateFormError : ""})
            return true;
        }
    }

    validateMobile2(){
        let mobile2 = this.state.mobile2;

        // eslint-disable-next-line no-useless-escape
        let regExp = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
        let validation = regExp.test(mobile2) && (mobile2.length < 254 && mobile2.length > 6);
        if (mobile2.length > 0){
            if(!validation){
                this.setState({validateFormError : "Tienes un error escribiendo el mobile 2"}, () => {alert(this.state.validateFormError)})
                return false;
            }else{
                return true;
            }
        }else{
            return true;
        }
    }

    validateEmail(){
        let email = this.state.email;
        // eslint-disable-next-line no-useless-escape
        let regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let validation = regExp.test(email) && (email.length < 254 && email.length > 5);
        if (!validation){
            if(email.length > 0){
                this.setState({validateFormError : "Tienes un error escribiendo el Correo"}, () => {alert(this.state.validateFormError)})
            }else{
                this.setState({validateFormError : "El campo correo es obligatorio"}, () => {alert(this.state.validateFormError)})
            }
            return false;
        }else{
            this.setState({validateFormError : ""})
            return true;
        }
    }

    validateDirectDiscount(){
        let direct_discount = this.state.direct_discount;

        if (direct_discount.length > 0 && direct_discount.length < 255){
            this.setState({validateFormError : ""})
            return true;
        }else{
            this.setState({validateFormError : "El campo descuento directo es obligatorio"}, () => {alert(this.state.validateFormError)})
            return false;
        }
    }

    validateCity(){
        let city = this.state.city;

        if (city.length > 0 && city.length < 255){
            this.setState({validateFormError : ""})
            return true;
        }else{
            this.setState({validateFormError : "El campo ciudad es obligatorio"}, () => {alert(this.state.validateFormError)})
            return false;
        }
    }

    validateDepartment(){
        let department = this.state.department;

        if (department.length > 0 && department.length < 255){
            this.setState({validateFormError : ""})
            return true;
        }else{
            this.setState({validateFormError : "El campo departamento es obligatorio"}, () => {alert(this.state.validateFormError)})
            return false;
        }
    }

    validateNeighborhood(){
        let neighborhood = this.state.neighborhood;

        if (neighborhood.length > 0 && neighborhood.length < 255){
            this.setState({validateFormError : ""})
            return true;
        }else{
            this.setState({validateFormError : "El campo barrio es obligatorio"}, () => {alert(this.state.validateFormError)})
            return false;
        }
    }

    validateAddress(){
        let address = this.state.address;

        if (address.length > 0 && address.length < 255){
            this.setState({validateFormError : ""})
            return true;
        }else{
            this.setState({validateFormError : "El campo dirección es obligatorio"}, () => {alert(this.state.validateFormError)})
            return false;
        }
    }

    redirectToLogin = () => {
        if (this.state.redirectToLogin) {
            return <Redirect push to='/' />
        }
    }

    searchText = (e) => {
        this.setState({searchText: e.target.value}, () => {
            this.applyFilters();
        })
    }

    applyFilters(){
        //let products_filtered = this.state.products;
        let clients_filtered = this._filterSearchText();
        this.setState({clients_filtered})
    }

    _filterSearchText(){
        let clientss_filtered = [];
        this.state.clients.forEach((client) => {
            let entries = Object.entries(client);
            for (let i = 0; i < entries.length; i++) {
                let value = entries[i][1] ? entries[i][1].toString() : '';
                if(value.toLocaleLowerCase().includes(this.state.searchText.toLocaleLowerCase())){
                    clientss_filtered.push(client)
                    break;
                }
            }
        })
        return clientss_filtered;
    }


    render(){

        const columns = [
            {
                dataField: 'client_name',
                text: 'Cliente',
                sort: true
            },{
                dataField: 'business_name',
                text: 'Razón Social',
                sort: true
            },{
                dataField: 'nit',
                text: 'NIT',
            },{
                dataField: 'email',
                text: 'Correo',
            },{
                dataField: 'mobile1',
                text: 'Celular',
            },{
                dataField: 'actions',
                text: 'Acciones',
            }
        ];

        const rowStyle2 = (row, rowIndex) => {
            const style = {};
            if (rowIndex % 2 === 0) {
                style.backgroundColor = '#FFFBCE';
                style.color = '#000000';
                style.height = '45px';
            } else {
                style.backgroundColor = 'white';
                style.height = '45px';
            }

            style.cursor = 'pointer';

            return style;
        };

        return(
            <React.Fragment>
                {this.redirectToLogin()}
                <LoadingComponent loadingImage={Logo} loadingText={'Quitando acceso a cliente.'} loadingState={this.state.loadingRevokeLogin}/>
                <LoadingComponent loadingImage={Logo} loadingText={'Activando acceso a cliente.'} loadingState={this.state.loadingActivateLogin}/>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.editCreateModalOpen}
                    onClose={this.handleEditCreateModalClose.bind(this)}
                    className='flomecolModalContainer'
                >
                    <div className='flomecolModalContent'>

                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                {
                                    this.state.modalType === 'create' ?
                                        <div className='modalTitle'><h2>NUEVO CLIENTE</h2></div>
                                        :
                                        <div className='modalTitle'><h2>EDITAR CLIENTE</h2></div>
                                }
                            </Grid>

                            <Grid item xs={12} md={12} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Cliente"
                                    className='inputTextField'
                                    name='client_name'
                                    value={this.state.client_name}
                                    onChange={this.handleChange.bind(this)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={8} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Razón social"
                                    className='inputTextField'
                                    name='business_name'
                                    value={this.state.business_name}
                                    onChange={this.handleChange.bind(this)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="NIT"
                                    type="number"
                                    className='inputTextField'
                                    name='nit'
                                    value={this.state.nit}
                                    onChange={this.handleChange.bind(this)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Celular 1"
                                    type="number"
                                    className='inputTextField'
                                    name='mobile1'
                                    value={this.state.mobile1}
                                    onChange={this.handleChange.bind(this)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Celular 2"
                                    type="number"
                                    className='inputTextField'
                                    name='mobile2'
                                    value={this.state.mobile2}
                                    onChange={this.handleChange.bind(this)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Correo"
                                    className='inputTextField'
                                    name='email'
                                    value={this.state.email}
                                    onChange={this.handleChange.bind(this)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Descuento directo (%)"
                                    className='inputTextField'
                                    name='direct_discount'
                                    type='number'
                                    value={this.state.direct_discount}
                                    onChange={this.handleChange.bind(this)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Ciudad"
                                    className='inputTextField'
                                    name='city'
                                    value={this.state.city}
                                    onChange={this.handleChange.bind(this)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Departamento"
                                    className='inputTextField'
                                    name='department'
                                    value={this.state.department}
                                    onChange={this.handleChange.bind(this)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Barrio"
                                    className='inputTextField'
                                    name='neighborhood'
                                    value={this.state.neighborhood}
                                    onChange={this.handleChange.bind(this)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Dirección"
                                    className='inputTextField'
                                    name='address'
                                    value={this.state.address}
                                    onChange={this.handleChange.bind(this)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                <CssTextField
                                    id="outlined-balance"
                                    label="Cupo ($)"
                                    className='inputTextField'
                                    name='balance'
                                    value={this.state.balance}
                                    onChange={ (e) => {
                                        this.setState({
                                            balance: e.target.value
                                        })
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        inputComponent: NumberFormatCustom,
                                        endAdornment: <InputAdornment position="end">COP</InputAdornment>,
                                    }}
                                />
                            </Grid>

                            {
                                this.state.modalType === 'edit' && this.state.canSelectSeller ?
                                    <Grid item xs={12} className='inputTextFieldContainer'>
                                        <strong className='create-requested-order-product-select'>SELECCIONE UN VENDEDOR</strong>
                                        <Select
                                            value={this.state.selectedSeller}
                                            onChange={this.handleSelectedSellerChange}
                                            options={this.state.sellers}
                                            disabled={true}
                                        />
                                    </Grid>
                                    :
                                    ''
                            }

                            {
                                this.state.modalType === 'create' ?
                                    <Grid item xs={12} className='inputTextFieldContainer'>
                                        <Button variant="contained" color="primary" onClick={this.doCreateClient.bind(this)}>
                                            <AddCircleIcon/> &nbsp; CREAR NUEVO CLIENTE
                                        </Button>
                                    </Grid>
                                    :
                                    <Grid item xs={12} className='inputTextFieldContainer'>
                                        <Button variant="contained" color="primary" onClick={this.doEditClient.bind(this)}>
                                            <CreateIcon/> &nbsp; EDITAR CLIENTE
                                        </Button>
                                    </Grid>
                            }


                        </Grid>

                    </div>
                </Modal>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.activateLoginOpen}
                    onClose={this.handleActivateLoginModalClose.bind(this)}
                    className='flomecolModalContainer'
                >
                    <div className='flomecolModalContent'>

                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div className='modalTitle'><h2>ACTIVAR LOGIN CLIENTE</h2></div>
                            </Grid>

                            <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Contraseña"
                                    className='inputTextField'
                                    name='clientPassword'
                                    value={this.state.clientPassword}
                                    onChange={this.handleChange.bind(this)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                                <Button variant="contained" color="primary" onClick={this.doActivateLogin.bind(this)} className='inputTextFieldContainerFull'>
                                    <PersonAddIcon/> &nbsp; ACTIVAR LOGIN
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Modal>

                <Grid container spacing={0} className='ModuleMainContainer'>

                    {
                        this.state.canEdit && <React.Fragment>
                            <Button variant="contained" color="primary" onClick={this.handleEditCreateModalOpen.bind(this)}>
                                <AddCircleIcon/> &nbsp; CREAR NUEVO CLIENTE
                            </Button>
                        </React.Fragment>
                    }

                    <Grid item xs={12}>
                        <div className="clients-search-box">
                            <InputBase
                                placeholder="BUSCAR CLIENTE. . . "
                                classes={{
                                    root: "clients-search-input-root",
                                    input: "clients-search-input-input",
                                }}
                                value={this.state.searchText}
                                onChange={this.searchText.bind(this)}
                            />
                        </div>
                    </Grid>

                    

                    <Grid container spacing={0} className='tables-container'>
                        <Grid item xs={12}>
                            <h3 className='table-title'>LISTADO DE CLIENTES</h3>
                        </Grid>
                        <Grid item xs={12}>
                            <BootstrapTable
                                keyField='id'
                                data={ this.state.clients_filtered }
                                columns={ columns }
                                rowStyle={ rowStyle2 }
                                classes='bootstrap-table'
                                wrapperClasses='bootstrap-table-wrapper'
                                headerClasses='bootstrap-table-header'
                                pagination={ paginationFactory()}
                            />
                        </Grid>
                    </Grid>


                </Grid>
            </React.Fragment>
        )
    }
}

export default ClientsComponent;