import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import axiosClient from "../../services/apiClient";
import { API_URL, READ_PRODUCTS, CREATE_PRODUCTION_CONTROL, GET_ALL_PRODUCTION_CONTROL, SEARCH_PRODUCTION_CONTROL } from "../../API";
import moment from 'moment'
import Button from "@material-ui/core/Button";
import PrintIcon from '@material-ui/icons/Print';
import { formatDate } from "../../utils/dates";

const ProductionControlController = () => {

    const history = useHistory();

    const [ componentPermissions, setComponentPermissions ] = useState();
    const [ searchText, setSearchText ] = useState('');
    const [ isEditCreateModalOpen, setIsEditCreateModalOpen ] = useState(false);
    const [ QRInfo, setQRInfo ] = useState({ time: moment().format('LT') });
    const [ selectedProduct, setSelectedProduct ] = useState();
    const [ loadedProducts, setLoadedProducts ] = useState([]);
    const [ creatingProductionControl, setCreatingProductionControl ] = useState(false)
    const [ openProductionControlCreatedSuccess, setOpenProductionControlCreatedSuccess ] = useState(false)
    const [ dialogMessage, setDialogMessage ] = useState('')
    const [ gettingProductionControl, setGettingProductionControl ] = useState(false)
    const [ productionControlList, setProductionControlList ] = useState([])
    const [ paginationData, setPaginationData ] = useState({})
    const [ isPrintAreaModalOpen, setIsPrintAreaModalOpen ] = useState(false); 
    const [ options, setOptions ] = useState({
        hideSizePerPage: true,
        page: 1,
        totalSize: 1,
        sizePerPage: 10,
        paginationSize: 1,
    })
    
    const columns = [
        {
            dataField: 'id',
            text: 'ID'
        },{
            dataField: 'product_name',
            text: 'Producto'
        },{
            dataField: 'batch_number',
            text: 'Lote'
        },{
            dataField: 'due_date',
            text: 'Fecha de vencimiento',
        },{
            dataField: 'packaging',
            text: 'Embalaje',
        },{
            dataField: 'responsable',
            text: 'Responsable',
        },{
            dataField: 'delivery_date',
            text: 'Fecha de entrega',
        },{
            dataField: 'time',
            text: 'Hora',
        },{
            dataField: 'boxes',
            text: 'Número de cajas',
        },{
            dataField: 'actions',
            text: 'Acciones',
        }
    ];

    const rowStyle = (row, rowIndex) => {
        const style = {};
        if (rowIndex % 2 === 0) {
            style.backgroundColor = '#FFFBCE';
            style.color = '#000000';
            style.height = '45px';
        } else {
            style.backgroundColor = 'white';
            style.height = '45px';
        }

        style.cursor = 'pointer';

        return style;
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            padding: 20,
            cursor: 'pointer',
            color: state.isFocused ? '#336636' : 'black',
            backgroundColor: state.isFocused ? '#FFFBCE' : 'white',
        }),
        menu: () => ({
            // none of react-select's styles are passed to <Control />
            backgroundColor: 'white',
            position: 'absolute',
            zIndex: '1000',
            width: '100%',
        }),
        input: () => ({
            // none of react-select's styles are passed to <Control />
            height: '52px',
        }),
    };

    useEffect(() => {
        handlePermissions()
        getProducts()
        getAllProductionControl()
    }, [])

    const updatePagintationData = response => {
        setPaginationData(response.data);
        setOptions({
            ...options, ...{
                page: response.data.current_page,
                paginationSize: response.data.last_page < 10 ? response.data.last_page : 10,
                totalSize: response.data.total
            }
        });
    }

    const handlePrintClick = (productionControl) => {
        setQRInfo({
            productName: productionControl.product_name,
            batchNumber: productionControl.batch_number,
            dueDate: productionControl.due_date,
            packaging: productionControl.packaging,
            responsable: productionControl.responsable,
            deliveryDate: productionControl.delivery_date,
            time: productionControl.time,
            boxes: productionControl.boxes
        })
        setIsPrintAreaModalOpen(true)
    }

    const handleProductionControlResponse = response => {

        updatePagintationData(response);
        
        let productionControlList_ = [];

        response.data.data.forEach((productionControl) => {

            let actions = <Fragment>
                {
                    <Button
                        className='coin-button-table-disable'
                        onClick={() => handlePrintClick(productionControl)}
                    >
                        <PrintIcon/>
                    </Button>    
                }
            </Fragment>

            let newProductionControl = productionControl;
            newProductionControl.due_date = formatDate(new Date(productionControl.due_date));
            newProductionControl.delivery_date = formatDate(new Date(productionControl.delivery_date));
            newProductionControl.actions = actions;            
            productionControlList_.push(newProductionControl);
        })

        setProductionControlList(productionControlList_)
    }

    const getAllProductionControl = () => {
        setGettingProductionControl(true)
        axiosClient.get(GET_ALL_PRODUCTION_CONTROL).then((response) => {
            setGettingProductionControl(false)
            handleProductionControlResponse(response)
        }).catch((error) => {
            console.error(error)
            setGettingProductionControl(false)
            if(error.response && error.response.data && error.response.data.message){
                alert(error.response.data.message)
            }else{
                alert("ERROR: error de servidor cargando listado de control de produccion")
            }
        })
    }

    const filterProductionControl = () => {
        if(searchText === ''){
            getAllProductionControl();
        } else {
            setGettingProductionControl(true)
            axiosClient.get(`${SEARCH_PRODUCTION_CONTROL}/${searchText}`).then((response) => {
                setGettingProductionControl(false)
                handleProductionControlResponse(response)
            }).catch((error) => {
                setGettingProductionControl(false)
                if (error.response && error.response.data && error.response.data.message) {
                    alert(error.response.data.message)
                } else {
                    alert("ERROR: error de servidor cargando listado de control de produccion")
                }
            })
        }
    }

    const getProducts = () => {
      axiosClient.get(API_URL + READ_PRODUCTS).then((response) => {

            response.data.forEach(function(product) {
                product.label = product.product_name + ' ' + product.product_presentation;
            });

            let products = response.data.filter((product) => product.status === 'active')

            setLoadedProducts(products)

        }).catch((error) => {
            if(error.response && error.response.data && error.response.data.message){
                alert(error.response.data.message)
            }else{
                alert("ERROR: error de servidor cargando listado de productos")
            }
        })
    }

    const handlePermissions = () => {

        let permissions = {}

        if(localStorage.getItem('data')){
            let data = JSON.parse(localStorage.getItem('data'));

            switch (data.rol) {
                case 'administracion':
                    permissions = {
                        canView: true,
                        canEdit: true,
                    }
                    break;
                case 'ventas':
                    permissions = {
                        canView: true,
                        canEdit: true,
                    }
                    break;
                case 'facturacion':
                    permissions = {
                        canView: true,
                        canEdit: true,
                    }
                    break;
                case 'control':
                    permissions = {
                        canView: true,
                        canEdit: true,
                    }
                    break;
                default:
                    permissions = {
                        canView: false,
                        canEdit: false,
                    }
                    break;
            }

            setComponentPermissions(permissions)

        }else{
            localStorage.clear();
            history.push("/");
        }
    }

    const handleSelectedProductChange = product => {
        setSelectedProduct(product)
        setQRInfo({ ...QRInfo, ...{ productName: product.label }})
    }

    const validateFields = () => {
        return validateProductName() && 
        validateBatchNumber() && 
        validateDueDate() &&
        validatePackaging() && 
        validateResponsable() &&
        validateDeliveryDate() && 
        validateTime() &&
        validateBoxes();
    }

    const validateProductName = () => {
        if(!QRInfo.productName || QRInfo.productName === ''){
            alert("Debes ingresar el nombre del producto.")
            return false;
        }else{
            return true;
        }
    }

    const validateBatchNumber = () => {
        if(!QRInfo.batchNumber || QRInfo.batchNumber === ''){
            alert("Debes ingresar el lote.")
            return false;
        }else{
            return true;
        }
    }

    const validateDueDate = () => {
        if(!QRInfo.dueDate || QRInfo.dueDate === ''){
            alert("Debes ingresar la fecha de vencimiento.")
            return false;
        }else{
            return true;
        }
    }

    const validatePackaging = () => {
        if(!QRInfo.packaging || QRInfo.packaging === ''){
            alert("Debes ingresar el embalaje.")
            return false;
        }else{
            return true;
        }
    }

    const validateResponsable = () => {
        if(!QRInfo.responsable || QRInfo.responsable === ''){
            alert("Debes ingresar el responsable.")
            return false;
        }else{
            return true;
        }
    }

    const validateDeliveryDate = () => {
        if(!QRInfo.deliveryDate || QRInfo.deliveryDate === ''){
            alert("Debes ingresar la fecha de entrega.")
            return false;
        }else{
            return true;
        }
    }

    const validateTime = () => {
        if(!QRInfo.time || QRInfo.time === ''){
            alert("Debes ingresar la hora.")
            return false;
        }else{
            return true;
        }
    }

    const validateBoxes = () => {
        if(!QRInfo.boxes || QRInfo.boxes === ''){
            alert("Debes ingresar el numero de cajas.")
            return false;
        }else{
            return true;
        }
    }

    const doCreateProductionControl = () => {
        if(validateFields()){
            
            setCreatingProductionControl(true);

            let formData = {
                product_name: QRInfo.productName,
                batch_number: QRInfo.batchNumber,
                due_date: QRInfo.dueDate,
                packaging: QRInfo.packaging,
                responsable: QRInfo.responsable,
                delivery_date: QRInfo.deliveryDate,
                time: QRInfo.time,
                boxes: QRInfo.boxes,
                created_by: JSON.parse(localStorage.getItem('data')).id,
            }

            const error_message = "ERROR: error de servidor creando control de producción.";
            
            axiosClient.post(CREATE_PRODUCTION_CONTROL, formData).then((response) =>{
                setCreatingProductionControl(false);
                if(response.data){
                    if(response.data.success){
                        const productionControl = response.data.production_control
                        setQRInfo({});
                        setIsEditCreateModalOpen(false);
                        setOpenProductionControlCreatedSuccess(true);
                        getAllProductionControl();
                        setDialogMessage(<div>CONTROL DE PRODUCCION CREADO CORRECTAMENTE: <br/> <strong># {productionControl.id}</strong></div>);
                    }else{
                        alert(error_message)
                    }
                }else{
                    alert(error_message)
                }
            }).catch((error) => {
                console.error(error)
                setCreatingProductionControl(false);
                alert(error_message)
                if(error.response && error.response.data && error.response.data.message){
                    console.error(error.response.data.message)
                }
            })
        }
    }

    const onTableChange = (type, newState) => {
        setGettingProductionControl(true)
        axiosClient.get(`${paginationData.path}?page=${newState.page}`).then((response) => {
            handleProductionControlResponse(response)
            setGettingProductionControl(false)
        }).catch((error) => {
            setGettingProductionControl(false)
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message)
            } else {
                alert("ERROR: error de servidor cargando listado de control de produccion")
            }
        })
    }

    useEffect(() => {
        filterProductionControl()
    }, [searchText])

    const handleOpenEditCreateModalOpen = () => {
        setIsEditCreateModalOpen(true)
        setQRInfo({ time: moment().format('LT') })
    }

    return {
        componentPermissions,
        searchText,
        setSearchText,
        isEditCreateModalOpen, 
        setIsEditCreateModalOpen,
        columns,
        rowStyle,
        QRInfo,
        setQRInfo,
        doCreateProductionControl,
        customStyles,
        selectedProduct,
        handleSelectedProductChange,
        loadedProducts,
        isPrintAreaModalOpen,
        setIsPrintAreaModalOpen,
        openProductionControlCreatedSuccess, 
        setOpenProductionControlCreatedSuccess,
        dialogMessage, 
        creatingProductionControl,
        productionControlList,
        onTableChange,
        options,
        handleOpenEditCreateModalOpen,
        gettingProductionControl
    }
}

export default ProductionControlController;