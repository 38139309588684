import React from 'react';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import DatePicker from 'react-date-picker';
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import SpeakerNotesOff from "@material-ui/icons/SpeakerNotesOff";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axiosClient from "../../services/apiClient";
import Select from 'react-select';
import DialogComponent from "../DialogComponent/DialogComponent";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import NumberFormat from "react-number-format";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import Send from "@material-ui/icons/Send";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from '@material-ui/core/styles';
import PdfViewerComponent from "../PdfViewerComponent/PdfViewerComponent";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import Logo from '../../images/Logo_flomecol.svg';
import {
    API_URL,
    CREATE_REQUEST_ORDER,
    GET_REQUESTED_ORDERS,
    GET_REQUESTED_ORDERS_PDF,
    SEARCH_REQUESTED_ORDERS,
    READ_CLIENTS,
    READ_CLIENTS_BY_SELLER_NAME,
    READ_PRODUCTS,
    UPDATE_REQUEST_ORDER,
    UPDATE_REQUEST_ORDER_STATUS,
    GET_ALL_CITIES
} from "../../API";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { message_responses } from "../../MESSAGE_RESPONSES";
import { ALERT_REQUESTED_ORDERS_DAYS } from "../../SETTINGS";
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from "@material-ui/core/InputAdornment";
import InputBase from "@material-ui/core/InputBase";

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#336636',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#336636',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#336636',
                borderWidth: '2px',
            },
            '&:hover fieldset': {
                borderColor: '#336636',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#336636',
            },
        },
    },
})(TextField);

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#336636',
    },
    tabsRoot: {
        borderBottom: '1px solid #1d1d1d',
    },
    tabsIndicator: {
        backgroundColor: '#ffffff',
        height: "4px",
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        fontFamily: "\"Montserrat\", sans-serif !important",
        fontSize: "18px",
        '&:hover': {
            color: '#ffffff',
            opacity: 5,
        },
        '&:focus': {
            color: '#ffffff',
        },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing.unit * 3,
    },
    button: {
        margin: theme.spacing.unit,
    },
    input: {
        display: 'none',
    },
});

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalScale={0}
            thousandSeparator={","}
            decimalSeparator={"."}
            fixedDecimalScale={true}
            prefix="$ "
        />
    );
}

const ordersTypes = [
    'SIN_DESPACHAR',
    'CON_PENDIENTES',
    'DESPACHADA',
    'ANULADA'
]

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'COP',
});

class RequestedOrdersComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editCreateModalOpen: false,
            selectedOption: {
                direct_discount: 0
            },
            selectedProduct: {},
            order_date: new Date(),
            shipping_date: new Date(),
            client_name: '',
            business_name: '',
            nit: '',
            department: '',
            city: '',
            neighborhood: '',
            address: '',
            mobile1: '',
            mobile2: '',
            email: '',
            client: '',
            seller: '',
            order_total_cost: 0,
            general_observations: '',
            direct_discount: 0,
            discount_prompt_payment: 0,
            invoice_term: '',
            payment_date: '',
            payment_date_value: '',
            total_discount: 0,
            openOrderCreatedSuccess: false,
            messageOrderCreatedSuccess: '',

            clients: [],
            products: [],
            sellers: [],

            orders: [],

            loadedProducts: [],

            canView: false,
            canCreate: false,
            canDispatch: false,
            canRevoke: false,


            invoiceTerms: [
                { value: 'Contado', label: 'Contado' },
                { value: '5 días', label: '5 días' },
                { value: '10 días', label: '10 días' },
                { value: '15 días', label: '15 días' },
                { value: '30 días', label: '30 días' },
            ],
            transporters: [
                { value: 'Servientrega', label: 'Servientrega' },
                { value: 'Envia', label: 'Envia' },
                { value: 'Surenvios', label: 'Surenvios' },
                { value: 'Encoexpress', label: 'Encoexpress' },
                { value: 'Domicilios Bogota', label: 'Domicilios Bogota' },
                { value: 'Terminal', label: 'Terminal' },
            ],
            value: 0,

            sendOrderModalOpen: false,
            selectedSendOrder: {
                products: [],
                order_date: new Date(),
            },

            real_shipping_date: new Date(),

            guide_number: '',
            transporter: {
                value: '',
                label: '',
            },

            pdfViewer: false,
            pdfURL: '',
            shipping_observations: '',

            loadingCreatingOrder: false,
            dispatchingOrder: false,

            cancellation_reason: '',
            cancellation_reason_modal: false,
            requested_order_to_cancel: {},
            cancellingOrder: false,

            cities: [],
            dispatchCity: '',

            invoice_number: '',
            real_amount: '',

            searchText: '',

            options: {
                hideSizePerPage: true,
                page: 1,
                totalSize: 1,
                sizePerPage: 10,
                paginationSize: 1,
            },

            paginationData: {},

            loadingData: false,
        }
    }

    onTableChange(type, newState){
        this.setState({loadingData:true})
        axiosClient.get(`${this.state.paginationData.path}?page=${newState.page}`).then((response) => {
            this.formatOrders(response)
            this.setState({loadingData:false})
        }).catch((error) => {
            this.setState({loadingData:false})
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message)
            } else {
                alert("ERROR: error de servidor cargando listado de ordenes de pedido.")
            }
        })
    }

    componentDidMount() {
        this._handlePermissions();
        this._updateClients();
        this._updateProducts();
        this._updateSellers();
        this._updateOrders();
        this._updateTransporters();
        this._updateCities();
    }

    _handlePermissions() {

        if (localStorage.getItem('data')) {
            let data = JSON.parse(localStorage.getItem('data'));

            switch (data.rol) {
                case 'administracion':
                    this.setState({
                        canView: true,
                        canCreate: true,
                        canDispatch: true,
                        canRevoke: true,
                    })
                    break;
                case 'ventas':
                    this.setState({
                        canView: true,
                        canCreate: true,
                        canDispatch: false,
                    })
                    break;
                case 'facturacion':
                    this.setState({
                        canView: true,
                        canCreate: false,
                        canDispatch: true,
                    })
                    break;
                case 'control':
                    this.setState({
                        canView: true,
                        canCreate: false,
                        canDispatch: false,
                    })
                    break;
                default:
                    break;
            }
        } else {
            this.setState({ redirectToLogin: true })
        }
    }

    _updateClients() {
        if (localStorage.getItem('data')) {
            let data = JSON.parse(localStorage.getItem('data'));
            if (data.rol === 'administracion') {
                axiosClient.get(API_URL + READ_CLIENTS).then((response) => {
                    response.data.forEach(function (client) {
                        client.label = client.client_name + ', ' + client.business_name + ', ' + client.nit;
                    });

                    this.setState({
                        clients: response.data
                    })
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.message) {
                        alert(error.response.data.message)
                    } else {
                        alert("ERROR: error de servidor cargando listado de clientes")
                    }
                })
            } else {

                let formData = {
                    seller_name: data.name
                }

                axiosClient.post(API_URL + READ_CLIENTS_BY_SELLER_NAME, formData).then((response) => {
                    response.data.forEach(function (client) {
                        client.label = client.client_name + ', ' + client.business_name + ', ' + client.nit;
                    });

                    this.setState({
                        clients: response.data
                    })
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.message) {
                        alert(error.response.data.message)
                    } else {
                        alert("ERROR: error de servidor cargando listado de clientes")
                    }
                })
            }
        }
    }

    _updateProducts() {

      axiosClient.get(API_URL + READ_PRODUCTS).then((response) => {
        response.data.forEach(function (product) {
            product.label = product.product_name + ' ' + product.product_presentation + ' - $' + product.product_price;
        });

        let products = response.data.filter((product) => product.status === 'active')

        this.setState({
            loadedProducts: products
        })
      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
            alert(error.response.data.message)
        } else {
            alert("ERROR: error de servidor cargando listado de productos")
        }
      })
    }

    _updateSellers() {

        if (localStorage.getItem('data')) {
            let data = JSON.parse(localStorage.getItem('data'));

            let seller = [{
                label: data.name,
                name: data.name,
            }];

            this.setState({
                sellers: seller
            })
        }
    }

    updatePagintationData(response){
        this.setState({
            paginationData: response.data,
            options: {
                ...this.state.options, ...{
                    page: response.data.current_page,
                    paginationSize: response.data.last_page < 10 ? response.data.last_page : 10,
                    totalSize: response.data.total
                }
            }
        })
    }

    filterOrders(){
        if(this.state.searchText === ''){
            this._updateOrders();
        }else if (localStorage.getItem('data')) {
            this.setState({loadingData:true})
            const data = JSON.parse(localStorage.getItem('data'));
            const id = data.rol === 'ventas' ? '/' + data.id : '';
            axiosClient.get(`${API_URL}${SEARCH_REQUESTED_ORDERS}/${ordersTypes[this.state.value]}/${this.state.searchText}${id}`).then((response) => {
                this.formatOrders(response)
                this.setState({loadingData:false})
            }).catch((error) => {
                this.setState({loadingData:false})
                if (error.response && error.response.data && error.response.data.message) {
                    alert(error.response.data.message)
                } else {
                    alert("ERROR: error de servidor cargando listado de ordenes de pedido.")
                }
            })
        }
    }

    _updateOrders() {
        if (localStorage.getItem('data')) {
            this.setState({loadingData:true})
            const data = JSON.parse(localStorage.getItem('data'));
            const id = data.rol === 'ventas' ? '/' + data.id : '';
            axiosClient.get(`${API_URL}${GET_REQUESTED_ORDERS}/${ordersTypes[this.state.value]}${id}`).then((response) => {
                this.formatOrders(response)
                this.setState({loadingData:false})
            }).catch((error) => {
                this.setState({loadingData:false})
                if (error.response && error.response.data && error.response.data.message) {
                    alert(error.response.data.message)
                } else {
                    alert("ERROR: error de servidor cargando listado de ordenes de pedido.")
                }
            })
        }
    }

    formatOrders(response){
        this.updatePagintationData(response)
        let orders = [];
        response.data.data.forEach((requestedOrder) => {
            requestedOrder.order_date = new Date(requestedOrder.order_date);
            requestedOrder.order_date_to_show = this.formatDate(new Date(requestedOrder.order_date));

            requestedOrder.tentative_shipping_date = new Date(requestedOrder.tentative_shipping_date);
            requestedOrder.tentative_shipping_date_to_show = this.formatDate(new Date(requestedOrder.tentative_shipping_date));

            requestedOrder.total_price_with_discount_text = requestedOrder.total_price_with_discount;
            requestedOrder.total_price_with_discount = <NumberFormat
                value={requestedOrder.total_price_with_discount}
                displayType={'text'}
                prefix={'$'}
                decimalScale={0}
                thousandSeparator={","}
                decimalSeparator={"."}
                fixedDecimalScale={true}
            />;

            let actions = <React.Fragment>
                {
                    this.state.canView ?
                        <a
                            href={API_URL + GET_REQUESTED_ORDERS_PDF + requestedOrder.id}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='download-order-pdf-eye'
                        >
                            <Button
                                className='coin-button-table-disable'
                            >
                                <RemoveRedEye />
                            </Button>

                        </a>
                        :
                        ''
                }

                {
                    this.state.canDispatch && this.state.value === 0 || this.state.value === 1 ?
                        <Button
                            className='coin-button-table-disable'
                            onClick={this.sendRequestedOrder.bind(this, requestedOrder)}
                        >
                            <Send />
                        </Button>
                        :
                        ''
                }

                {
                    this.state.canRevoke && this.state.value === 0 || this.state.value === 1 ?
                        <Button
                            className='coin-button-table-disable'
                            onClick={this.handleCancellationReasonModalOpen.bind(this, requestedOrder)}
                        >
                            <SpeakerNotesOff />
                        </Button>
                        :
                        ''
                }

            </React.Fragment>
            requestedOrder.actions = actions;
            orders.push(requestedOrder);
        })

        this.setState({
            orders
        })
    }

    _updateCities() {
      axiosClient.get(API_URL + GET_ALL_CITIES).then((response) => {
            this.setState({
                cities: response.data
            })
        }).catch((error) => {
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message)
            } else {
                alert("ERROR: error de servidor cargando listado de ciudades.")
            }
        })
    }

    _updateTransporters() {

    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    showRequestedOrder(requestedOrder) {
        //alert("EN CONSTRUCCION: " + requestedOrder.id)

        axiosClient.get(API_URL + GET_REQUESTED_ORDERS_PDF + requestedOrder.id).then((response) => {
            this.setState({
                pdfURL: response.data,
                pdfViewer: true
            })
        })
    }

    closePdfViewer() {
        this.setState({ pdfViewer: false })
    }

    sendRequestedOrder(requestedOrder) {
        this.setState({
            selectedSendOrder: requestedOrder,
            sendOrderModalOpen: true
        })
    }

    revokeRequestedOrder() {
        let requestedOrder = this.state.requested_order_to_cancel;
        if (window.confirm('Está seguro que desea anular la orden de pedido #' + requestedOrder.id + '?')) {
            this.setState({ cancellingOrder: true });
            let formData = {
                status: 'ANULADA',
                cancellation_reason: this.state.cancellation_reason,
            }
            axiosClient.put(API_URL + UPDATE_REQUEST_ORDER_STATUS + requestedOrder.id, formData).then((response) => {
                this.setState({ cancellingOrder: false })
                if (response.data.success) {
                    this.setState({
                        cancellation_reason: '',
                        cancellation_reason_modal: false,
                    })
                    alert(message_responses[response.data.message]);
                    this._updateOrders();
                } else {
                    alert(message_responses[response.data.message]);
                }
            }).catch((error) => {
                this.setState({ cancellingOrder: false })
                if (error.response && error.response.data) {
                    alert(error.response.data.message === 'VALIDATOR_ERROR' ? error.response.data.data.error_message : message_responses[error.response.data.message])
                } else {
                    alert("ERROR DE SERVIDOR: Hemos tenido un error anulando esta orden, si el problema continua por favor contactar al área encargada.")
                }
            })
        }
    }

    formatDate(date) {
        if (date) {
            let monthNames = [
                "Enero", "Febrero", "Marzo",
                "Abril", "Mayo", "Junio", "Julio",
                "Agosto", "Septiembre", "Octubre",
                "Noviembre", "Diciembre"
            ];
            let day = date.getDate();
            let monthIndex = date.getMonth();
            let year = date.getFullYear();
            return day + ' ' + monthNames[monthIndex] + ' ' + year;
        } else {
            return '';
        }

    }

    handleEditCreateModalClose() {
        this.setState({
            editCreateModalOpen: false
        })
    }

    handleCancellationReasonModalClose() {
        this.setState({
            cancellation_reason_modal: false
        })
    }

    handleCancellationReasonModalOpen(requestedOrder) {
        this.setState({
            requested_order_to_cancel: requestedOrder,
            cancellation_reason_modal: true
        })
    }

    handleEditCreateModalOpen() {
        this.setState({
            editCreateModalOpen: true
        })
    }

    handleChange = event => {

        let name = event.target.name;
        if (name === 'discount_prompt_payment') {
            this.setState({ [event.target.name]: event.target.value }, () => {
                this.calculateTotalCost();
            })
        } else {
            this.setState({ [event.target.name]: event.target.value })
        }
    };

    handleSelectChange = selectedOption => {
        this.setState({ selectedOption }, () => {
            this.calculateTotalCost();
        });
    };

    handleSellerSelectChange = seller => {
        this.setState({ seller });
    };

    handleTransporterSelectChange = transporter => {
        this.setState({ transporter: transporter })
    }

    handleTermSelectChange = invoice_term => {
        this.setState({ invoice_term }, () => {
            let value = this.state.invoice_term.label;
            switch (value) {
                case 'Contado':
                    value = 0;
                    break;
                case '5 días':
                    value = 5;
                    break;
                case '10 días':
                    value = 10;
                    break;
                case '15 días':
                    value = 15;
                    break;
                case '30 días':
                    value = 30;
                    break;
                default:
                    value = 0;
                    break;
            }

            let add = value < 0 ? 0 : parseInt(value);
            let date = new Date(this.state.order_date);
            date.setDate(date.getDate() + add);

            this.setState({
                payment_date: date,
                payment_date_value: date,
            })

        });
    };

    handleSelectedProductChange = selectedProduct => {
        this.setState({ selectedProduct });
    }

    calculateTotalCost() {
        let order_total_cost = 0;
        this.state.products.forEach((product) => { order_total_cost += product.total_price })
        let direct_discount = this.state.selectedOption.direct_discount ? parseInt(this.state.selectedOption.direct_discount) : 0;
        let discount_prompt_payment = this.state.discount_prompt_payment.length > 0 ? parseInt(this.state.discount_prompt_payment) : 0;
        let total_discount = direct_discount + discount_prompt_payment;
        order_total_cost -= (order_total_cost * total_discount / 100);
        this.setState({ order_total_cost })
    }

    handleProductListChange(event, field, id) {

        if (field === 'quantity') {
            let products = this.state.products;
            let index = products.findIndex((product) => product.id === id);
            products[index][field] = parseInt(event.target.value) < 0 ? 0 : parseInt(event.target.value);
            products[index]['total_price'] = parseInt(event.target.value) < 0 ? 0 : parseInt(event.target.value) * products[index]['product_price'];
            this.setState({ products }, () => {
                this.calculateTotalCost();
            })
        } else {
            let products = this.state.products;
            let index = products.findIndex((product) => product.id === id);
            products[index][field] = parseInt(event.target.value) < 0 ? 0 : parseInt(event.target.value);
            this.setState({ products })
        }
    }

    handleSendOrderProductListChange(event, field, id) {
        let temp_selected_order = this.state.selectedSendOrder;
        let temp_product = this.state.selectedSendOrder.products.find((product) => product.id === id);
        temp_product[field] = field === 'pending_quantity' ? parseInt(event.target.value) : event.target.value;
        let products = this.state.selectedSendOrder.products.filter((product) => product.id !== id);
        products.push(temp_product);
        temp_selected_order.products = products;
        temp_selected_order.products.sort((a, b) => (a.id > b.id) ? 1 : -1)
        this.setState({ selectedSendOrder: temp_selected_order })
    }

    handleDateChange = (date) => {
        this.setState({
            order_date: date === null ? new Date() : date,
        });
    }

    handleShippingDateChange = (date) => {
        this.setState({
            shipping_date: date === null ? new Date() : date,
        });
    }

    addProduct() {

        if (!(Object.keys(this.state.selectedProduct).length === 0 && this.state.selectedProduct.constructor === Object)) {

            let product = this.state.products.find((product) => product.id === this.state.selectedProduct.id);

            if (product === undefined) {
                let product_list = this.state.products;
                let new_product = {
                    id: this.state.selectedProduct.id,
                    product_name: this.state.selectedProduct.product_name,
                    product_presentation: this.state.selectedProduct.product_presentation,
                    product_price: this.state.selectedProduct.product_price,
                    quantity: '',
                    bonus_quantity: 0,
                    total_price: '',
                    /*pending_quantity: '',
                    product_batch: '',*/
                }


                product_list.push(new_product);
                this.setState({
                    products: product_list,
                    selectedProduct: {}
                })
            } else {
                alert("Este producto ya ha sido agregado a la lista.")
            }
        } else {
            alert("Debes selecionar un producto de la lista.")
        }
    }

    deleteProduct(id) {
        this.setState({
            products: this.state.products.filter((product) => product.id !== id)
        })
    }

    static fromDateToString(my_date) {
        if (my_date) {
            let date = my_date.getFullYear() + '-' + (my_date.getMonth() + 1) + '-' + my_date.getDate();
            let time = my_date.getHours() + ":" + my_date.getMinutes() + ":" + my_date.getSeconds();
            return date + ' ' + time;
        } else {
            return null;
        }
    }

    createOrder(){
        this.setState({ loadingCreatingOrder: true })
        let order_date = RequestedOrdersComponent.fromDateToString(this.state.order_date);
        let tentative_shipping_date = RequestedOrdersComponent.fromDateToString(this.state.shipping_date);
        let payment_date = RequestedOrdersComponent.fromDateToString(this.state.payment_date);
        let formData = {
            client_id: this.state.selectedOption.id,
            order_date,
            tentative_shipping_date,
            seller: this.state.seller.name,
            direct_discount: this.state.selectedOption.direct_discount,
            discount_prompt_payment: parseInt(this.state.discount_prompt_payment),
            invoice_term: this.state.invoice_term.value,
            payment_date,
            general_observations: this.state.general_observations,
            created_by: JSON.parse(localStorage.getItem('data')).id,
            products: this.state.products,
            total_discount: parseInt(this.state.discount_prompt_payment) + this.state.selectedOption.direct_discount,
            total_price: (parseInt(this.state.order_total_cost) * (parseInt(this.state.discount_prompt_payment) + parseInt(this.state.selectedOption.direct_discount)) / (100 - (parseInt(this.state.discount_prompt_payment) + parseInt(this.state.selectedOption.direct_discount)))) + (parseInt(this.state.order_total_cost)),
            total_price_with_discount: parseInt(this.state.order_total_cost),
            status: 'SIN_AUTORIZAR',
        }

        axiosClient.post(API_URL + CREATE_REQUEST_ORDER, formData).then((response) => {
            this.setState({ loadingCreatingOrder: false })
            if (response.data) {
                if (response.data.success) {
                    let order_id = response.data.data.order_id;

                    this.setState({
                        openOrderCreatedSuccess: true,
                        messageOrderCreatedSuccess: <div>ORDEN DE PEDIDO CREADA CORRECTAMENTE: <br /> <strong># {order_id}</strong></div>,
                        editCreateModalOpen: false,
                        selectedOption: {
                            direct_discount: 0
                        },
                        selectedProduct: {},
                        order_date: new Date(),
                        shipping_date: new Date(),
                        client_name: '',
                        business_name: '',
                        nit: '',
                        department: '',
                        city: '',
                        neighborhood: '',
                        address: '',
                        mobile1: '',
                        mobile2: '',
                        email: '',
                        client: '',
                        seller: '',
                        order_total_cost: 0,
                        general_observations: '',
                        direct_discount: 0,
                        discount_prompt_payment: 0,
                        invoice_term: '',
                        payment_date: '',
                        payment_date_value: '',
                        total_discount: 0,
                        products: [],
                        shipping_observations: '',
                    })
                    this._updateOrders();
                } else {
                    alert("ERROR")
                }
            } else {
                alert("ERROR")
            }
        }).catch((error) => {
            this.setState({ loadingCreatingOrder: false })
            alert(error)
        })
    }

    doCreateOrder() {
        if (this.validateFields()) {
            const requested_order_total_cost = (parseInt(this.state.order_total_cost) * (parseInt(this.state.discount_prompt_payment) + parseInt(this.state.selectedOption.direct_discount)) / (100 - (parseInt(this.state.discount_prompt_payment) + parseInt(this.state.selectedOption.direct_discount)))) + (parseInt(this.state.order_total_cost))
            const requested_order_client_balance = this.state.selectedOption.balance
            if(requested_order_client_balance < requested_order_total_cost){
                if(window.confirm(`El valor total de factura sin descuentos no debe ser mayor al cupo del cliente, el cupo actual del cliente ${this.state.selectedOption.client_name} es de ${formatter.format(requested_order_client_balance)}, mientras que el valor total de la factura es de ${formatter.format(requested_order_total_cost)}, la orden no podra ser despachada si el cliente no tiene el cupo suficiente para cubrir el valor de la factura. ¿Desea continuar?`)){
                    this.createOrder();
                }
            }else{
                this.createOrder();
            }
        }
    }

    validateDispatchFields() {
        return this.validateDispatchProducts() &&
            this.validateGuideNumber() &&
            this.validateTransporter() &&
            this.validateDispatchObservations() &&
            this.validateInvoiceNumber() &&
            this.validateRealAmount();
    }

    validateDispatchObservations() {
        if (this.state.shipping_observations === '') {
            return window.confirm('Esta seguro de despachar esta orden sin observaciones?');
        } else {
            return true;
        }
    }

    validateDispatchProducts() {
        let validation = true;
        this.state.selectedSendOrder.products.forEach((product) => {

            if (product.pending_quantity === '' || product.pending_quantity === null) {
                alert("Falta llenar el campo de cantidad pendiente del producto: " + product.product_name + ' ' + product.product_presentation)
                validation = false;
                return false;
            }

            if (product.pending_quantity > product.quantity) {
                alert("No puede tener mas de " + product.quantity + " unidades de " + product.product_name + ' ' + product.product_presentation + " en el campo de cantidad pendiente.")
                validation = false;
                return false;
            }

            /*if(product.product_batch === '' || product.product_batch === null ){
                alert("Falta llenar el campo de lote del producto: " + product.product_name + ' ' + product.product_presentation)
                validation = false;
                return false;
            }*/
        })

        return validation;

    }

    validateTransporter() {
        if (this.state.transporter.value === '') {
            alert("Falta llenar el campo de transportadora.")
            return false;
        } else {
            return true;
        }
    }

    validateGuideNumber() {
        if (this.state.guide_number === '') {
            alert("Falta llenar el campo de número de guia.")
            return false;
        } else {
            return true;
        }
    }

    orderHasPending(products) {
        let orderHasPending = false
        products.forEach((product) => {
            if (product.pending_quantity > 0) {
                //ordes has at least one pending products
                orderHasPending = true
            }
        })
        return orderHasPending
    }

    doDispatchOrder() {
        this.setState({ dispatchingOrder: true });
        if (this.validateDispatchFields()) {
            let id = this.state.selectedSendOrder.id;
            let formData = {
                guide_number: this.state.guide_number,
                transporter: this.state.transporter.value,
                real_shipping_date: RequestedOrdersComponent.fromDateToString(this.state.real_shipping_date),
                shipping_observations: this.state.shipping_observations,
                products: this.state.selectedSendOrder.products,
                status: this.orderHasPending(this.state.selectedSendOrder.products) ? "CON_PENDIENTES" : "DESPACHADA",
                invoice_number: this.state.invoice_number,
                real_amount: this.state.real_amount,
            }

            axiosClient.put(API_URL + UPDATE_REQUEST_ORDER + id, formData).then((response) => {
                this.setState({});
                alert("ORDEN DESPACHADA CORRECTAMENTE")
                this.setState({
                    sendOrderModalOpen: false,
                    dispatchingOrder: false,
                    guide_number: '',
                    transporter: {
                        value: '',
                        label: '',
                    },
                    real_shipping_date: new Date(),
                    shipping_observations: '',
                    invoice_number: '',
                    real_amount: '',
                })
                this._updateOrders();
            }).catch(() => {
                alert("ERROR DESPACHANDO ORDEN")
            })
        } else {
            this.setState({ dispatchingOrder: false });
        }
    }

    validateFields() {
        return this.validateClient() &&
            this.validateSeller() &&
            this.validateProducts() &&
            this.validateDirectDiscount() &&
            this.validateDiscountPromptPayment() &&
            this.validateCity() &&
            this.validateInvoiceTerm() &&
            this.validateCityLevel() &&
            this.validateGeneralObservations();
    }

    validateClient() {
        if (Object.keys(this.state.selectedOption).length === 1 && this.state.selectedOption.constructor) {
            alert("Debes seleccionar un cliente.")
            return false;
        } else {
            return true;
        }
    }

    validateSeller() {
        if (this.state.seller === '') {
            alert("Debes seleccionar un vendedor.")
            return false;
        } else {
            return true;
        }
    }

    validateProducts() {
        if (this.state.products.length === 0) {
            alert("Debes seleccionar al menos un producto.")
            return false;
        } else {

            let validation = true;

            this.state.products.forEach((product) => {

                if (product.bonus_quantity === '' || isNaN(product.bonus_quantity)) {
                    alert("Falta llenar el campo de cantidad de bono del producto: " + product.product_name + ' ' + product.product_presentation)
                    validation = false;
                    return false;
                }

                if (product.quantity === '') {
                    alert("Falta llenar el campo de cantidad del producto: " + product.product_name + ' ' + product.product_presentation)
                    validation = false;
                    return false;
                }



                /*if(product.pending_quantity === ''){
                    alert("Falta llenar el campo de cantidad pendiente del producto: " + product.product_name + ' ' + product.product_presentation)
                    validation = false;
                    return false;
                }

                if(product.product_batch === ''){
                    alert("Falta llenar el campo de lote del producto: " + product.product_name + ' ' + product.product_presentation)
                    validation = false;
                    return false;
                }*/
            })

            return validation;
        }
    }

    validateDirectDiscount() {
        if (this.state.direct_discount === '') {
            alert("Debes llenar un porcentaje de descuento directo.")
            return false;
        } else {
            return true;
        }
    }

    validateDiscountPromptPayment() {
        if (this.state.discount_prompt_payment === '') {
            alert("Debes llenar un porcentaje de descuento por pronto pago.")
            return false;
        } else {
            return true;
        }
    }

    validateCity() {
        if (this.state.dispatchCity === '' || this.state.dispatchCity === undefined || this.state.dispatchCity === null) {
            alert("Debes seleccionar la ciudad de destino.")
            return false;
        } else {
            return true;
        }
    }

    validateInvoiceTerm() {
        if (this.state.invoice_term === '') {
            alert("Debes llenar los dias de plazo para el pago.")
            return false;
        } else {
            return true;
        }
    }

    validateGeneralObservations() {
        if (this.state.general_observations === '') {
            return window.confirm('Esta seguro de enviar esta orden de pedido sin observaciones?');
        } else {
            return true;
        }
    }

    validateInvoiceNumber() {
        if (this.state.invoice_number === '') {
            alert("Debes llenar el número de factura.")
            return false;
        } else {
            return true;
        }
    }

    validateRealAmount() {
        if (this.state.real_amount === '' || Number.isNaN(this.state.real_amount)) {
            alert("Debes llenar el valor real de la factura.")
            return false;
        } else {
            return true;
        }
    }

    validateCityLevel() {
        let total_cost = this.state.order_total_cost;
        let level = this.state.dispatchCity.level;

        if (level === 0 && total_cost <= 35000) {
            alert("Para domicilios el monto mínimo con descuentos del pedido debe ser de $35.000.")
            return false;
        }

        if (level === 1 && total_cost <= 150000) {
            alert("Para ciudades principales el monto mínimo con descuentos del pedido debe ser de $150.000.")
            return false;
        }

        if (level === 2 && total_cost <= 400000) {
            alert("Para ciudades secundarias el monto mínimo con descuentos del pedido debe ser de $400.000.")
            return false;
        }

        if (level === 3 && total_cost <= 500000) {
            alert("Para ciudades terciarias el monto mínimo con descuentos del pedido debe ser de $500.000.")
            return false;
        }

        return true;
    }

    handleCloseOrderCreatedSuccess() {
        this.setState({ openOrderCreatedSuccess: false })
    }

    handleChangeValue = (event, value) => {
        this.setState({ value }, () => {
            this._updateOrders();
        });
    };

    handleSendModalModalClose() {
        this.setState({ sendOrderModalOpen: false })
    }

    setSelectedDispatchCity(value) {
        this.setState({ dispatchCity: value })
    }

    handleRealAmountChange(e) {
        let real_amount = parseFloat(e.target.value.substring(2).replace(/,/g, ''));
        if(this.state.selectedSendOrder.balance < real_amount){
            alert(`El valor real de la factura no puede ser mayor al cupo del cliente, el cupo actual del cliente ${this.state.selectedSendOrder.client_name} es de ${formatter.format(this.state.selectedSendOrder.balance) }`)
        }else{
            this.setState({ real_amount })
        }
    }
  
    render() {

        const { classes } = this.props;

        const columns = [
            {
                dataField: 'id',
                text: '#',
            }, {
                dataField: 'client_name',
                text: 'Cliente',
            }, {
                dataField: 'business_name',
                text: 'Razón Social',
            }, {
                dataField: 'seller',
                text: 'Vendedor',
            }, {
                dataField: 'order_date_to_show',
                text: 'Orden',
            }, {
                dataField: 'tentative_shipping_date_to_show',
                text: 'Despacho',
            }, {
                dataField: 'total_price_with_discount',
                text: 'Precio Total',
            }, {
                dataField: 'actions',
                text: 'Acciones',
                classes: 'actions-custom-cell'
            }
        ];

        const rowStyle2 = (row, rowIndex) => {
            const style = {};


            style.color = '#000000';
            style.height = '45px';
            style.backgroundColor = 'white';
            style.cursor = 'pointer';

            return style;
        };

        const rowStyleOrders = (row, rowIndex) => {
            const style = {};
            if(!row) return null;
            let orderDate = row.tentative_shipping_date;
            orderDate.setHours(0, 0, 0);
            let today = new Date();
            today.setHours(0, 0, 0);

            let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            let diffDays = Math.round((orderDate - today) / oneDay);

            style.backgroundColor = diffDays > 0 && diffDays < ALERT_REQUESTED_ORDERS_DAYS ? 'rgba(55, 164, 62, 0.30)' : orderDate >= today ? 'white' : 'rgba(255, 24, 24, 0.30)';
            style.color = '#000000';
            style.height = '45px';
            style.cursor = 'pointer';

            /*if (row.id === 29) {
                style.backgroundColor = 'red';
            }*/
            
            return style;
        };

        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                padding: 20,
                cursor: 'pointer',
                color: state.isFocused ? '#336636' : 'black',
                backgroundColor: state.isFocused ? '#FFFBCE' : 'white',
            }),
            menu: () => ({
                // none of react-select's styles are passed to <Control />
                backgroundColor: 'white',
                position: 'absolute',
                zIndex: '1000',
                width: '100%',
            }),
            input: () => ({
                // none of react-select's styles are passed to <Control />
                height: '52px',
            }),
        }

        return (
            <React.Fragment>
                <LoadingComponent loadingImage={Logo} loadingText={'Creando nueva orden'} loadingState={this.state.loadingCreatingOrder} />
                <LoadingComponent loadingImage={Logo} loadingText={'Despachando orden'} loadingState={this.state.dispatchingOrder} />
                <LoadingComponent loadingImage={Logo} loadingText={'Anulando orden'} loadingState={this.state.cancellingOrder} />
                <LoadingComponent loadingImage={Logo} loadingText={'Cargando ordenes.'} loadingState={this.state.loadingData}/>
                <PdfViewerComponent
                    handleClose={() => this.closePdfViewer()}
                    open={this.state.pdfViewer}
                    pdfURL={this.state.pdfURL}
                />

                <DialogComponent
                    handleClose={() => this.handleCloseOrderCreatedSuccess()}
                    open={this.state.openOrderCreatedSuccess}
                    message={this.state.messageOrderCreatedSuccess}
                    messageType='SUCCESS'
                />

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.cancellation_reason_modal}
                    onClose={this.handleCancellationReasonModalClose.bind(this)}
                    className='flomecolModalContainer'
                >
                    <div className='flomecolModalContent'>

                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <div className='modalTitle'><h2>MOTIVO DE LA ANULACIÓN</h2></div>
                            </Grid>

                            <Grid item xs={12} md={12} className='inputTextFieldContainer'>
                                <CssTextField
                                    id="outlined-multiline-static"
                                    label="Motivo de la anulación"
                                    multiline
                                    rows="4"
                                    className='inputTextField'
                                    margin="normal"
                                    variant="outlined"
                                    name='cancellation_reason'
                                    onBlur={this.handleChange.bind(this)}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} className='inputTextFieldContainer'>
                                <Button variant="contained" color="primary" onClick={this.revokeRequestedOrder.bind(this)} fullWidth>
                                    ANULAR ORDEN
                                </Button>
                            </Grid>

                        </Grid>
                    </div>
                </Modal>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.editCreateModalOpen}
                    onClose={this.handleEditCreateModalClose.bind(this)}
                    className='flomecolModalContainer'
                >
                    <div className='flomecolModalContent'>

                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <div className='modalTitle'><h2>NUEVA ORDEN DE PEDIDO</h2></div>
                            </Grid>

                            <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                                Cliente
                                <Select
                                    styles={customStyles}
                                    value={(Object.keys(this.state.selectedOption).length === 0 && this.state.selectedOption.constructor) ? '' : this.state.selectedOption}
                                    onChange={this.handleSelectChange}
                                    options={this.state.clients}
                                    placeholder=''
                                    className='react-select-container'
                                />
                            </Grid>

                            <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                                Vendedor
                                <Select
                                    styles={customStyles}
                                    value={this.state.seller}
                                    onChange={this.handleSellerSelectChange}
                                    options={this.state.sellers}
                                    placeholder=''
                                    className='react-select-container'
                                />
                            </Grid>

                            <Grid item xs={12} md={12} className='inputTextFieldContainer'>
                                <ExpansionPanel className='flomecol-expansion-panel'>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.heading}>Detalles de cliente</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>

                                        <Grid container spacing={0}>

                                            <Grid item xs={12} md={9} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Cliente"
                                                    placeholder="Cliente"
                                                    className='inputTextField'
                                                    name='client_name'
                                                    value={this.state.selectedOption.client_name}
                                                    //onChange={this.handleChange.bind(this)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <span className='date-picker-label'>Fecha de orden</span>
                                                <DatePicker
                                                    calendarAriaLabel='sdfsd'
                                                    dateFormat="MMMM - d / yyyy"
                                                    value={this.state.order_date}
                                                    onChange={this.handleDateChange.bind(this)}
                                                    minDate={new Date()}
                                                    locale="es-ES"
                                                    calendarIcon={<CalendarTodayIcon />}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={9} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Razón Social"
                                                    className='inputTextField'
                                                    name='business_name'
                                                    value={this.state.selectedOption.business_name}
                                                    //onChange={this.handleChange.bind(this)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="NIT"
                                                    className='inputTextField'
                                                    name='nit'
                                                    value={this.state.selectedOption.nit}
                                                    //onChange={this.handleChange.bind(this)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Departamento"
                                                    className='inputTextField'
                                                    name='department'
                                                    value={this.state.selectedOption.department}
                                                    //onChange={this.handleChange.bind(this)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Ciudad"
                                                    className='inputTextField'
                                                    name='city'
                                                    value={this.state.selectedOption.city}
                                                    //onChange={this.handleChange.bind(this)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Barrio"
                                                    className='inputTextField'
                                                    name='neighborhood'
                                                    value={this.state.selectedOption.neighborhood}
                                                    //onChange={this.handleChange.bind(this)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Correo"
                                                    className='inputTextField'
                                                    name='email'
                                                    value={this.state.selectedOption.email}
                                                    //onChange={this.handleChange.bind(this)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Dirección"
                                                    className='inputTextField'
                                                    name='address'
                                                    value={this.state.selectedOption.address}
                                                    //onChange={this.handleChange.bind(this)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Telefono 1"
                                                    className='inputTextField'
                                                    name='mobile1'
                                                    value={this.state.selectedOption.mobile1}
                                                    //onChange={this.handleChange.bind(this)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <CssTextField
                                                    id="outlined-phone2"
                                                    label="Telefono 2"
                                                    className='inputTextField'
                                                    name='mobile2'
                                                    value={this.state.selectedOption.mobile2}
                                                    //onChange={this.handleChange.bind(this)}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <CssTextField
                                                    id="outlined-balance"
                                                    label="Cupo ($)"
                                                    className='inputTextField'
                                                    name='balance'
                                                    value={this.state.selectedOption.balance}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                    InputProps={{
                                                        inputComponent: NumberFormatCustom,
                                                        endAdornment: <InputAdornment position="end">COP</InputAdornment>,
                                                    }}
                                                />
                                            </Grid>

                                        </Grid>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>

                            {this.state.products.map((product) => (
                                <Grid container spacing={0} className='request-oder-list-product' key={product.id}>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Nombre producto"
                                            className='inputTextField'
                                            name='list_product_name'
                                            value={product.product_name}
                                            margin="normal"
                                            variant="outlined"
                                            disabled={true}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Presentación"
                                            className='inputTextField'
                                            name='list_product_presentation'
                                            value={product.product_presentation}
                                            margin="normal"
                                            variant="outlined"
                                            disabled={true}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Precio"
                                            className='inputTextField'
                                            name='list_product_price'
                                            value={product.product_price}
                                            margin="normal"
                                            variant="outlined"
                                            disabled={true}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Precio Total"
                                            className='inputTextField'
                                            name='total_price'
                                            type='number'
                                            value={product.total_price}
                                            disabled={true}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={1} className='inputTextFieldContainer'>
                                        <CssTextField
                                            id="outlined-name"
                                            label="Bono"
                                            className='inputTextField'
                                            name='list_product_bonus_quantity'
                                            type='number'
                                            value={product.bonus_quantity}
                                            onChange={(event) => this.handleProductListChange(event, 'bonus_quantity', product.id)}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <CssTextField
                                            id="outlined-name"
                                            label="Cantidad"
                                            className='inputTextField'
                                            name='list_product_quantity'
                                            type='number'
                                            value={product.quantity}
                                            onChange={(event) => this.handleProductListChange(event, 'quantity', product.id)}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={1} className='inputTextFieldContainer'>
                                        <Button variant="contained" className='flomecol-danger-button' fullWidth onClick={() => this.deleteProduct(product.id)} >
                                            <DeleteForeverIcon />
                                        </Button>
                                    </Grid>
                                    {
                                        /*

                                        <Grid item xs={3} md={1} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Pendiente"
                                            className='inputTextField'
                                            name='list_product_pending_quantity'
                                            type='number'
                                            value={product.pending_quantity}
                                            onChange={(event) => this.handleProductListChange(event,'pending_quantity', product.id)}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={3} md={3} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Lote"
                                            className='inputTextField'
                                            name='list_product_batch'
                                            value={product.product_batch}
                                            onChange={(event) => this.handleProductListChange(event,'product_batch', product.id)}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>

                                        */
                                    }

                                </Grid>
                            ))}

                            <Grid item xs={12} sm={9} className='inputTextFieldContainer'>
                                <strong className='create-requested-order-product-select'>SELECCIONE UN PRODUCTO PARA AGREGAR A LA ORDEN</strong>
                                <Select
                                    styles={customStyles}
                                    value={this.state.selectedProduct}
                                    onChange={this.handleSelectedProductChange}
                                    options={this.state.loadedProducts}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3} className='inputTextFieldContainer'>
                                <Button variant="contained" color="primary" onClick={this.addProduct.bind(this)} className='inputTextFieldContainerFull'>
                                    AGREGAR PRODUCTO
                                </Button>
                            </Grid>

                            <hr className='hr-divider' />

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Descuento directo (%)"
                                    className='inputTextField'
                                    name='direct_discount'
                                    type='number'
                                    value={this.state.selectedOption.direct_discount}
                                    disabled={true}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                <CssTextField
                                    id="outlined-name"
                                    label="Descuento pronto pago (%)"
                                    className='inputTextField'
                                    name='discount_prompt_payment'
                                    type='number'
                                    value={this.state.discount_prompt_payment}
                                    onChange={this.handleChange.bind(this)}
                                    margin="normal"
                                    variant="outlined"
                                    disabled={this.state.products.length === 0}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Descuento total (%)"
                                    className='inputTextField'
                                    name='total_discount'
                                    type='number'
                                    value={parseInt(this.state.discount_prompt_payment) + parseInt(this.state.selectedOption.direct_discount)}
                                    disabled={true}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Costo total con descuento"
                                    className='inputTextField'
                                    name='order_total_cost'
                                    value={this.state.order_total_cost}
                                    margin="normal"
                                    variant="outlined"
                                    disabled={true}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-name"
                                    label="Costo total sin descuento"
                                    className='inputTextField'
                                    name='order_total_cost'
                                    value={(parseInt(this.state.order_total_cost) * (parseInt(this.state.discount_prompt_payment) + parseInt(this.state.selectedOption.direct_discount)) / (100 - (parseInt(this.state.discount_prompt_payment) + parseInt(this.state.selectedOption.direct_discount)))) + (parseInt(this.state.order_total_cost))}
                                    margin="normal"
                                    variant="outlined"
                                    disabled={true}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                <Autocomplete
                                    id="combo-box-demo"
                                    style={{ width: '100%' }}
                                    freeSolo={false}
                                    options={this.state.cities}
                                    getOptionLabel={option => option.name}
                                    value={this.state.dispatchCity}
                                    onChange={(event, newValue) => { this.setSelectedDispatchCity(newValue, event) }}
                                    autoHighlight
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Ciudad"
                                            variant="outlined"
                                            fullWidth
                                            required
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>

                                <Select
                                    styles={customStyles}
                                    value={this.state.invoice_term}
                                    onChange={this.handleTermSelectChange}
                                    options={this.state.invoiceTerms}
                                    placeholder='Plazo de pago (dias)'
                                    className='react-select-container'
                                />
                            </Grid>

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                <span className='date-picker-label'>Fecha de pago</span>
                                <DatePicker
                                    dateFormat="MMMM - d / yyyy"
                                    value={this.state.payment_date}
                                    minDate={new Date()}
                                    locale="es-ES"
                                    calendarIcon={<CalendarTodayIcon />}
                                    disabled
                                    disableCalendar={true}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                <span className='date-picker-label'>Fecha de despacho</span>
                                <DatePicker
                                    dateFormat="MMMM - d / yyyy"
                                    value={this.state.shipping_date}
                                    onChange={this.handleShippingDateChange.bind(this)}
                                    minDate={new Date()}
                                    locale="es-ES"
                                    calendarIcon={<CalendarTodayIcon />}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} className='inputTextFieldContainer'>
                                <CssTextField
                                    id="outlined-multiline-static"
                                    label="Observaciones Generales"
                                    multiline
                                    rows="4"
                                    className='inputTextField'
                                    margin="normal"
                                    variant="outlined"
                                    name='general_observations'
                                    onBlur={this.handleChange.bind(this)}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} className='inputTextFieldContainer'>
                                <Button variant="contained" color="primary" onClick={this.doCreateOrder.bind(this)} fullWidth>
                                    CREAR NUEVA ORDEN
                                </Button>
                            </Grid>

                        </Grid>

                    </div>
                </Modal>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.sendOrderModalOpen}
                    onClose={this.handleSendModalModalClose.bind(this)}
                    className='flomecolModalContainer'
                >
                    <div className='flomecolModalContent'>

                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <div className='modalTitle'><h2>DESPACHAR ORDEN DE PEDIDO #{this.state.selectedSendOrder.id}</h2></div>
                            </Grid>



                            <Grid item xs={12} md={12} className='inputTextFieldContainer'>
                                <ExpansionPanel className='flomecol-expansion-panel'>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.heading}>Detalles</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>

                                        <Grid container spacing={0}>
                                            <Grid item xs={12} md={12} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Vendedor"
                                                    className='inputTextField'
                                                    name='client_name'
                                                    value={this.state.selectedSendOrder.seller}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={9} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Cliente"
                                                    className='inputTextField'
                                                    name='client_name'
                                                    value={this.state.selectedSendOrder.client_name}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <span className='date-picker-label'>Fecha de orden</span>
                                                <DatePicker
                                                    dateFormat="MMMM - d / yyyy"
                                                    value={this.state.selectedSendOrder.order_date}
                                                    minDate={new Date()}
                                                    locale="es-ES"
                                                    disabled
                                                    calendarIcon={<CalendarTodayIcon />}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={9} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Razón Social"
                                                    className='inputTextField'
                                                    name='business_name'
                                                    value={this.state.selectedSendOrder.business_name}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="NIT"
                                                    className='inputTextField'
                                                    name='nit'
                                                    value={this.state.selectedSendOrder.nit}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Departamento"
                                                    className='inputTextField'
                                                    name='department'
                                                    value={this.state.selectedSendOrder.department}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Ciudad"
                                                    className='inputTextField'
                                                    name='city'
                                                    value={this.state.selectedSendOrder.city}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Barrio"
                                                    className='inputTextField'
                                                    name='neighborhood'
                                                    value={this.state.selectedSendOrder.neighborhood}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Correo"
                                                    className='inputTextField'
                                                    name='email'
                                                    value={this.state.selectedSendOrder.email}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Dirección"
                                                    className='inputTextField'
                                                    name='address'
                                                    value={this.state.selectedSendOrder.address}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Telefono 1"
                                                    className='inputTextField'
                                                    name='mobile1'
                                                    value={this.state.selectedSendOrder.mobile1}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Telefono 2"
                                                    className='inputTextField'
                                                    name='mobile2'
                                                    value={this.state.selectedSendOrder.mobile2}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Descuento directo (%)"
                                                    className='inputTextField'
                                                    name='direct_discount'
                                                    type='number'
                                                    value={this.state.selectedSendOrder.direct_discount}
                                                    disabled={true}
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Descuento pronto pago (%)"
                                                    className='inputTextField'
                                                    name='discount_prompt_payment'
                                                    type='number'
                                                    value={this.state.selectedSendOrder.discount_prompt_payment}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Descuento total (%)"
                                                    className='inputTextField'
                                                    name='total_discount'
                                                    type='number'
                                                    value={parseInt(this.state.selectedSendOrder.discount_prompt_payment) + parseInt(this.state.selectedSendOrder.direct_discount)}
                                                    disabled={true}
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Costo total con descuento"
                                                    className='inputTextField'
                                                    name='order_total_cost'
                                                    value={this.state.selectedSendOrder.total_price_with_discount_text}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Costo total sin descuento"
                                                    className='inputTextField'
                                                    name='order_total_cost'
                                                    value={this.state.selectedSendOrder.total_price}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-name"
                                                    label="Plazo de Pago"
                                                    className='inputTextField'
                                                    name='invoice_term'
                                                    value={this.state.selectedSendOrder.invoice_term}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                                <span className='date-picker-label'>Fecha de pago</span>
                                                <DatePicker
                                                    dateFormat="MMMM - d / yyyy"
                                                    value={new Date(this.state.selectedSendOrder.payment_date)}
                                                    minDate={new Date()}
                                                    locale="es-ES"
                                                    calendarIcon={<CalendarTodayIcon />}
                                                    disabled
                                                    disableCalendar={true}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                                <span className='date-picker-label'>Fecha de despacho</span>
                                                <DatePicker
                                                    dateFormat="MMMM - d / yyyy"
                                                    value={new Date(this.state.selectedSendOrder.tentative_shipping_date)}
                                                    onChange={this.handleShippingDateChange.bind(this)}
                                                    minDate={new Date()}
                                                    locale="es-ES"
                                                    disabled
                                                    calendarIcon={<CalendarTodayIcon />}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={12} className='inputTextFieldContainer'>
                                                <TextField
                                                    id="outlined-multiline-static"
                                                    label="Observaciones Generales"
                                                    multiline
                                                    rows="4"
                                                    className='inputTextField'
                                                    margin="normal"
                                                    variant="outlined"
                                                    name='general_observations'
                                                    value={this.state.selectedSendOrder.general_observations}
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </Grid>

                                            <Grid item xs={12} className='inputTextFieldContainer'>
                                                <CssTextField
                                                    id="outlined-balance"
                                                    label="Cupo ($)"
                                                    className='inputTextField'
                                                    name='balance'
                                                    value={this.state.selectedSendOrder.balance}
                                                    margin="normal"
                                                    variant="outlined"
                                                    disabled={true}
                                                    fullWidth
                                                    InputProps={{
                                                        inputComponent: NumberFormatCustom,
                                                        endAdornment: <InputAdornment position="end">COP</InputAdornment>,
                                                    }}
                                                />
                                            </Grid>

                                        </Grid>


                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>

                            {this.state.selectedSendOrder.products.map((product) => (
                                <Grid container spacing={0} className='request-oder-list-product' key={product.id}>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Nombre producto"
                                            className='inputTextField'
                                            name='list_product_name'
                                            value={product.product_name}
                                            margin="normal"
                                            variant="outlined"
                                            disabled={true}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Presentación"
                                            className='inputTextField'
                                            name='list_product_presentation'
                                            value={product.product_presentation}
                                            margin="normal"
                                            variant="outlined"
                                            disabled={true}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Precio"
                                            className='inputTextField'
                                            name='list_product_price'
                                            value={product.product_price}
                                            margin="normal"
                                            variant="outlined"
                                            disabled={true}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Cantidad"
                                            className='inputTextField'
                                            name='list_product_quantity'
                                            type='number'
                                            value={product.quantity}
                                            disabled={true}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Bono"
                                            className='inputTextField'
                                            name='list_product_bonus_quantity'
                                            type='number'
                                            value={product.bonus_quantity}
                                            disabled={true}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Precio Total"
                                            className='inputTextField'
                                            name='total_price'
                                            type='number'
                                            value={product.product_price * product.quantity}
                                            disabled={true}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>


                                    <Grid item xs={6} md={6} className='inputTextFieldContainer'>
                                        <CssTextField
                                            id="outlined-name"
                                            label="Cantidad Pendiente"
                                            className='inputTextField'
                                            name='list_product_pending_quantity'
                                            type='number'
                                            value={product.pending_quantity !== undefined && product.pending_quantity !== null ? product.pending_quantity : ''}
                                            onChange={(event) => this.handleSendOrderProductListChange(event, 'pending_quantity', product.id)}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={6} className='inputTextFieldContainer'>
                                        <CssTextField
                                            id="outlined-name"
                                            label="Lote"
                                            className='inputTextField'
                                            name='list_product_batch'
                                            value={product.product_batch !== undefined && product.product_batch !== null ? product.product_batch : ''}
                                            onChange={(event) => this.handleSendOrderProductListChange(event, 'product_batch', product.id)}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>

                                </Grid>
                            ))}

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                <span className='date-picker-label'>Fecha real de despacho</span>
                                <DatePicker
                                    dateFormat="MMMM - d / yyyy"
                                    value={new Date(this.state.real_shipping_date)}
                                    locale="es-ES"
                                    disabled
                                    calendarIcon={<CalendarTodayIcon />}
                                />
                            </Grid>

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                <CssTextField
                                    id="outlined-name"
                                    label="Número de Guia"
                                    className='inputTextField'
                                    name='guide_number'
                                    onBlur={this.handleChange.bind(this)}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={4} className='inputTextFieldContainer'>

                                <Select
                                    styles={customStyles}
                                    value={this.state.transporter}
                                    onChange={this.handleTransporterSelectChange}
                                    options={this.state.transporters}
                                    placeholder='Transportador'
                                    className='react-select-container'
                                />
                            </Grid>

                            <Grid item xs={12} md={12} className='inputTextFieldContainer'>
                                <CssTextField
                                    id="outlined-multiline-static"
                                    label="Observaciones de despacho"
                                    multiline
                                    rows="4"
                                    className='inputTextField'
                                    margin="normal"
                                    variant="outlined"
                                    name='shipping_observations'
                                    onBlur={this.handleChange.bind(this)}
                                    fullWidth
                                />
                            </Grid>


                            <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-invoice-number"
                                    label="Número de factura"
                                    className='inputTextField'
                                    name='invoice_number'
                                    value={this.state.invoice_number}
                                    onChange={this.handleChange.bind(this)}
                                    margin="normal"
                                    variant="outlined"
                                    disabled={false}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                                <TextField
                                    id="outlined-real-amount"
                                    label="Valor real de la factura"
                                    className='inputTextField'
                                    name='real_amount'
                                    value={this.state.real_amount}
                                    onBlur={this.handleRealAmountChange.bind(this)}
                                    margin="normal"
                                    variant="outlined"
                                    disabled={false}
                                    fullWidth
                                    InputProps={{
                                        inputComponent: NumberFormatCustom,
                                        endAdornment: <InputAdornment position="end">COP</InputAdornment>,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} className='inputTextFieldContainer'>
                                <Button variant="contained" color="primary" onClick={this.doDispatchOrder.bind(this)} fullWidth>
                                    DESPACHAR ORDEN
                                </Button>
                            </Grid>

                        </Grid>

                    </div>
                </Modal>

                <Grid container spacing={0} className='ModuleMainContainer'>
                    {
                        this.state.canCreate && <React.Fragment>
                            <Button variant="contained" color="primary" onClick={this.handleEditCreateModalOpen.bind(this)}>
                                CREAR NUEVA ORDEN
                            </Button>
                        </React.Fragment>
                    }

                    <Grid item xs={12}>
                        <div className="clients-search-box">
                            <InputBase
                                placeholder="BUSCAR ORDEN. . . "
                                classes={{
                                    root: "clients-search-input-root",
                                    input: "clients-search-input-input",
                                }}
                                value={this.state.searchText}
                                onChange={(e) => this.setState({searchText: e.target.value}, () => {
                                    this.filterOrders()
                                })}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <h3 className='table-title'>LISTADO DE ORDENES DE PEDIDO</h3>
                    </Grid>

                    <AppBar position="static" className={classes.root}>
                        <Tabs variant="fullWidth" value={this.state.value} onChange={this.handleChangeValue} classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}>
                            <Tab label="SIN DESPACHAR" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
                            <Tab label="CON PENDIENTES" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
                            <Tab label="DESPACHADAS" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
                            <Tab label="ANULADAS" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
                        </Tabs>
                    </AppBar>

                    {this.state.value === 0 &&

                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <BootstrapTable
                                    remote
                                    keyField='id'
                                    data={this.state.orders}
                                    columns={columns}
                                    rowStyle={rowStyleOrders}
                                    classes='bootstrap-table'
                                    wrapperClasses='bootstrap-table-wrapper'
                                    headerClasses='bootstrap-table-header'
                                    pagination={paginationFactory(this.state.options)}
                                    onTableChange={ (type, newState) => this.onTableChange(type, newState) }
                                />
                            </Grid>
                        </Grid>
                    }

                    {this.state.value === 1 &&

                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <BootstrapTable
                                    remote
                                    keyField='id'
                                    data={this.state.orders}
                                    columns={columns}
                                    rowStyle={rowStyle2}
                                    classes='bootstrap-table'
                                    wrapperClasses='bootstrap-table-wrapper'
                                    headerClasses='bootstrap-table-header'
                                    pagination={paginationFactory(this.state.options)}
                                    onTableChange={ (type, newState) => this.onTableChange(type, newState) }
                                />
                            </Grid>
                        </Grid>
                    }

                    {this.state.value === 2 &&

                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <BootstrapTable
                                    remote
                                    keyField='id'
                                    data={this.state.orders}
                                    columns={columns}
                                    rowStyle={rowStyle2}
                                    classes='bootstrap-table'
                                    wrapperClasses='bootstrap-table-wrapper'
                                    headerClasses='bootstrap-table-header'
                                    pagination={paginationFactory(this.state.options)}
                                    onTableChange={ (type, newState) => this.onTableChange(type, newState) }
                                />
                            </Grid>
                        </Grid>
                    }

                    {this.state.value === 3 &&

                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <BootstrapTable
                                    remote
                                    keyField='id'
                                    data={this.state.orders}
                                    columns={columns}
                                    rowStyle={rowStyle2}
                                    classes='bootstrap-table'
                                    wrapperClasses='bootstrap-table-wrapper'
                                    headerClasses='bootstrap-table-header'
                                    pagination={paginationFactory(this.state.options)}
                                    onTableChange={ (type, newState) => this.onTableChange(type, newState) }
                                />
                            </Grid>
                        </Grid>
                    }
                </Grid>


            </React.Fragment>
        )
    }
}

export default withStyles(styles)(RequestedOrdersComponent);