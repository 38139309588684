import React, { Fragment, useRef } from 'react'
import ProductionControlController from './ProductionControlController'
import './ProductionControlComponent.css';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";
import AddCircleIcon from "@material-ui/icons/AddCircleOutline";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import Logo from "../../images/Logo_flomecol.svg";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import Select from "react-select";
import InputBase from "@material-ui/core/InputBase";
import DatePicker from 'react-date-picker';
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import QRCode from "react-qr-code";
import ReactToPrint from 'react-to-print';
import DialogComponent from "../DialogComponent/DialogComponent";
import PrintIcon from '@material-ui/icons/Print';

class PrintAreaComponent extends React.PureComponent {
    
    render() {

        const { QRInfo } = this.props;

        const stickets_ = [];

        const renderSticker = (QRInfo, i, type) => {
            return <div className='printProductionControlContainer' key={`${i}${type}`}>
                <div className='printProductionControl'>
                    <div className='productionControlLeftPanel' >
                        <div className='productionControlLeftPanelTop' >
                            <div>PRODUCTO: {QRInfo.productName.toUpperCase()}</div>
                            <div>LOTE: {QRInfo.batchNumber.toUpperCase()}</div>
                            <div>FECHA DE VENCIMIENTO: {QRInfo.dueDate.toUpperCase()}</div>
                            <div>EMBALAJE: {QRInfo.packaging.toUpperCase()}</div>
                            <hr/>
                            <div>RESPONSABLE: {QRInfo.responsable.toUpperCase()}</div>
                            <div>FECHA DE ENTREGA: {QRInfo.deliveryDate.toUpperCase()}</div>
                            <div>HORA: {QRInfo.time.toUpperCase()}</div>
                            <div>CAJA NUMERO: {i} DE {QRInfo.boxes}</div>
                        </div>
                    </div>
                    <div className='productionControlRightPanel' >
                        <div className='productionControlRightPanelBottom' >
                            <QRCode value={
                                'PRODUCTO: ' + QRInfo.productName.toUpperCase() +
                                '\u000ALOTE: ' + QRInfo.batchNumber.toUpperCase() +
                                '\u000AFECHA DE VENCIMIENTO: ' + QRInfo.dueDate.toUpperCase() +
                                '\u000AEMBALAJE: ' + QRInfo.packaging.toUpperCase() +
                                '\u000A' +
                                '\u000ARESPONSABLE: ' + QRInfo.responsable.toUpperCase() +
                                '\u000AFECHA DE ENTREGA: ' + QRInfo.deliveryDate.toUpperCase() +                          
                                '\u000AHORA: ' + QRInfo.time.toUpperCase() +
                                '\u000ACAJA NUMERO: ' + i + ' DE ' + QRInfo.boxes
                                }
                                size={100}
                            />
                        </div>
                    </div>
                    
                </div>
                <div className='stickerLabel'>********* {type} *********</div>
            </div>
        }

        for(let i = 1; i <= QRInfo.boxes; i++){
            stickets_.push(renderSticker(QRInfo, i, 'ORIGINAL'))
            stickets_.push(renderSticker(QRInfo, i, 'COPIA'))
        }

        return(
            <div id='printAreaProductionControl'>
                {stickets_.map((sticker) => ( sticker ))}
            </div>
        )
    }
}

const ProductionControlComponent = () => {

    const controllerProps = ProductionControlController({});
    const componentToPrintRef = useRef();

    const { 
        componentPermissions,
        searchText,
        setSearchText,
        isEditCreateModalOpen, 
        setIsEditCreateModalOpen,
        columns,
        rowStyle,
        QRInfo,
        setQRInfo,
        doCreateProductionControl,
        customStyles,
        selectedProduct,
        handleSelectedProductChange,
        loadedProducts,
        isPrintAreaModalOpen,
        setIsPrintAreaModalOpen,
        openProductionControlCreatedSuccess, 
        setOpenProductionControlCreatedSuccess,
        dialogMessage, 
        creatingProductionControl,
        productionControlList,
        onTableChange,
        options,
        handleOpenEditCreateModalOpen,
        gettingProductionControl
    } = controllerProps;

    if(!componentPermissions) return null

    const printArea = <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isPrintAreaModalOpen}
        onClose={() => setIsPrintAreaModalOpen(false)}
        className='flomecolModalContainer'
    >
        <div className='flomecolModalContent'>
            <div>
                <PrintAreaComponent QRInfo={QRInfo} ref={componentToPrintRef} />
            </div>
            <div>
                <ReactToPrint
                    trigger={() => <Grid item xs={12} className='inputTextFieldContainer'>
                        <Button variant="contained" color="primary" className='printButton'>
                            <PrintIcon/> &nbsp; IMPRIMIR
                        </Button>
                    </Grid>}
                    content={() => componentToPrintRef.current}
                />
            </div>
        </div>
        
    </Modal>


    const editCreateModal = <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isEditCreateModalOpen}
        onClose={() => setIsEditCreateModalOpen(false)}
        className='flomecolModalContainer'
    >
        <div className='flomecolModalContent'>

            <Grid container spacing={1}>

                <Grid item xs={12}>
                    <div className='modalTitle'><h2>NUEVO DESPACHO</h2></div>
                </Grid>

                <Grid item xs={12} sm={12} className='inputTextFieldContainer'>
                    <strong className='create-requested-order-product-select'>SELECCIONE UN PRODUCTO</strong>
                    <Select
                        styles={customStyles}
                        value={selectedProduct}
                        onChange={(e) => handleSelectedProductChange(e)}
                        options={loadedProducts}
                    />
                </Grid>

                <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                    <TextField
                        id="outlined-batch-number"
                        label="Lote"
                        className='inputTextField'
                        name='batch_number'
                        value={QRInfo.batchNumber}
                        onChange={(e) => setQRInfo({ ...QRInfo, ...{ batchNumber: e.target.value }})}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                    <span className='date-picker-label'>Fecha de vencimiento</span>
                    <DatePicker
                        dateFormat="MMMM - d / yyyy"
                        value={QRInfo.dueDate}
                        onChange={(e) => setQRInfo({ ...QRInfo, ...{ dueDate: e }})}
                        minDate={new Date()}
                        locale="es-ES"
                        calendarIcon={<CalendarTodayIcon />}
                    />
                </Grid>

                <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                    <TextField
                        id="outlined-packaging"
                        label="Embalaje"
                        className='inputTextField'
                        name='packaging'
                        value={QRInfo.packaging}
                        onChange={(e) => setQRInfo({ ...QRInfo, ...{ packaging: e.target.value }})}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                    <TextField
                        id="outlined-responsable"
                        label="Responsable"
                        className='inputTextField'
                        name='responsable'
                        value={QRInfo.responsable}
                        onChange={(e) => setQRInfo({ ...QRInfo, ...{ responsable: e.target.value }})}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                    <span className='date-picker-label'>Fecha de entrega</span>
                    <DatePicker
                        dateFormat="MMMM - d / yyyy"
                        value={QRInfo.deliveryDate}
                        onChange={(e) => setQRInfo({ ...QRInfo, ...{ deliveryDate: e }})}
                        minDate={new Date()}
                        locale="es-ES"
                        calendarIcon={<CalendarTodayIcon />}
                    />
                </Grid>

                <Grid item xs={12} md={2} className='inputTextFieldContainer'>
                    <TextField
                        id="outlined-time"
                        label="Hora"
                        className='inputTextField'
                        name='time'
                        value={QRInfo.time}
                        onChange={(e) => setQRInfo({ ...QRInfo, ...{ time: e.target.value }})}
                        margin="normal"
                        variant="outlined"
                        disabled={!!QRInfo.time}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={2} className='inputTextFieldContainer'>
                    <TextField
                        id="outlined-boxes"
                        type="number"
                        label="Número de cajas"
                        className='inputTextField'
                        name='boxes'
                        value={QRInfo.boxes}
                        onChange={(e) => setQRInfo({ ...QRInfo, ...{ boxes: e.target.value }})}
                        onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                        }}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} className='inputTextFieldContainer'>
                    <Button variant="contained" color="primary" onClick={doCreateProductionControl}>
                        <AddCircleIcon/> &nbsp; CREAR NUEVO DESPACHO
                    </Button>
                </Grid>
                
            </Grid>
        </div>
    </Modal>

    return(
        <Fragment>

            <LoadingComponent loadingImage={Logo} loadingText={'Creando control de productos.'} loadingState={creatingProductionControl}/>
            <LoadingComponent loadingImage={Logo} loadingText={'Obteniedo listado control de productos.'} loadingState={gettingProductionControl}/>

            <DialogComponent
                handleClose={() => setOpenProductionControlCreatedSuccess(false)}
                open={openProductionControlCreatedSuccess}
                message={dialogMessage}
                messageType='SUCCESS'
            />

            {printArea}

            {editCreateModal}

            <Grid container spacing={0} className='ModuleMainContainer'>

                    { componentPermissions.canEdit && 
                        <Fragment>
                                <Button variant="contained" color="primary" onClick={() => handleOpenEditCreateModalOpen()}>
                                    <AddCircleIcon/> &nbsp; CREAR NUEVO DESPACHO
                                </Button>
                        </Fragment>
                    }

                    <Grid item xs={12}>
                        <div className="clients-search-box">
                            <InputBase
                                placeholder="BUSCAR DESPACHO. . . "
                                classes={{
                                    root: "clients-search-input-root",
                                    input: "clients-search-input-input",
                                }}
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </div>
                    </Grid>

                    <Grid container spacing={0} className='tables-container'>
                        <Grid item xs={12}>
                            <h3 className='table-title'>LISTADO DE DESPACHOS</h3>
                        </Grid>
                        <Grid item xs={12}>
                            <BootstrapTable
                                remote
                                keyField='id'
                                data={ productionControlList }
                                columns={ columns }
                                rowStyle={ rowStyle }
                                classes='bootstrap-table'
                                wrapperClasses='bootstrap-table-wrapper'
                                headerClasses='bootstrap-table-header'
                                pagination={ paginationFactory(options)}
                                onTableChange={ (type, newState) => onTableChange(type, newState) }
                            />
                        </Grid>
                    </Grid>


                </Grid>
        </Fragment>
    )

}

export default ProductionControlComponent