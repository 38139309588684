import React from 'react';

class FooterComponent extends React.Component{
    render(){
        return(
            <React.Fragment>
            </React.Fragment>
        )
    }
}

export default FooterComponent;