import React from 'react';
import './App.css';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Home from './layouts/Home/Home';
import NotFound from "./layouts/NotFound/NotFound";
import RequestedOrders from "./layouts/RequestedOrders/RequestedOrders";
import RequestedOrdersPortfolio from "./layouts/RequestedOrdersPortfolio/RequestedOrdersPortfolio";
import Clients from "./layouts/Clients/Clients";
import Login from "./layouts/Login/Login";
import AllyLogin from "./layouts/AllyLogin/AllyLogin";
import RequestedOrdersMetrics from "./layouts/RequestedOrdersMetrics/RequestedOrdersMetrics";
import AllyDashboard from "./layouts/AllyDashboard/AllyDashboard";
import AllyRequestedOrders from "./layouts/AllyRequestedOrders/AllyRequestedOrders";
import AdminProducts from "./layouts/AdminProducts/AdminProducts";
import Viaticos from "./layouts/Viaticos/Viaticos";
import StaffAccess from "./layouts/StaffAccess/StaffAccess";
import CashReceipts from "./layouts/CashReceipts/CashReceipts";
import StaffAccessReportLayout from './layouts/StaffAccessReportLayout/StaffAccessReportLayout';
import ProductionControl from './layouts/ProductionControl/ProductionControl';
import PrivateRoute from './utils/PrivateRoute';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#336636',
        },
        secondary: {
            main: '#000000',
        },
        error: {
            main: '#000000',
        },
    },
});

class App extends React.Component{
  render(){
    return (
        <React.Fragment>
          <MuiThemeProvider theme={theme}>
            <Router>
              <ScrollToTop>
                <Switch>
                  <Route exact path="/"  component={Login} />
                  <PrivateRoute exact path="/dashboard"  component={Home} />
                  <PrivateRoute exact path="/ordenes-pedido"  component={RequestedOrders} />
                  <PrivateRoute exact path="/autorizaciones-ordenes-pedido"  component={RequestedOrdersPortfolio} />
                  <PrivateRoute exact path="/metricas-ordenes-pedido"  component={RequestedOrdersMetrics} />
                  <PrivateRoute exact path="/clientes"  component={Clients} />
                  <Route exact path="/aliados"  component={AllyLogin} />
                  <Route exact path="/alidos-panel"  component={AllyDashboard} />
                  <Route exact path="/ordenes-pedido-aliado"  component={AllyRequestedOrders} />
                  <PrivateRoute exact path="/admin-productos"  component={AdminProducts} />
                  <PrivateRoute exact path="/viaticos"  component={Viaticos} />
                  <Route exact path="/acceso-personal"  component={StaffAccess} />
                  <PrivateRoute exact path="/recibos-de-caja" component={CashReceipts} />
                  <PrivateRoute exact path="/acceso-personal-reporte" component={StaffAccessReportLayout} />
                  <PrivateRoute exact path="/control-producion-bodega" component={ProductionControl} />

                  <Route component={NotFound} />
                </Switch>
              </ScrollToTop>
            </Router>
          </MuiThemeProvider>
        </React.Fragment>
    );
  }
}

export default App;
