export const message_responses = {
    'UPDATE_REQUESTED_ORDER_STATUS_ERROR': 'ERROR DE SERVIDOR: Hemos tenido un error con esta petición al servidor, si el problema continua por favor contactar al área encargada.',
    'UPDATE_REQUESTED_ORDER_STATUS_SUCCESS': 'Orden anulada correctamente.',
    'REVOKE_CLIENT_LOGIN_ACCESS_ERROR': 'ERROR DE SERVIDOR: Hemos tenido un error con esta petición al servidor, si el problema continua por favor contactar al área encargada.',
    'REVOKE_CLIENT_LOGIN_ACCESS_SUCCESS': 'Acceso de cliente modificado correctamente.',
    'ACTIVATE_CLIENT_LOGIN_ACCESS_ERROR': 'ERROR DE SERVIDOR: Hemos tenido un error con esta petición al servidor, si el problema continua por favor contactar al área encargada.',
    'ACTIVATE_CLIENT_LOGIN_ACCESS_SUCCESS': 'Acceso de cliente modificado correctamente.',
    'ALLY_LOGIN_ERROR': 'Credenciales invalidas o usuario no existe.',
    'ALLY_LOGIN_SUCCESS': 'Acceso correcto.',
    'ALLY_LOGIN_INVALID_CREDENTIALS': 'Credenciales de usuario invalidas.',
    'ALLY_CANT_DO_LOGIN': 'Este usuario no tiene habilitado acceso a través de la plataforma, para solicitar acceso puedes comunicarte con nuestra área encargada al número ####### o puedes comunicarte al número de tu vendedor.',
    'USERNAME_NOT_EXIT_ERROR': 'ERROR: Usuario no existe.',
    'GET_USER_BY_USERNAME_ERROR': 'ERROR DE SERVIDOR: Hemos tenido un error con esta petición al servidor, si el problema continua por favor contactar al área encargada.',
    'ACCESS_CONTROL_USER_NOT_FOUND': 'ERROR: código de acceso invalido.',
    'ACCESS_CONTROL_INVALID_CREDENTIALS': 'ERROR: Contraseña de acceso invalida.',
    'ACCESS_CONTROL_ERROR': 'ERROR: Error registrando acceso.',
    'CHECK_ACCESS_CONTROL_USER_NOT_FOUND': 'ERROR: código de acceso invalido.',
    'CHECK_ACCESS_CONTROL_INVALID_CREDENTIALS': 'ERROR: Contraseña de acceso invalida.',

    'GET_EMPLOYEES_ERROR': 'ERROR: Error cargando empleados.',
    'UNAUTHORIZED': 'NO AUTORIZADO: Tu sesión ha expirado o no estas autorizado para acceder a este servicio, por favor inicia sesión nuevamente.'
}