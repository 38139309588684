import React from 'react';
import AllyRequestedOrdersComponent from "../../components/AllyRequestedOrdersComponent/AllyRequestedOrdersComponent";
import AllyHeaderComponent from "../../components/AllyHeaderComponent/AllyHeaderComponent";

class AllyRequestedOrders extends React.Component{
    render(){
        return(
            <React.Fragment>
                <AllyHeaderComponent/>
                <AllyRequestedOrdersComponent/>
            </React.Fragment>
        )
    }
}

export default AllyRequestedOrders;