import React from 'react';
import HeaderComponent from "../../components/HeaderComponent/HeaderComponent";
import FooterComponent from "../../components/FooterComponent/FooterComponent";
import ClientsComponent from "../../components/ClientsComponent/ClientsComponent";

class Clients extends React.Component{
    render(){
        return(
            <React.Fragment>
                <HeaderComponent/>
                <ClientsComponent/>
                <FooterComponent/>
            </React.Fragment>
        )
    }
}

export default Clients;