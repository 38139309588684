import React from 'react';

class NotFoundComponent extends React.Component{
    render(){
        return(
            <React.Fragment>
                ERROR 404: Página no encontrada.
            </React.Fragment>
        )
    }
}

export default NotFoundComponent;