import React from 'react';
import AllyLoginComponent from "../../components/AllyLoginComponent/AllyLoginComponent";


class AllyLogin extends React.Component{
    render(){
        return(
            <React.Fragment>
                <AllyLoginComponent/>
            </React.Fragment>
        )
    }
}

export default AllyLogin;