export const getAuthToken = () => {
  const userData = localStorage.getItem('data');
  const userObj = JSON.parse(userData);
  return userObj ? `Bearer ${userObj.token}` : null;
}

export const getUser = () => {
  const userData = localStorage.getItem('data');
  const userObj = JSON.parse(userData);
  return userObj;
}
