import axios from "axios";
import { getAuthToken } from "../utils/auth";
import { API_URL } from "../API";
import { message_responses } from "../MESSAGE_RESPONSES";

const axiosClient = axios.create({
  baseURL: API_URL,
});

axiosClient.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    return {
      ...config,
      headers: {
        ...(token !== null && { Authorization: `${token}` }),
        ...config.headers,
      },
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      alert(message_responses.UNAUTHORIZED);
      window.location = '/'
    }
});

export default axiosClient;
