import React, { Fragment } from  'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import DatePicker from 'react-date-picker';
import StaffAccessReportController from './StaffAccessReportController';
import Select from "react-select";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Logo from "../../images/Logo_flomecol.svg";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import './StaffAccessReport.css';

const StaffAccessReport = () => {

    const componentProps = StaffAccessReportController({});

    const {
        onChangeStartDate,
        onChangeEndDate,
        todayDate,
        dateStartPicker,
        dateEndPicker,
        selectedEmployee,
        handleSelectedEmployeeChange,
        employees,
        loadingEmployees,
        columns,
        rowStyle,
        onTableChange,
        report,
        options,
        loadingReport
    } = componentProps;

    return <Fragment>
        <LoadingComponent loadingImage={Logo} loadingText={'Cargando empleados.'} loadingState={loadingEmployees}/>
        <LoadingComponent loadingImage={Logo} loadingText={'Cargando registro.'} loadingState={loadingReport}/>

        <Container component="main" maxWidth="xl" className='StaffAccessReport-root'>

            <Grid container spacing={2}>

                <Grid item xs={12} sm={12}>
                    <strong>SELECCIONE UN EMPLEADO</strong>
                    <Select
                        value={selectedEmployee}
                        onChange={handleSelectedEmployeeChange}
                        options={employees}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    Desde:
                    <DatePicker
                        onChange={onChangeStartDate}
                        value={dateStartPicker}
                        locale="es-ES"
                        className='date-picket-container'
                        maxDate={dateEndPicker}
                        clearIcon={null}
                        calendarIcon={null}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    Hasta:
                    <DatePicker
                        onChange={onChangeEndDate}
                        value={dateEndPicker}
                        locale="es-ES"
                        className='date-picket-container'
                        maxDate={todayDate}
                        minDate={dateStartPicker}
                        clearIcon={null}
                        calendarIcon={null}
                    />
                </Grid>

                <Grid item xs={12}>
                    <h3 className='table-title'>REPORTE</h3>
                </Grid>

                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <BootstrapTable
                            remote
                            keyField='id'
                            data={ report }
                            columns={ columns }
                            rowStyle={ rowStyle }
                            classes='bootstrap-table'
                            wrapperClasses='bootstrap-table-wrapper'
                            headerClasses='bootstrap-table-header'
                            pagination={ paginationFactory(options) }
                            onTableChange={ (type, newState) => onTableChange(type, newState) }
                        />
                    </Grid>
                </Grid>

            
            </Grid>
          
        </Container>
        
    </Fragment>

}

export default StaffAccessReport;