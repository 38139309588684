import React from 'react';
import { Dialog, DialogContent, CircularProgress, withMobileDialog  } from '@material-ui/core';
import PropTypes from 'prop-types';

class Loading extends React.Component{
    state = {
        open: false,
        loading: true,
        success: null,
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render(){
        return(
            <Dialog
                fullScreen={false}
                fullWidth={true}
                open={this.props.loadingState}
                onClose={this.handleClose}
                aria-labelledby="responsive-dialog-title"
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                classes={{
                    paper: 'loadingBackgroundPaper',
                }}
            >
                <DialogContent className='loadingDialogContent'>
                    <div className={'loadingContentContainer'}>
                        {this.state.success ? 'Completado' : <img src={this.props.loadingImage} className={['loadingLogoImg'].join(' ')} alt="loadingImage"/>}
                        {<CircularProgress size={100}  className={['loadingCircleImg'].join(' ')}/>}
                    </div>
                    <div className='loadingText' ><span>{this.props.loadingText}</span></div>
                </DialogContent>
            </Dialog>
        )
    }
}

Loading.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(Loading);