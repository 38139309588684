import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";

class DialogComponent extends React.Component{
    render(){
        return(
            <Dialog onClose={this.props.handleClose} aria-labelledby="simple-dialog-title" open={this.props.open}>
                {this.props.messageType === 'SUCCESS' &&
                <React.Fragment>
                    <Grid container spacing={0} className='success-dialog-container'>
                        {this.props.message}
                    </Grid>
                </React.Fragment>
                }
            </Dialog>
        )
    }
}

export default DialogComponent;