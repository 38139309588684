import React from 'react';
import NotFoundComponent from "../../components/NotFoundComponent/NotFoundComponent";


class NotFound extends React.Component{
    render(){
        return(
            <React.Fragment>
                <NotFoundComponent/>
            </React.Fragment>
        )
    }
}

export default NotFound;