import React from 'react';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import DatePicker from 'react-date-picker';
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axiosClient from "../../services/apiClient";
import DialogComponent from "../DialogComponent/DialogComponent";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import NumberFormat from "react-number-format";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from '@material-ui/core/styles';
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import Logo from '../../images/Logo_flomecol.svg';
import {
    API_URL,
    GET_UNAUTHORIZED_REQUESTED_ORDERS,
    GET_REQUESTED_ORDERS_PDF,
    SEARCH_REQUESTED_ORDERS,
    UPDATE_UNAUTHORIZED_REQUESTED_ORDERS,
    DELETE_REQUEST_ORDER
} from "../../API";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { ALERT_REQUESTED_ORDERS_DAYS } from "../../SETTINGS";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputBase from "@material-ui/core/InputBase";

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#336636',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#336636',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#336636',
                borderWidth: '2px',
            },
            '&:hover fieldset': {
                borderColor: '#336636',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#336636',
            },
        },
    },
})(TextField);

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#336636',
    },
    tabsRoot: {
        borderBottom: '1px solid #1d1d1d',
    },
    tabsIndicator: {
        backgroundColor: '#ffffff',
        height: "4px",
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        fontFamily: "\"Montserrat\", sans-serif !important",
        fontSize: "18px",
        '&:hover': {
            color: '#ffffff',
            opacity: 5,
        },
        '&:focus': {
            color: '#ffffff',
        },
    },
    tabSelected: {},
    input: {
        display: 'none',
    },
    formControl: {
      width: '100%',
    },
    selectorLabel: {
      background: 'whitesmoke',
      padding: '0 5px'
    },
    autorizationCheckBoxContainer: {
      padding: '10px 20px',
      borderBottom: 'dashed 2px #336636',
      '&:last-child': {
        borderBottom: 'unset',
      },
    },
    autorizationCheckBoxListContainer: {
      margin: '5px',
      border: 'solid 2px #336636',
      borderRadius: '4px'
    },
    productsExpansionPanel: {
      display: 'block !important',
    },
    requestOderListProduct: {
      margin: '5px',
      padding: '10px',
      border: 'solid 2px #336636',
      borderRadius: '4px'
    }

});

const numberFormatCustom = props => {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalScale={0}
            thousandSeparator={","}
            decimalSeparator={"."}
            fixedDecimalScale={true}
            prefix="$ "
        />
    );
}

const ordersTypes = [
    'SIN_AUTORIZAR',
    'NO_CUPO',
    'CARTERA_PENDIENTE',
    'ACTUALIZACION_DOCUMENTOS',
    'AUTORIZADAS'
]

class RequestedOrdersPortfolioComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: {
                direct_discount: 0
            },
            isAuthorizationSuccessModalOpen: false,
            messageOrderCreatedSuccess: '',
            orders: [],
            tabValue: 0,
            isAuthorizationModalOpen: false,
            selectedOrder: {
                products: [],
                order_date: new Date(),
            },
            deletingOrder: false,
            authorizingOrder: false,
            searchText: '',

            options: {
                hideSizePerPage: true,
                page: 1,
                totalSize: 1,
                sizePerPage: 10,
                paginationSize: 1,
            },
            paginationData: {},
            loadingData: false,

            isQuotaAuthorized: false,
            isPortfolioAuthorized: false,
            isDocumentsAuthorized: false,
            isQuotaAuthorizedBy: null,
            isPortfolioAuthorizedBy: null,
            isDocumentsAuthorizedBy: null
        }
    }

    componentDidMount() {
      this._updateOrders();
    }

    onTableChange(type, newState){
        this.setState({loadingData:true})
        axiosClient.get(`${this.state.paginationData.path}?page=${newState.page}`).then((response) => {
            this.formatOrders(response)
            this.setState({loadingData:false})
        }).catch((error) => {
            this.setState({loadingData:false})
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message)
            } else {
                alert("ERROR: error de servidor cargando listado de ordenes de pedido.")
            }
        })
    }

    updatePagintationData(response){
        this.setState({
            paginationData: response.data,
            options: {
                ...this.state.options, ...{
                    page: response.data.current_page,
                    paginationSize: response.data.last_page < 10 ? response.data.last_page : 10,
                    totalSize: response.data.total
                }
            }
        })
    }

    filterOrders(){
        if(this.state.searchText === ''){
            this._updateOrders();
        }else if (localStorage.getItem('data')) {
            this.setState({loadingData:true})
            const data = JSON.parse(localStorage.getItem('data'));
            const id = data.rol === 'ventas' ? '/' + data.id : '';
            axiosClient.get(`${API_URL}${SEARCH_REQUESTED_ORDERS}/${ordersTypes[this.state.tabValue]}/${this.state.searchText}${id}`).then((response) => {
                this.formatOrders(response)
                this.setState({loadingData:false})
            }).catch((error) => {
                this.setState({loadingData:false})
                if (error.response && error.response.data && error.response.data.message) {
                    alert(error.response.data.message)
                } else {
                    alert("ERROR: error de servidor cargando listado de ordenes de pedido.")
                }
            })
        }
    }

    _updateOrders() {
        if (localStorage.getItem('data')) {
            this.setState({ orders: [], loadingData:true })
            const data = JSON.parse(localStorage.getItem('data'));
            const id = data.rol === 'ventas' ? '/' + data.id : '';
            axiosClient.get(`${API_URL}${GET_UNAUTHORIZED_REQUESTED_ORDERS}/${ordersTypes[this.state.tabValue]}${id}`).then((response) => {
                this.formatOrders(response)
                this.setState({loadingData:false})
            }).catch((error) => {
                this.setState({loadingData:false})
                if (error.response && error.response.data && error.response.data.message) {
                    alert(error.response.data.message)
                } else {
                    alert("ERROR: error de servidor cargando listado de ordenes de pedido.")
                }
            })
        }
    }

    formatOrders(response){
        this.updatePagintationData(response)
        let orders = [];
        response.data.data.forEach((requestedOrder) => {
            requestedOrder.order_date = new Date(requestedOrder.order_date);
            requestedOrder.order_date_to_show = this.formatDate(new Date(requestedOrder.order_date));

            requestedOrder.tentative_shipping_date = new Date(requestedOrder.tentative_shipping_date);
            requestedOrder.tentative_shipping_date_to_show = this.formatDate(new Date(requestedOrder.tentative_shipping_date));

            requestedOrder.total_price_with_discount_text = requestedOrder.total_price_with_discount;
            requestedOrder.total_price_with_discount = <NumberFormat
                value={requestedOrder.total_price_with_discount}
                displayType={'text'}
                prefix={'$'}
                decimalScale={0}
                thousandSeparator={","}
                decimalSeparator={"."}
                fixedDecimalScale={true}
            />;

            const actions = <React.Fragment>
              <a
                  href={API_URL + GET_REQUESTED_ORDERS_PDF + requestedOrder.id}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='download-order-pdf-eye'
              >
                <Button className='coin-button-table-disable'>
                    <CloudDownloadIcon />
                </Button>
              </a>
              {
                this.state.tabValue < 4 &&
                <Button
                    className='coin-button-table-disable'
                    onClick={this.openOrderAuthorizationModal.bind(this, requestedOrder)}
                >
                    <PlaylistAddCheckIcon />
                </Button>
              }
              {
                this.state.tabValue < 4 &&
                <Button
                    className='coin-button-table-disable'
                    onClick={this.deleteRequestOrder.bind(this, requestedOrder.id)}
                >
                    <DeleteForeverIcon />
                </Button>
              }

            </React.Fragment>

            requestedOrder.actions = actions;
            orders.push(requestedOrder);
        })

        this.setState({ orders })
    }

    deleteRequestOrder(id){
      if(window.confirm("¿Está seguro de que desea eliminar esta orden?. Esta es una operación destructiva y no es posible deshacerla.")){
        this.setState({ deletingOrder: true });
        axiosClient.delete(`${API_URL}${DELETE_REQUEST_ORDER}${id}`).then((response) => {
          this.setState({ deletingOrder: false });
          this._updateOrders();
          alert("Orden eliminada exitosamente.");
        }).catch((error) => {
          this.setState({ deletingOrder: false });
          if(error.response && error.response.data && error.response.data.message){
              alert(error.response.data.message)
          }else{
              alert("ERROR: error de servidor eliminando orden.")
          }
        })
      }
    }

    openOrderAuthorizationModal(requestedOrder) {
      this.setState({
        isQuotaAuthorized: requestedOrder.quota_authorized,
        isPortfolioAuthorized: requestedOrder.portfolio_authorized,
        isDocumentsAuthorized: requestedOrder.documents_authorized,
        isQuotaAuthorizedBy: requestedOrder.quota_authorized_by,
        isPortfolioAuthorizedBy: requestedOrder.portfolio_authorized_by,
        isDocumentsAuthorizedBy: requestedOrder.documents_authorized_by,
        selectedOrder: requestedOrder,
        isAuthorizationModalOpen: true
      })
    }

    formatDate(date) {
      if (date) {
        let monthNames = [
            "Enero", "Febrero", "Marzo",
            "Abril", "Mayo", "Junio", "Julio",
            "Agosto", "Septiembre", "Octubre",
            "Noviembre", "Diciembre"
        ];
        let day = date.getDate();
        let monthIndex = date.getMonth();
        let year = date.getFullYear();
        return day + ' ' + monthNames[monthIndex] + ' ' + year;
      } else {
        return '';
      }
    }

    orderHasPending(products) {
        let orderHasPending = false
        products.forEach((product) => {
            if (product.pending_quantity > 0) {
                //ordes has at least one pending products
                orderHasPending = true
            }
        })
        return orderHasPending
    }

    validateAuthorizationInputs() {
      const {
        quota_authorized,
        portfolio_authorized,
        documents_authorized
      } = this.state.selectedOrder;
      
      if(!quota_authorized && !portfolio_authorized && !documents_authorized) return true;

      if(this.state.isQuotaAuthorized === true && !this.state.isQuotaAuthorizedBy){
        alert('Debes selectionar quien autoriza el cupo')
        return false
      }
      if(this.state.isPortfolioAuthorized === true && !this.state.isPortfolioAuthorizedBy){
        alert('Debes selectionar quien autoriza estado actual de cartera')
        return false
      }
      if(this.state.isDocumentsAuthorized === true && !this.state.isDocumentsAuthorizedBy){
        alert('Debes selectionar quien autoriza el cupo')
        return false
      }
      return true
    }

    doAuthorizeOrder() {
      if(this.validateAuthorizationInputs()){
        this.setState({ authorizingOrder: true });
        const formData = {
          ...(this.state.isQuotaAuthorized === true && {
            quota_authorized: this.state.isQuotaAuthorized,
            quota_authorized_by: this.state.isQuotaAuthorizedBy
          }),
          ...(this.state.isPortfolioAuthorized === true && {
            portfolio_authorized: this.state.isPortfolioAuthorized,
            portfolio_authorized_by: this.state.isPortfolioAuthorizedBy
          }),
          ...(this.state.isDocumentsAuthorized === true && {
            documents_authorized: this.state.isDocumentsAuthorized,
            documents_authorized_by: this.state.isDocumentsAuthorizedBy
          })
        }
  
        axiosClient.put(`${API_URL}${UPDATE_UNAUTHORIZED_REQUESTED_ORDERS}/${this.state.selectedOrder.id}`, formData).then(() => {
          this.setState({ 
            authorizingOrder: false,
            isAuthorizationModalOpen: false
          });
          this._updateOrders();
        }).catch((error) => {
          this.setState({ authorizingOrder: false });
  
          if(error.response && error.response.data && error.response.data.message){
              alert(error.response.data.message)
          }else{
              alert("ERROR: error de servidor actualizando orden de pedido.")
          }
        })
      }
    }

    closeAuthorizationSucessModal() {
        this.setState({ isAuthorizationSuccessModalOpen: false })
    }

    handleTabChangeValue = (event, value) => {
        this.setState({ 
          tabValue: value
        }, () => {
            this._updateOrders();
        });
    };

    closeAuthorizationModal() {
        this.setState({ isAuthorizationModalOpen: false })
    }

    disableAuthorizationSelector(selectorId) {
      return(
        (selectorId === 'quota_authorized' && this.state.selectedOrder.quota_authorized_by) ||
        (selectorId === 'portfolio_authorized' && this.state.selectedOrder.portfolio_authorized_by) ||
        (selectorId === 'documents_authorized' && this.state.selectedOrder.documents_authorized_by)
      )
    }
  
    render() {

        const { classes } = this.props;

        const columns = [
            {
                dataField: 'id',
                text: '#',
            }, {
                dataField: 'client_name',
                text: 'Cliente',
            }, {
                dataField: 'business_name',
                text: 'Razón Social',
            }, {
                dataField: 'seller',
                text: 'Vendedor',
            }, {
                dataField: 'order_date_to_show',
                text: 'Orden',
            }, {
                dataField: 'tentative_shipping_date_to_show',
                text: 'Despacho',
            }, {
                dataField: 'total_price_with_discount',
                text: 'Precio Total',
            }, {
                dataField: 'actions',
                text: 'Acciones',
                classes: 'actions-custom-cell'
            }
        ];

        const rowStyle2 = (row, rowIndex) => {
            const style = {};
            style.color = '#000000';
            style.height = '45px';
            style.backgroundColor = 'white';
            style.cursor = 'pointer';
            return style;
        };

        const rowStyleOrders = (row, rowIndex) => {
            const style = {};
            if(!row) return null;
            let orderDate = row.tentative_shipping_date;
            orderDate.setHours(0, 0, 0);
            let today = new Date();
            today.setHours(0, 0, 0);
            let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            let diffDays = Math.round((orderDate - today) / oneDay);
            style.backgroundColor = diffDays > 0 && diffDays < ALERT_REQUESTED_ORDERS_DAYS ? 'rgba(55, 164, 62, 0.30)' : orderDate >= today ? 'white' : 'rgba(255, 24, 24, 0.30)';
            style.color = '#000000';
            style.height = '45px';
            style.cursor = 'pointer';
            
            return style;
        };

        const AutorizationCheckBox = props => {
          const {
            id: selectorId,
            checkboxValue,
            onCheckboxChange,
            selectValue,
            onSelectChange,
            label
          } = props;

          return <Grid container spacing={0} alignItems='center' className={classes.autorizationCheckBoxContainer} >
            <Grid item xs={12} md={6}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={this.disableAuthorizationSelector(selectorId)}
                      checked={checkboxValue}
                      onChange={(e) => onCheckboxChange(e.target.checked)}
                      color="primary"
                    />
                  }
                  label={label}
                />
              </FormGroup>
            </Grid>
            {
              this.state.selectedOrder.authorized_by && (
                <Grid item xs={12} md={6} disp>
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="is-quota-authorized-by-select-label" className={classes.selectorLabel}>
                      Quien autoriza
                    </InputLabel>
                    <Select
                      labelId="is-quota-authorized-by-select-label"
                      id="is-quota-authorized-by-select"
                      value={selectValue}
                      onChange={(e) => onSelectChange(e.target.value)}
                      disabled={this.disableAuthorizationSelector(selectorId) || !checkboxValue}
                    >
                      {
                        this.disableAuthorizationSelector(selectorId) && <MenuItem value={selectValue}>{selectValue}</MenuItem>
                      }
                      
                      <MenuItem value={'JONNY'}>JONNY</MenuItem>
                      <MenuItem value={'JERANDINNI'}>JERANDINNI</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )
            }
            
          </Grid>
        }

        return (
            <React.Fragment>
              <LoadingComponent loadingImage={Logo} loadingText={'Eliminando orden'} loadingState={this.state.deletingOrder} />
              <LoadingComponent loadingImage={Logo} loadingText={'Autorizando orden'} loadingState={this.state.authorizingOrder} />
              <LoadingComponent loadingImage={Logo} loadingText={'Cargando ordenes.'} loadingState={this.state.loadingData}/>

              <DialogComponent
                  handleClose={() => this.closeAuthorizationSucessModal()}
                  open={this.state.isAuthorizationSuccessModalOpen}
                  message={this.state.messageOrderCreatedSuccess}
                  messageType='SUCCESS'
              />

              <Modal
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  open={this.state.isAuthorizationModalOpen}
                  onClose={this.closeAuthorizationModal.bind(this)}
                  className='flomecolModalContainer'
              >
                  <div className='flomecolModalContent'>

                      <Grid container spacing={0}>
                          <Grid item xs={12}>
                              <div className='modalTitle'><h2>AUTORIZAR ORDEN DE PEDIDO #{this.state.selectedOrder.id}</h2></div>
                          </Grid>

                          <Grid item xs={12} md={12} className='inputTextFieldContainer'>
                              <ExpansionPanel className='flomecol-expansion-panel'>
                                  <ExpansionPanelSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                  >

                                    <Typography className={classes.heading}>Detalles</Typography>

                                  </ExpansionPanelSummary>
                                  <ExpansionPanelDetails>

                                      <Grid container spacing={0}>
                                          <Grid item xs={12} md={12} className='inputTextFieldContainer'>
                                              <TextField
                                                  id="outlined-name"
                                                  label="Vendedor"
                                                  className='inputTextField'
                                                  name='client_name'
                                                  value={this.state.selectedOrder.seller}
                                                  margin="normal"
                                                  variant="outlined"
                                                  disabled={true}
                                                  fullWidth
                                              />
                                          </Grid>

                                          <Grid item xs={12} md={9} className='inputTextFieldContainer'>
                                              <TextField
                                                  id="outlined-name"
                                                  label="Cliente"
                                                  className='inputTextField'
                                                  name='client_name'
                                                  value={this.state.selectedOrder.client_name}
                                                  margin="normal"
                                                  variant="outlined"
                                                  disabled={true}
                                                  fullWidth
                                              />
                                          </Grid>

                                          <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                              <span className='date-picker-label'>Fecha de orden</span>
                                              <DatePicker
                                                  dateFormat="MMMM - d / yyyy"
                                                  value={this.state.selectedOrder.order_date}
                                                  minDate={new Date()}
                                                  locale="es-ES"
                                                  disabled
                                                  calendarIcon={<CalendarTodayIcon />}
                                              />
                                          </Grid>

                                          <Grid item xs={12} md={9} className='inputTextFieldContainer'>
                                              <TextField
                                                  id="outlined-name"
                                                  label="Razón Social"
                                                  className='inputTextField'
                                                  name='business_name'
                                                  value={this.state.selectedOrder.business_name}
                                                  margin="normal"
                                                  variant="outlined"
                                                  disabled={true}
                                                  fullWidth
                                              />
                                          </Grid>

                                          <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                              <TextField
                                                  id="outlined-name"
                                                  label="NIT"
                                                  className='inputTextField'
                                                  name='nit'
                                                  value={this.state.selectedOrder.nit}
                                                  margin="normal"
                                                  variant="outlined"
                                                  disabled={true}
                                                  fullWidth
                                              />
                                          </Grid>

                                          <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                              <TextField
                                                  id="outlined-name"
                                                  label="Departamento"
                                                  className='inputTextField'
                                                  name='department'
                                                  value={this.state.selectedOrder.department}
                                                  margin="normal"
                                                  variant="outlined"
                                                  disabled={true}
                                                  fullWidth
                                              />
                                          </Grid>

                                          <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                              <TextField
                                                  id="outlined-name"
                                                  label="Ciudad"
                                                  className='inputTextField'
                                                  name='city'
                                                  value={this.state.selectedOrder.city}
                                                  margin="normal"
                                                  variant="outlined"
                                                  disabled={true}
                                                  fullWidth
                                              />
                                          </Grid>

                                          <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                              <TextField
                                                  id="outlined-name"
                                                  label="Barrio"
                                                  className='inputTextField'
                                                  name='neighborhood'
                                                  value={this.state.selectedOrder.neighborhood}
                                                  margin="normal"
                                                  variant="outlined"
                                                  disabled={true}
                                                  fullWidth
                                              />
                                          </Grid>

                                          <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                              <TextField
                                                  id="outlined-name"
                                                  label="Correo"
                                                  className='inputTextField'
                                                  name='email'
                                                  value={this.state.selectedOrder.email}
                                                  margin="normal"
                                                  variant="outlined"
                                                  disabled={true}
                                                  fullWidth
                                              />
                                          </Grid>

                                          <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                                              <TextField
                                                  id="outlined-name"
                                                  label="Dirección"
                                                  className='inputTextField'
                                                  name='address'
                                                  value={this.state.selectedOrder.address}
                                                  margin="normal"
                                                  variant="outlined"
                                                  disabled={true}
                                                  fullWidth
                                              />
                                          </Grid>

                                          <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                              <TextField
                                                  id="outlined-name"
                                                  label="Telefono 1"
                                                  className='inputTextField'
                                                  name='mobile1'
                                                  value={this.state.selectedOrder.mobile1}
                                                  margin="normal"
                                                  variant="outlined"
                                                  disabled={true}
                                                  fullWidth
                                              />
                                          </Grid>

                                          <Grid item xs={12} md={3} className='inputTextFieldContainer'>
                                              <TextField
                                                  id="outlined-name"
                                                  label="Telefono 2"
                                                  className='inputTextField'
                                                  name='mobile2'
                                                  value={this.state.selectedOrder.mobile2}
                                                  margin="normal"
                                                  variant="outlined"
                                                  disabled={true}
                                                  fullWidth
                                              />
                                          </Grid>

                                          <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                              <TextField
                                                  id="outlined-name"
                                                  label="Descuento directo (%)"
                                                  className='inputTextField'
                                                  name='direct_discount'
                                                  type='number'
                                                  value={this.state.selectedOrder.direct_discount}
                                                  disabled={true}
                                                  margin="normal"
                                                  variant="outlined"
                                                  fullWidth
                                              />
                                          </Grid>

                                          <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                              <TextField
                                                  id="outlined-name"
                                                  label="Descuento pronto pago (%)"
                                                  className='inputTextField'
                                                  name='discount_prompt_payment'
                                                  type='number'
                                                  value={this.state.selectedOrder.discount_prompt_payment}
                                                  margin="normal"
                                                  variant="outlined"
                                                  disabled={true}
                                                  fullWidth
                                              />
                                          </Grid>

                                          <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                              <TextField
                                                  id="outlined-name"
                                                  label="Descuento total (%)"
                                                  className='inputTextField'
                                                  name='total_discount'
                                                  type='number'
                                                  value={parseInt(this.state.selectedOrder.discount_prompt_payment) + parseInt(this.state.selectedOrder.direct_discount)}
                                                  disabled={true}
                                                  margin="normal"
                                                  variant="outlined"
                                                  fullWidth
                                              />
                                          </Grid>

                                          <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                                              <TextField
                                                  id="outlined-name"
                                                  label="Costo total con descuento"
                                                  className='inputTextField'
                                                  name='order_total_cost'
                                                  value={this.state.selectedOrder.total_price_with_discount_text}
                                                  margin="normal"
                                                  variant="outlined"
                                                  disabled={true}
                                                  fullWidth
                                              />
                                          </Grid>

                                          <Grid item xs={12} md={6} className='inputTextFieldContainer'>
                                              <TextField
                                                  id="outlined-name"
                                                  label="Costo total sin descuento"
                                                  className='inputTextField'
                                                  name='order_total_cost'
                                                  value={this.state.selectedOrder.total_price}
                                                  margin="normal"
                                                  variant="outlined"
                                                  disabled={true}
                                                  fullWidth
                                              />
                                          </Grid>

                                          <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                              <TextField
                                                  id="outlined-name"
                                                  label="Plazo de Pago"
                                                  className='inputTextField'
                                                  name='invoice_term'
                                                  value={this.state.selectedOrder.invoice_term}
                                                  margin="normal"
                                                  variant="outlined"
                                                  disabled={true}
                                                  fullWidth
                                              />
                                          </Grid>

                                          <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                              <span className='date-picker-label'>Fecha de pago</span>
                                              <DatePicker
                                                  dateFormat="MMMM - d / yyyy"
                                                  value={new Date(this.state.selectedOrder.payment_date)}
                                                  minDate={new Date()}
                                                  locale="es-ES"
                                                  calendarIcon={<CalendarTodayIcon />}
                                                  disabled
                                                  disableCalendar={true}
                                              />
                                          </Grid>

                                          <Grid item xs={12} md={4} className='inputTextFieldContainer'>
                                              <span className='date-picker-label'>Fecha de despacho</span>
                                              <DatePicker
                                                  dateFormat="MMMM - d / yyyy"
                                                  value={new Date(this.state.selectedOrder.tentative_shipping_date)}
                                                  minDate={new Date()}
                                                  locale="es-ES"
                                                  disabled
                                                  calendarIcon={<CalendarTodayIcon />}
                                              />
                                          </Grid>

                                          <Grid item xs={12} md={12} className='inputTextFieldContainer'>
                                              <TextField
                                                  id="outlined-multiline-static"
                                                  label="Observaciones Generales"
                                                  multiline
                                                  rows="4"
                                                  className='inputTextField'
                                                  margin="normal"
                                                  variant="outlined"
                                                  name='general_observations'
                                                  value={this.state.selectedOrder.general_observations}
                                                  disabled={true}
                                                  fullWidth
                                              />
                                          </Grid>

                                          <Grid item xs={12} className='inputTextFieldContainer'>
                                              <CssTextField
                                                  id="outlined-balance"
                                                  label="Cupo ($)"
                                                  className='inputTextField'
                                                  name='balance'
                                                  value={this.state.selectedOrder.balance}
                                                  margin="normal"
                                                  variant="outlined"
                                                  disabled={true}
                                                  fullWidth
                                                  InputProps={{
                                                      inputComponent: numberFormatCustom,
                                                      endAdornment: <InputAdornment position="end">COP</InputAdornment>,
                                                  }}
                                              />
                                          </Grid>

                                      </Grid>


                                  </ExpansionPanelDetails>
                              </ExpansionPanel>
                          </Grid>


                          <Grid item xs={12} className='inputTextFieldContainer'>
                            <ExpansionPanel className='flomecol-expansion-panel'>
                              <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                              >

                                <Typography className={classes.heading}>Productos</Typography>

                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails className={classes.productsExpansionPanel}>
                              {this.state.selectedOrder.products.map((product) => (
                                <Grid container spacing={0} xs={12} className={classes.requestOderListProduct} key={product.id}>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Nombre producto"
                                            className='inputTextField'
                                            name='list_product_name'
                                            value={product.product_name}
                                            margin="normal"
                                            variant="outlined"
                                            disabled={true}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Presentación"
                                            className='inputTextField'
                                            name='list_product_presentation'
                                            value={product.product_presentation}
                                            margin="normal"
                                            variant="outlined"
                                            disabled={true}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Precio"
                                            className='inputTextField'
                                            name='list_product_price'
                                            value={product.product_price}
                                            margin="normal"
                                            variant="outlined"
                                            disabled={true}
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Cantidad"
                                            className='inputTextField'
                                            name='list_product_quantity'
                                            type='number'
                                            value={product.quantity}
                                            disabled={true}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Bono"
                                            className='inputTextField'
                                            name='list_product_bonus_quantity'
                                            type='number'
                                            value={product.bonus_quantity}
                                            disabled={true}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={2} className='inputTextFieldContainer'>
                                        <TextField
                                            id="outlined-name"
                                            label="Precio Total"
                                            className='inputTextField'
                                            name='total_price'
                                            type='number'
                                            value={product.product_price * product.quantity}
                                            disabled={true}
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>

                                </Grid>
                              ))}

                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Grid>
                          
                          <Grid container spacing={0} alignItems='center' className={classes.autorizationCheckBoxListContainer}>

                            <AutorizationCheckBox
                              id='quota_authorized'
                              checkboxValue={this.state.isQuotaAuthorized}
                              onCheckboxChange={(e) => this.setState({isQuotaAuthorized: e, isQuotaAuthorizedBy: null})}
                              selectValue={this.state.isQuotaAuthorizedBy}
                              onSelectChange={(e) => this.setState({isQuotaAuthorizedBy: e})}
                              label='¿Tiene cupo disponible?'
                            />

                            <AutorizationCheckBox
                              id='portfolio_authorized'
                              checkboxValue={this.state.isPortfolioAuthorized}
                              onCheckboxChange={(e) => this.setState({isPortfolioAuthorized: e, isPortfolioAuthorizedBy: null})}
                              selectValue={this.state.isPortfolioAuthorizedBy}
                              onSelectChange={(e) => this.setState({isPortfolioAuthorizedBy: e})}
                              label='¿Esta al día con cartera?'
                            />

                            <AutorizationCheckBox
                              id='documents_authorized'
                              checkboxValue={this.state.isDocumentsAuthorized}
                              onCheckboxChange={(e) => this.setState({isDocumentsAuthorized: e, isDocumentsAuthorizedBy: null})}
                              selectValue={this.state.isDocumentsAuthorizedBy}
                              onSelectChange={(e) => this.setState({isDocumentsAuthorizedBy: e})}
                              label='¿Documentos al día?'
                            />

                          </Grid>

                          <Grid item xs={12} className='inputTextFieldContainer'>
                              <Button variant="contained" color="primary" onClick={this.doAuthorizeOrder.bind(this)} fullWidth>
                                  AUTORIZAR ORDEN
                              </Button>
                          </Grid>

                      </Grid>

                  </div>
              </Modal>

              <Grid container spacing={0} className='ModuleMainContainer'>

                  <Grid item xs={12}>
                      <div className="clients-search-box">
                          <InputBase
                              placeholder="BUSCAR ORDEN. . . "
                              classes={{
                                root: "clients-search-input-root",
                                input: "clients-search-input-input",
                              }}
                              value={this.state.searchText}
                              onChange={(e) => this.setState({searchText: e.target.value}, () => {
                                  this.filterOrders()
                              })}
                          />
                      </div>
                  </Grid>

                  <Grid item xs={12}>
                      <h3 className='table-title'>LISTADO DE ORDENES DE PEDIDO POR AUTORIZAR</h3>
                  </Grid>

                  <AppBar position="static" className={classes.root}>
                      <Tabs variant="fullWidth" value={this.state.tabValue} onChange={this.handleTabChangeValue} classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}>
                          <Tab label="NUEVAS" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
                          <Tab label="NO CUPO" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
                          <Tab label="CARTERA" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
                          <Tab label="DOCUMENTOS" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
                          <Tab label="AUTORIZADAS" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
                      </Tabs>
                  </AppBar>

                  <Grid container spacing={0}>
                      <Grid item xs={12}>
                          <BootstrapTable
                              remote
                              keyField='id'
                              data={this.state.orders}
                              columns={columns}
                              rowStyle={this.state.tabValue === 0 ? rowStyleOrders : rowStyle2}
                              classes='bootstrap-table'
                              wrapperClasses='bootstrap-table-wrapper'
                              headerClasses='bootstrap-table-header'
                              pagination={paginationFactory(this.state.options)}
                              onTableChange={ (type, newState) => this.onTableChange(type, newState) }
                          />
                      </Grid>
                  </Grid>

              </Grid>

            </React.Fragment>
        )
    }
}

export default withStyles(styles)(RequestedOrdersPortfolioComponent);