import React from 'react';
import HeaderComponent from "../../components/HeaderComponent/HeaderComponent";
import FooterComponent from "../../components/FooterComponent/FooterComponent";
import RequestedOrdersMetricsComponent from "../../components/RequestedOrdersMetricsComponent/RequestedOrdersMetricsComponent";

class RequestedOrdersMetrics extends React.Component{
    render(){
        return(
            <React.Fragment>
                <HeaderComponent/>
                <RequestedOrdersMetricsComponent/>
                <FooterComponent/>
            </React.Fragment>
        )
    }
}

export default RequestedOrdersMetrics;