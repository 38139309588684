import React from 'react';
import AllyDashboardComponent from "../../components/AllyDashboardComponent/AllyDashboardComponent";
import AllyHeaderComponent from "../../components/AllyHeaderComponent/AllyHeaderComponent";

class AllyDashboard extends React.Component{
    render(){
        return(
            <React.Fragment>
                <AllyHeaderComponent/>
                <AllyDashboardComponent/>
            </React.Fragment>
        )
    }
}

export default AllyDashboard;