import React, { Fragment } from 'react';
import HeaderComponent from "../../components/HeaderComponent/HeaderComponent";
import FooterComponent from "../../components/FooterComponent/FooterComponent";
import StaffAccessReport from "../../components/StaffAccessReport/StaffAccessReport";
import AccessControl from "../../hooks/AccessControl.js"
import NoAccessComponent from "../../components/NoAccessComponent/NoAccessComponent.js";

const StaffAccessReportLayout = () => {
    return(
      <React.Fragment>
        <AccessControl
          allowedRoles={['RH', 'administracion']}
          renderNoAccess={() => <NoAccessComponent/>}
        >
          <Fragment>
            <HeaderComponent/>
            <StaffAccessReport/>
            <FooterComponent/>
        </Fragment>
        </AccessControl>
      </React.Fragment>
  )
}

export default StaffAccessReportLayout;