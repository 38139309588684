import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axiosClient from "../../services/apiClient";
import {API_URL, USER_LOGIN} from "../../API";
import {Redirect} from "react-router-dom";

const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class LoginComponent extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            username: '',
            password: '',
            redirectToDashboard: false,
        }
    }

    componentDidMount() {
        localStorage.clear();
    }

    doLogin(){

        let formData = {
            username: this.state.username,
            password: this.state.password,
        }

        axiosClient.post(API_URL + USER_LOGIN, formData).then((response) => {
            if(response.data.success){
                let data = response.data.data;
                let serializedData = JSON.stringify(data);
                localStorage.setItem('data', serializedData);
                this.setState({redirectToDashboard: true})
            }else{
                alert("Credenciales invalidas.")
            }
        })
    }

    handleChange(event){
        this.setState({[event.target.name]:event.target.value})
    }

    redirectToDashboard = () => {
        if (this.state.redirectToDashboard) {
            return <Redirect push to='/dashboard' />
        }
    }

    render(){
        const { classes } = this.props;

        return (
            <React.Fragment>
                {this.redirectToDashboard()}
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5" align='center' >
                            SISTEMA DE INFORMACION FLOMECOL®
                        </Typography>
                        <form className={classes.form} noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Usuario"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                value={this.state.username}
                                onChange={this.handleChange.bind(this)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Contraseña"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={this.state.password}
                                onChange={this.handleChange.bind(this)}
                            />

                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                size="large"
                                onClick={this.doLogin.bind(this)}
                            >
                                Iniciar Sesión
                            </Button>
                            <Grid container>
                                <Grid item xs onClick={() => alert("Si olvidaste la contraseña de tu usuario, puedes contactar al área encargada para recuperar tu contraseña.")} >
                                    <Link href="#" variant="body2">
                                        Olvidaste tu contraseña?
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(LoginComponent);