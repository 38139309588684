import React from 'react';
import HeaderComponent from "../../components/HeaderComponent/HeaderComponent";
import FooterComponent from "../../components/FooterComponent/FooterComponent";
import CashReceiptsComponent from "../../components/CashReceiptsComponent/CashReceiptsComponent";

class CashReceipts extends React.Component{
    render(){
        return(
            <React.Fragment>
                <HeaderComponent/>
                <CashReceiptsComponent/>
                <FooterComponent/>
            </React.Fragment>
        )
    }
}

export default CashReceipts;