import React, { useState, useEffect } from 'react';
import './CashReceiptsComponent.css';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import axiosClient from "../../services/apiClient";
import DialogComponent from "../DialogComponent/DialogComponent";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import NumberFormat from "react-number-format";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import Logo from '../../images/Logo_flomecol.svg';
import ReceiptIcon from '@material-ui/icons/Receipt';
import {
    API_URL,
    CREATE_CASH_RECEIPT,
    GET_REQUESTED_ORDERS_RECEIPTS, 
    GET_REQUESTED_ORDERS_PDF, 
} from "../../API";
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import { formatDate, fromDateToString } from "../../utils/dates";
import InputBase from "@material-ui/core/InputBase";
import CreateCashReceiptComponentModal from '../CreateCashReceiptComponent/CreateCashReceiptComponentModal';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#336636',
    },
    tabsRoot: {
        borderBottom: '1px solid #1d1d1d',
    },
    tabsIndicator: {
        backgroundColor: '#ffffff',
        height: "4px",
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        fontFamily: "\"Montserrat\", sans-serif !important",
        fontSize: "18px",
        '&:hover': {
            color: '#ffffff',
            opacity: 5,
        },
        '&:focus': {
            color: '#ffffff',
        },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing.unit * 3,
    },
    button: {
        margin: theme.spacing.unit,
    },
    input: {
        display: 'none',
    },
}));

function NumberFormatCustom(props){
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalScale={0}
            thousandSeparator={","}
            decimalSeparator={"."}
            fixedDecimalScale={true}
            prefix="$ "
        />
    );
}

const CashReceipstComponent = () => {

    const classes = useStyles();

    const [value, setValue] = useState(0);

    const [currentRequestedOrder, setCurrentRequestedOrder] = useState(undefined);
    const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false);
    const [permissions, setPermissions] = useState({
        canView: false,
        canCreate: false,
    });
    const [redirectToDashboard, setRedirectToDashboard] = useState(false);
    const [newReceipt, setNewReceipt] = useState({
        total_amount: '',
        description: '',
        notes: '',
    })
    const [creatingCashReceipt, setCreatingCashReceipt] = useState(false);
    const [openReceiptCreatedSuccess, setOpenReceiptCreatedSuccess] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [searchText, setSearchText] = useState("");

    const [loadingData, setLoadingData] = useState(false);

    const [tabValue, setTabValue] = useState(0);

    const [ receipts, setReceipts ] = useState([]);

    const [ options, setOptions ] = useState({
        hideSizePerPage: true,
        page: 1,
        totalSize: 1,
        sizePerPage: 10,
        paginationSize: 1,
    });

    const [ paginationData, setPaginationData ] = useState({})

    const ordersTypes = [
        'WITH_REMAINING',
        'PAID'
    ]

    const columns = [
        {
            dataField: 'id',
            text: '# de orden',
            sort: true
        },{
            dataField: 'invoice_number',
            text: '# de factura',
            sort: true
        },{
            dataField: 'client_name',
            text: 'Cliente',
            sort: true
        },{
            dataField: 'business_name',
            text: 'Razón Social',
            sort: true
        },{
            dataField: 'seller',
            text: 'Vendedor',
            sort: true
        },{
            dataField: 'real_amount',
            text: 'Precio Real',
        },{
            dataField: 'total_amount_receipts',
            text: 'Pagado',
        },{
            dataField: 'remaining',
            text: 'Saldo',
        },{
            dataField: 'actions',
            text: 'Acciones',
            classes: 'actions-custom-cell'
        }
    ];

    const onTableChange = (type, newState) => {
        setLoadingData(true)
        axiosClient.get(`${paginationData.path}?page=${newState.page}`).then((response) => {
            setReceipts(formatReceipts(response))
            setLoadingData(false)
        }).catch((error) => {
            setLoadingData(false)
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message)
            } else {
                alert("ERROR: error de servidor cargando listado de ordenes de pedido.")
            }
        })
    }

    const updatePagintationData = (response) => {
        setPaginationData(response.data)
        setOptions({
            ...options, ...{
                page: response.data.current_page,
                paginationSize: response.data.last_page < 10 ? response.data.last_page : 10,
                totalSize: response.data.total
            }
        })
    }

    const doSearch = () => {
        if(searchText){
            filterReceiptsPaid();
        }else{
            getRequestedOrders();
        }
    }

    const filterReceiptsPaid = () => {
        if(searchText === ''){
            getRequestedOrders();
        }else if (localStorage.getItem('data')) {
            setLoadingData(true)
            axiosClient.get(`${GET_REQUESTED_ORDERS_RECEIPTS}/${ordersTypes[tabValue]}/${searchText}`).then((response) => {
                setReceipts(formatReceipts(response))
                setLoadingData(false)
            }).catch((error) => {
                setLoadingData(false)
                if (error.response && error.response.data && error.response.data.message) {
                    alert(error.response.data.message)
                } else {
                    alert("ERROR: error de servidor cargando listado de ordenes de pedido.")
                }
            })
        }
    }

    const rowStyle2 = (row, rowIndex) => {
        const style = {};
        style.color = '#000000';
        style.height = '45px';
        style.backgroundColor = 'white';
        style.cursor = 'pointer';

        return style;
    };

    useEffect(() => {
        handlePermissions();
    }, []);

    useEffect(() => {
        if(!permissions.canCreate && !permissions.canView) return;
        getRequestedOrders();
    }, [permissions, tabValue]);

    const handlePermissions = () => {
        if(localStorage.getItem('data')){

            let data = JSON.parse(localStorage.getItem('data'));

            switch (data.rol) {
                case 'administracion':
                    setPermissions({
                        canView: true,
                        canCreate: true,
                    });
                    break;
                case 'ventas':
                    setPermissions({
                        canView: false,
                        canCreate: false,
                    });
                    break;
                case 'facturacion':
                    setPermissions({
                        canView: false,
                        canCreate: false,
                    });
                    break;
                case 'control':
                    setPermissions({
                        canView: true,
                        canCreate: false,
                    });
                    break;
                case 'cartera':
                    setPermissions({
                        canView: true,
                        canCreate: true,
                    });
                    break;
                default:
                    break;
            }
        }else{
            setRedirectToDashboard(true);
        }
    }

    const clearModal = () => {
        setNewReceipt({
            total_amount: '',
            description: '',
            notes: '',
        });
    }

    const doCreateCashReceipt = () => {
        if(validateFields()){
            setCreatingCashReceipt(true);
            let formData = {
                city: currentRequestedOrder.client_city,
                date: fromDateToString(new Date()),
                received_from: currentRequestedOrder.client_name,
                nit: currentRequestedOrder.nit,
                business_name: currentRequestedOrder.business_name,
                business_city: currentRequestedOrder.client_city,
                business_department: currentRequestedOrder.department,
                business_neighborhood: currentRequestedOrder.neighborhood,
                total_amount: newReceipt.total_amount,
                remaining: currentRequestedOrder.aux_remaining - newReceipt.total_amount,
                description: newReceipt.description,
                notes: newReceipt.notes,
                is_signed_by_seller: true,
                is_signed_by_client: false,
                client_id: currentRequestedOrder.client_id,
                seller: currentRequestedOrder.seller,
                requested_order_id: currentRequestedOrder.id,
            }

            const error_message = "ERROR: error de servidor creando recibo de caja.";
            
            axiosClient.post(API_URL + CREATE_CASH_RECEIPT, formData).then((response) =>{
                setCreatingCashReceipt(false);
                if(response.data){
                    if(response.data.success){
                        let receipt_id = response.data.data.id;
                        getRequestedOrders();
                        setCurrentRequestedOrder(undefined);
                        setIsReceiptModalOpen(false);
                        clearModal();
                        setOpenReceiptCreatedSuccess(true);
                        setDialogMessage(<div>RECIBO DE CAJA CREADO CORRECTAMENTE: <br/> <strong># {receipt_id}</strong></div>);
                        
                    }else{
                        alert(error_message)
                    }
                }else{
                    alert(error_message)
                }
            }).catch((error) => {
                setCreatingCashReceipt(false)
                alert(error_message)
            })
        }
    }

    const validateFields = () =>{
        return validateClientField('client_city', 'ciudad') &&
        validateClientField('client_name', 'recibido de') &&
        validateClientField('nit', 'NIT') &&
        validateClientField('business_name', 'razón social') &&
        validateTotalAmount() &&
        validateDescription() &&
        validateNotes();
    }

    const validateClientField = (field, fieldName) => {
        const value = currentRequestedOrder[field];
        if(!value){
            alert("El campo '" + fieldName + "' debe ser agregado al cliente en el modulo de clientes.")
            return false;
        }        
        return true;
    }

    const validateTotalAmount = () => {
        if(!newReceipt.total_amount || newReceipt.total_amount === '' || newReceipt.total_amount < 0){
            alert("Debes llenar el valor del recibo de caja.")
            return false;
        }

        if( newReceipt.total_amount > currentRequestedOrder.aux_remaining ){
            alert("El valor ingresado no puede ser mayor al saldo de la factura.")
            return false;
        }
        
        return true;   
    }

    const validateDescription = () => {
        if(!newReceipt.description || newReceipt.description === ''){
            alert("Debes llenar el concepto por el cual se genera este recibo de caja.")
            return false;
        }else{
            return true;
        }
    }

    const validateNotes = () => {
        if(!newReceipt.notes || newReceipt.notes === ''){
            return window.confirm('Esta seguro de enviar este recibo de caja sin observaciones?');
        }else{
            return true;
        }
    }

    const formatReceipts = (response) => {
        updatePagintationData(response)
        let receipts_array = [];
        let data_ = response.data.data;

        if(!Array.isArray(data_)){
            data_ = Object.keys(data_).map(key => {
                return data_[key];
            })
        }

        data_.forEach((requestedOrder) => {
            requestedOrder.order_date = new Date(requestedOrder.order_date);
            requestedOrder.order_date_to_show = formatDate(new Date(requestedOrder.order_date));

            requestedOrder.tentative_shipping_date = new Date(requestedOrder.tentative_shipping_date);
            requestedOrder.tentative_shipping_date_to_show = formatDate(new Date(requestedOrder.tentative_shipping_date));

            const total_amount_receipts = requestedOrder.total_amount_receipts;
            
            requestedOrder.total_amount_receipts = <NumberFormat
                value={total_amount_receipts}
                displayType={'text'}
                prefix={'$'}
                decimalScale={0}
                thousandSeparator={","}
                decimalSeparator={"."}
                fixedDecimalScale={true}
            />;

            requestedOrder.aux_remaining = requestedOrder.real_amount - total_amount_receipts;
            
            requestedOrder.remaining = <NumberFormat
                value={requestedOrder.real_amount - total_amount_receipts}
                displayType={'text'}
                prefix={'$'}
                decimalScale={0}
                thousandSeparator={","}
                decimalSeparator={"."}
                fixedDecimalScale={true}
            />;

            requestedOrder.total_price_with_discount_text = requestedOrder.total_price_with_discount;

            requestedOrder.total_price_with_discount = <NumberFormat
                value={requestedOrder.total_price_with_discount}
                displayType={'text'}
                prefix={'$'}
                decimalScale={0}
                thousandSeparator={","}
                decimalSeparator={"."}
                fixedDecimalScale={true}
            />;

            requestedOrder.real_amount_aux = requestedOrder.real_amount;

            requestedOrder.real_amount = <NumberFormat
                value={requestedOrder.real_amount}
                displayType={'text'}
                prefix={'$'}
                decimalScale={0}
                thousandSeparator={","}
                decimalSeparator={"."}
                fixedDecimalScale={true}
            />;

            if(requestedOrder.status === 'CON_PENDIENTES' || requestedOrder.status === 'DESPACHADA'){
                
                let actions = <React.Fragment>
                    {
                        permissions.canView &&
                            <a
                                href={API_URL + GET_REQUESTED_ORDERS_PDF + requestedOrder.id}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='download-order-pdf-eye'
                            >
                                <Button
                                    className='coin-button-table-disable'
                                >
                                    <RemoveRedEye/>
                                </Button>

                            </a>
                    }

                    {
                        permissions.canCreate &&
                            <Button
                                className='coin-button-table-disable'
                                onClick={() => createReceipt(requestedOrder)}
                            >
                                <ReceiptIcon/>
                            </Button>
                    }

                </React.Fragment>
                requestedOrder.actions = actions;

            }

            receipts_array.push(requestedOrder)

        })
        
        return receipts_array;
    }

    const getRequestedOrders = () => {
        if (localStorage.getItem('data')) {
            setLoadingData(true)
            axiosClient.get(`${GET_REQUESTED_ORDERS_RECEIPTS}/${ordersTypes[tabValue]}`).then((response) => {
                setReceipts(formatReceipts(response))
                setLoadingData(false)
            }).catch((error) => {
                setLoadingData(false)
                if (error.response && error.response.data && error.response.data.message) {
                    alert(error.response.data.message)
                } else {
                    alert("ERROR: error de servidor cargando listado de ordenes de pedido.")
                }
            })
        }
    }

    const createReceipt = (requestedOrder) => {
        setCurrentRequestedOrder(requestedOrder);
        setIsReceiptModalOpen(true);
    }

    const handleChangeValue = (event, value) => {
        setSearchText('');
        setTabValue(value);
    };

    const handleNewReceiptChange = (e) => {
        setNewReceipt({ ...newReceipt, [e.target.name]: e.target.value })
    }

    const handleNewReceiptTotalAmountChange = (e) => {
        let total_amount = parseFloat(e.target.value.substring(2).replace(/,/g, ''));
        setNewReceipt({ ...newReceipt, total_amount })
    }

    return(
        <React.Fragment>
            { redirectToDashboard && <Redirect push to='/dashboard' /> }
            <LoadingComponent loadingImage={Logo} loadingText={'Creando nuevo recibo de caja'} loadingState={creatingCashReceipt}/>
            <LoadingComponent loadingImage={Logo} loadingText={'Cargando ordenes.'} loadingState={loadingData}/>

            <DialogComponent
                handleClose={() => setOpenReceiptCreatedSuccess(false)}
                open={openReceiptCreatedSuccess}
                message={dialogMessage}
                messageType='SUCCESS'
            />

            <Grid container spacing={0} className='ModuleMainContainer'>

                <Grid item xs={12} sm={9}>
                    <div className="clients-search-box">
                        <InputBase
                            placeholder="BUSCAR ORDEN. . . "
                            classes={{
                                root: "clients-search-input-root",
                                input: "clients-search-input-input",
                            }}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} sm={3} className='inputTextFieldContainerCash'>
                    <Button variant="contained" color="primary" onClick={doSearch} fullWidth>
                        BUSCAR
                    </Button>
                </Grid>
                

                <Grid item xs={12}>
                    <h3 className='table-title'>LISTADO DE ORDENES DE PEDIDO - RECIBOS DE CAJA</h3>
                </Grid>

                <AppBar position="static" className={classes.root}>
                    <Tabs variant="fullWidth" value={tabValue} onChange={handleChangeValue} classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}>
                        <Tab label="CON SALDO POR PAGAR" classes={{ root: classes.tabRoot, selected: classes.tabSelected }}  />
                        <Tab label="PAGADAS" classes={{ root: classes.tabRoot, selected: classes.tabSelected }}  />
                    </Tabs>
                </AppBar>

                {   tabValue === 0 &&

                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <BootstrapTable
                                remote
                                keyField='id'
                                data={receipts}
                                columns={columns}
                                rowStyle={rowStyle2}
                                classes='bootstrap-table'
                                wrapperClasses='bootstrap-table-wrapper'
                                headerClasses='bootstrap-table-header'
                                pagination={paginationFactory(options)}
                                onTableChange={ (type, newState) => onTableChange(type, newState) }
                            />
                        </Grid>
                    </Grid>
                }

                {   tabValue === 1 &&

                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <BootstrapTable
                                remote
                                keyField='id'
                                data={receipts}
                                columns={columns}
                                rowStyle={rowStyle2}
                                classes='bootstrap-table'
                                wrapperClasses='bootstrap-table-wrapper'
                                headerClasses='bootstrap-table-header'
                                pagination={paginationFactory(options)}
                                onTableChange={ (type, newState) => onTableChange(type, newState) }
                            />
                        </Grid>
                    </Grid>
                }

            </Grid>

            {
              currentRequestedOrder && <CreateCashReceiptComponentModal
                value={value}
                isReceiptModalOpen={isReceiptModalOpen}
                setIsReceiptModalOpen={setIsReceiptModalOpen}
                currentRequestedOrder={currentRequestedOrder}
                rowStyle2={rowStyle2}
                paginationFactory={paginationFactory}
                newReceipt={newReceipt}
                handleNewReceiptTotalAmountChange={handleNewReceiptTotalAmountChange}
                NumberFormatCustom={NumberFormatCustom}
                handleNewReceiptChange={handleNewReceiptChange}
                doCreateCashReceipt={doCreateCashReceipt}
                permissions={permissions}
              />
            }
            
        </React.Fragment>
    )
}

export default CashReceipstComponent;