import React from 'react';
import HeaderComponent from "../../components/HeaderComponent/HeaderComponent";
import FooterComponent from "../../components/FooterComponent/FooterComponent";
import ViaticosComponent from "../../components/ViaticosComponent/ViaticosComponent";


const Viaticos = (props) => {
    return (
        <>
            <HeaderComponent/>
            <ViaticosComponent/>
            <FooterComponent/>
        </>
    )
}

export default Viaticos