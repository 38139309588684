import React from 'react';
import HeaderComponent from "../../components/HeaderComponent/HeaderComponent";
import HomeComponent from "../../components/HomeComponent/HomeComponent";
import FooterComponent from "../../components/FooterComponent/FooterComponent";

class Home extends React.Component{
    render(){
        return(
            <React.Fragment>
                <HeaderComponent/>
                <HomeComponent/>
                <FooterComponent/>
            </React.Fragment>
        )
    }
}

export default Home;