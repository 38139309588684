import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { getUser } from '../../utils/auth';

const HomeController = () => {

    const [ isAdmin, setIsAdmin ] = useState(false);
    const [ isRH, setIsRH ] = useState(false);

    const history = useHistory();

    useEffect(() => {
        //TODO: Handle this with HOC for protect routes
        const localUser = getUser();
        if(!localUser) history.push("/");
        setIsAdmin(localUser.rol === 'administracion')
        setIsRH(localUser.rol === 'RH')
    }, [])

    return {
        isAdmin,
        isRH
    }
}

export default HomeController;