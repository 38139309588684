export const API_URL = process.env.REACT_APP_API_ENDPOINT;
//export const API_URL = 'http://localhost:8000/api';

export const CREATE_CLIENT = '/clients'; // POST
export const READ_CLIENTS = '/clients'; // GET
export const READ_CLIENTS_BY_SELLER_NAME = '/clients/getBySellerName'; // POST
export const UPDATE_CLIENT = '/clients/'; // PUT /{id}
//export const DELETE_CLIENT = '/clients/'; // DELETE /{id}requestedOrders

export const READ_PRODUCTS = '/products'; // GET
export const READ_SELLERS = '/requestedOrders/getSellers'; // GET
export const CREATE_PRODUCT = '/products'; //POST
export const UPDATE_PRODUCT = '/products/'; //POST
export const CHANGE_PRODUCT_STATUS = '/products-update-status/'; //PUT
export const DELETE_PRODUCT = '/products/'; //DELETE

export const USER_LOGIN = '/login'; // POST
export const ALLY_LOGIN = '/clients/ally-login'; //POST

export const CREATE_REQUEST_ORDER = '/requestedOrders'; //POST
export const UPDATE_REQUEST_ORDER = '/requestedOrders/'; //PUT
export const DELETE_REQUEST_ORDER = '/requestedOrders/'; //DELETE
export const UPDATE_REQUEST_ORDER_STATUS = '/requestedOrders/update-status/'; //PUT

export const GET_REQUESTED_ORDERS = '/requestedOrdersList'; //GET
export const SEARCH_REQUESTED_ORDERS = '/requestedOrdersSearch'; //GET
export const GET_UNAUTHORIZED_REQUESTED_ORDERS = '/requested-orders-list-unauthorized'; //GET
export const UPDATE_UNAUTHORIZED_REQUESTED_ORDERS = '/requested-orders-update-unauthorized'; //PUT
export const GET_REQUESTED_ORDERS_PDF = '/requestedOrdersPDF/'; //GET
export const GET_REQUESTED_SHIPPED_ORDERS_PDF = '/requestedOrdersShippedPDF/'; //GET

export const GET_ALLY_REQUESTED_ORDERS = '/requestedOrders/ally/'; //GET

export const GET_REQUESTED_ORDERS_KPI_BY_SELLER = '/KPI/requestedOrders'; //POST

export const GET_REQUESTED_ORDERS_KPI_SELLERS = '/KPI/SellersRequestedOrders'; //POST
export const REVOKE_CLIENT_LOGIN_ACCESS = '/clients/revoke-login-access/'; //PUT
export const ACTIVATE_CLIENT_LOGIN_ACCESS = '/clients/activate-login-access/'; //PUT

export const GET_USER_BY_NAME = '/users/get-by-name'; //POST

export const CREATE_VIATICS_REQUEST = '/viatics-requests'; //POST
export const GET_VIATICS_REQUEST = '/viatics-requests'; //GET
export const GET_VIATICS_REQUEST_BY_OWNER = '/viatics-requests/getByOwner/'; //GET
export const GET_VIATICS_REQUEST_ZONES = '/viatics-requests/getZones/'; //GET
export const DO_MANAGEMENT_SIGN_VIATICS_REQUEST = '/viatics-requests/doManagementSign'; //POST
export const DO_SALES_SIGN_VIATICS_REQUEST = '/viatics-requests/doSalesSign'; //POST
export const DO_ACCOUNTING_SIGN_VIATICS_REQUEST = '/viatics-requests/doAccountingSign'; //POST
export const GET_VIATICS_REQUEST_PDF = '/viatics-requests-PDF/'; //GET

export const GET_ALL_CITIES = '/cities';
export const ACCESS_CONTROL = API_URL+'/access-control';
export const ACCESS_CONTROL_ENTRY = API_URL+'/access-control-entry';

export const CREATE_CASH_RECEIPT = '/cash-receipts'; //POST
export const GET_CASH_RECEIPT_BY_ORDER = '/cash-receipts-by-order/'; // GET
export const GET_REQUESTED_ORDERS_RECEIPTS = `${API_URL}/requested-orders-receipts`; //GET
export const GET_REQUESTED_ORDER_RECEIPTS_PDF = '/requested-orders-receipts-pdf/'; //GET

export const GET_EMPLOYEES = `${API_URL}/employees`; //GET
export const GET_EMPLOYEE_REPORT = `${API_URL}/employee-report`; //GET
export const GET_EMPLOYEES_REPORT = `${API_URL}/employees-report`; //GET

export const CREATE_PRODUCTION_CONTROL = `${API_URL}/production-control`; //POST
export const GET_ALL_PRODUCTION_CONTROL = `${API_URL}/production-control`; //GET
export const SEARCH_PRODUCTION_CONTROL = `${API_URL}/production-control-search`; //GET