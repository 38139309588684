import React from 'react';
import Grid from "@material-ui/core/Grid";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import List from "@material-ui/core/List";
import {Link, Redirect} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';


class AllyDashboardComponent extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            redirectToAllyLogin: false,
        }
    }

    componentDidMount() {
        this._handleRole();
    }

    _handleRole(){
        if(localStorage.getItem('ally') === null){
            this.setState({redirectToAllyLogin: true})
        }
    }

    redirectToAllyLogin = () => {
        if (this.state.redirectToAllyLogin) {
            return <Redirect push to='/aliados' />
        }
    }

    render(){
        return(
            <React.Fragment>
                {this.redirectToAllyLogin()}
                <Grid container>
                    <Grid  item xs={12} sm={4} className='HomeComponentCardContainer'>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <CardContent>
                                    <div><FormatListNumberedIcon className='HomeComponentCardIcons'/></div>
                                    <Typography gutterBottom variant="h5" component="h2" className='HomeComponentCardTitle'>
                                        ORDENES DE PEDIDO
                                    </Typography>
                                </CardContent>

                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className='HomeComponentCardExpansion'>
                                <div className='HomeComponentCardList'>
                                    <List component="nav" aria-label="Main mailbox folders">
                                        <Link to='ordenes-pedido-aliado' className='HomeComponentCardListLink'>
                                            <ListItem button>
                                                <ListItemIcon>
                                                    <KeyboardArrowRightIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Ordenes de Pedido" />
                                            </ListItem>
                                        </Link>
                                    </List>
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

export default AllyDashboardComponent;