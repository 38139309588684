import React from 'react';
import StaffAccessComponent from "../../components/StaffAccessComponent/StaffAccessComponent";


class StaffAccess extends React.Component{
    render(){
        return(
            <React.Fragment>
                <StaffAccessComponent/>
            </React.Fragment>
        )
    }
}

export default StaffAccess;