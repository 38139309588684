import React from 'react';
import DatePicker from 'react-date-picker';
import Grid from "@material-ui/core/Grid";
import axiosClient from "../../services/apiClient";
import {API_URL, GET_REQUESTED_ORDERS_KPI_BY_SELLER, GET_REQUESTED_ORDERS_KPI_SELLERS, READ_SELLERS} from "../../API";
import Select from "react-select";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import NumberFormat from "react-number-format";
import {Bar} from "react-chartjs-2";

class RequestedOrdersMetricsComponent extends React.Component{

    constructor(props){
        super(props);
        let todayDate = new Date();
        let yesterdayDate = new Date();
        yesterdayDate.setDate(yesterdayDate.getDate()-30);
        this.state = {
            rol: '',
            name: '',
            dateFrom: null,
            dateTo: null,
            sellers: [],
            selectedSeller: '',
            KPISellerRequestedOrders: {
                seller: "",
                total_orders_amount: null,
                total_orders_count: 0,
                total_orders_not_shipped_amount: null,
                total_orders_not_shipped_count: 0,
                total_orders_shipped_amount: null,
                total_orders_shipped_count: 0,
            },
            KPIAllSellersRequestedOrders: [],
            barLabels: [],
            barTotalOrders: [],
            barDispatchedOrders: [],
            barNotDispatchedOrders: [],
            barTotalOrdersAmount: [],
            barDispatchedOrdersAmount: [],
            barNotDispatchedOrdersAmount: [],
            todayDate:  new Date(todayDate.setHours(0,0,0,0)),
            dateStartPicker: new Date(yesterdayDate.setHours(0,0,0,0)),
            dateEndPicker:  new Date(todayDate.setHours(0,0,0,0)),
        }
    }

    componentDidMount() {
        this._handlePermissions();
        this.getKPISellers();
    }

    _handlePermissions(){
        if(localStorage.getItem('data')){
            let data = JSON.parse(localStorage.getItem('data'));
            //administracion - ventas - facturacion - control
            this.setState({rol:data ? data.rol : ''})
            this.setState({name:data ? data.name : ''}, () => {
                this._getSellers();
            })
        }
    }

    _getSellers(){
      axiosClient.get(API_URL + READ_SELLERS).then((response) => {
            let sellers = [];

            response.data.forEach((seller) => {
                let new_seller = {
                    label:seller,
                    value:seller,
                }

                sellers.push(new_seller)
            })

            this.setState({sellers})
        })
    }

    getKPI(){
        if(this.state.selectedSeller && this.state.selectedSeller.value){
            let to = new Date();
            to.setDate(this.state.dateEndPicker.getDate() + 1);
            let formData = {
                seller: this.state.selectedSeller.value,
                from: this.state.dateStartPicker.toISOString(),
                to: to.toISOString(),
            }
            axiosClient.post(API_URL + GET_REQUESTED_ORDERS_KPI_BY_SELLER, formData).then((response) => {
                this.setState({KPISellerRequestedOrders : response.data })
            }).catch(() => {
                alert("Error cargando metricas")
            })
        }
    }

    getKPISellers(){
        //GET_REQUESTED_ORDERS_KPI_SELLERS

        let to = new Date();
        to.setDate(this.state.dateEndPicker.getDate() + 1);
        let formData = {
            from: this.state.dateStartPicker.toISOString(),
            to: to.toISOString(),
        }
        axiosClient.post(API_URL + GET_REQUESTED_ORDERS_KPI_SELLERS, formData).then((response) => {
            let barLabels = [];
            let barTotalOrders = [];
            let barDispatchedOrders = [];
            let barNotDispatchedOrders = [];
            let barTotalOrdersAmount = [];
            let barDispatchedOrdersAmount = [];
            let barNotDispatchedOrdersAmount = [];

            response.data.forEach((label) => {
                barLabels.push(label.seller)

                barTotalOrders.push(label.total_orders_count ? label.total_orders_count : 0)
                barDispatchedOrders.push(label.total_orders_shipped_count ? label.total_orders_shipped_count : 0)
                barNotDispatchedOrders.push(label.total_orders_not_shipped_count ? label.total_orders_not_shipped_count : 0)

                barTotalOrdersAmount.push(label.total_orders_amount)
                barDispatchedOrdersAmount.push(label.total_orders_shipped_amount)
                barNotDispatchedOrdersAmount.push(label.total_orders_not_shipped_amount)

            })

            this.setState({
                barLabels,
                barTotalOrders,
                barDispatchedOrders,
                barNotDispatchedOrders,
                barTotalOrdersAmount,
                barDispatchedOrdersAmount,
                barNotDispatchedOrdersAmount,
                KPIAllSellersRequestedOrders : response.data
            })
        }).catch(() => {
            alert("Error cargando metricas")
        })

    }

    onChangeStartDate = dateStartPicker => {
        let oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
        let diff = Math.round(Math.abs((this.state.dateEndPicker.getTime() - dateStartPicker.getTime())/(oneDay)));

        if(diff === 0){
            alert("No puedes tener la misma fecha en los campos 'Desde:' y 'Hasta'");
        }else if(diff < 0){
            alert("El campo de fecha 'Desde:' debe ser menor al campo de fecha 'Hasta'");
        }else{
            this.setState({ dateStartPicker }, () => {
                this.getKPI();
                this.getKPISellers();
            })
        }
    }

    onChangeEndDate = dateEndPicker => {
        let oneDay = 24*60*60*1000;
        let diff = Math.round(Math.abs((dateEndPicker.getTime() - this.state.dateStartPicker.getTime())/(oneDay)));
        if(diff === 0){
            alert("No puedes tener la misma fecha en los campos 'Desde:' y 'Hasta'");
        }else if(diff < 0){
            alert("El campo de fecha 'Desde:' debe ser menor al campo de fecha 'Hasta'");
        }else{
            this.setState({ dateEndPicker }, () => {
                this.getKPI();
                this.getKPISellers();
            })
        }
    }

    handleSelectedSellerChange = selectedSeller => {
        this.setState({ selectedSeller }, () => {
            this.getKPI();
        });
    }

    render(){

        const options = {
            // Elements options apply to all of the options unless overridden in a dataset
            // In this case, we are setting the border of each bar to be 2px wide and green
            elements: {
                rectangle: {
                    borderWidth: 1,
                    borderColor: '#336636',
                    borderSkipped: 'bottom'
                }
            },
            responsive: true,
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: 'Comparativo de vendedores'
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                    }
                }]
            }
        }

        return(
            <React.Fragment>
                <Grid container spacing={0} className='ModuleMainContainer'>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                Desde:
                                <DatePicker
                                    onChange={this.onChangeStartDate}
                                    value={this.state.dateStartPicker}
                                    locale="es-ES"
                                    className='date-picket-container'
                                    maxDate={this.state.dateEndPicker}
                                    clearIcon={null}
                                    calendarIcon={null}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                Hasta:
                                <DatePicker
                                    onChange={this.onChangeEndDate}
                                    value={this.state.dateEndPicker}
                                    locale="es-ES"
                                    className='date-picket-container'
                                    maxDate={this.state.todayDate}
                                    minDate={this.state.dateStartPicker}
                                    clearIcon={null}
                                    calendarIcon={null}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className='chart-bar-container'>
                        <Bar
                            data={{
                                labels: this.state.barLabels,
                                datasets: [
                                    {
                                        label: 'Cantidad de ordenes totales',
                                        backgroundColor: "#336636",
                                        data: this.state.barTotalOrders
                                    }, {
                                        label: 'Cantidad de ordenes despachadas',
                                        backgroundColor: "rgba(255, 251, 206,1)",
                                        data: this.state.barDispatchedOrders
                                    }, {
                                        label: 'Cantidad de ordenes sin despachar',
                                        backgroundColor: "red",
                                        data: this.state.barNotDispatchedOrders
                                    }
                                ]


                            }}

                            options={options}
                        />
                    </Grid>

                    <Grid item xs={12} className='chart-bar-container'>
                        <Bar
                            data={{
                                labels: this.state.barLabels,
                                datasets: [
                                    {
                                        label: 'Monto ordenes totales',
                                        backgroundColor: "#336636",
                                        data: this.state.barTotalOrdersAmount
                                    }, {
                                        label: 'Monto ordenes despachadas',
                                        backgroundColor: "rgba(255, 251, 206,1)",
                                        data: this.state.barDispatchedOrdersAmount
                                    }, {
                                        label: 'Monto ordenes sin despachar',
                                        backgroundColor: "red",
                                        data: this.state.barNotDispatchedOrdersAmount
                                    }
                                ]
                            }}
                            options={options}
                        />
                    </Grid>



                    <Grid item xs={12} sm={12} className='inputTextFieldContainer'>
                        <strong className='create-requested-order-product-select'>SELECCIONE UN VENDEDOR</strong>
                        <Select
                            value={this.state.selectedSeller}
                            onChange={this.handleSelectedSellerChange}
                            options={this.state.sellers}
                            disabled={true}
                        />

                    </Grid>



                    {
                        this.state.selectedSeller &&
                        <Grid container spacing={3} className='metrics-card-container'>
                            <Grid item xs={12} sm={4}>
                                <Card>
                                    <CardActionArea>
                                        <CardContent className='metrics-card-content'>
                                            <h2>ORDENES DE PEDIDO TOTALES</h2>
                                            <h1>{this.state.KPISellerRequestedOrders.total_orders_count}</h1>
                                            <h3><NumberFormat
                                                value={this.state.KPISellerRequestedOrders.total_orders_amount ? this.state.KPISellerRequestedOrders.total_orders_amount : 0}
                                                displayType={'text'}
                                                prefix={'$'}
                                                decimalScale={0}
                                                thousandSeparator={","}
                                                decimalSeparator={"."}
                                                fixedDecimalScale={true}
                                            /></h3>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Card>
                                    <CardActionArea>
                                        <CardContent className='metrics-card-content'>
                                            <h2>ORDENES DE PEDIDO DESPACHADAS</h2>
                                            <h1>{this.state.KPISellerRequestedOrders.total_orders_shipped_count}</h1>
                                            <h3><NumberFormat
                                                value={this.state.KPISellerRequestedOrders.total_orders_shipped_amount ? this.state.KPISellerRequestedOrders.total_orders_shipped_amount : 0}
                                                displayType={'text'}
                                                prefix={'$'}
                                                decimalScale={0}
                                                thousandSeparator={","}
                                                decimalSeparator={"."}
                                                fixedDecimalScale={true}
                                            /></h3>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Card>
                                    <CardActionArea>
                                        <CardContent className='metrics-card-content'>
                                            <h2>ORDENES DE PEDIDO SIN DESPACHAR</h2>
                                            <h1>{this.state.KPISellerRequestedOrders.total_orders_not_shipped_count}</h1>
                                            <h3><NumberFormat
                                                value={this.state.KPISellerRequestedOrders.total_orders_not_shipped_amount ? this.state.KPISellerRequestedOrders.total_orders_not_shipped_amount : 0}
                                                displayType={'text'}
                                                prefix={'$'}
                                                decimalScale={0}
                                                thousandSeparator={","}
                                                decimalSeparator={"."}
                                                fixedDecimalScale={true}
                                            /></h3>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>

                        </Grid>
                    }


                </Grid>

            </React.Fragment>
        )
    }
}

export default RequestedOrdersMetricsComponent;