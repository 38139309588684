import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import axiosClient from "../../services/apiClient";
import Logo from "../../images/Logo_flomecol.svg";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { ACCESS_CONTROL, ACCESS_CONTROL_ENTRY } from "../../API";
import { message_responses } from "../../MESSAGE_RESPONSES";
import './StaffAccessComponent.css';
import FastForwardIcon from '@material-ui/icons/FastForward';
import PanToolIcon from '@material-ui/icons/PanTool';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import FastRewindIcon from '@material-ui/icons/FastRewind';

const useStyles = makeStyles((theme) => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const StaffAccess = () => {
    const classes = useStyles();
    const radius = 1500;
    // const radius = Number.POSITIVE_INFINITY;
    const [ code, setCode ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ loading, setLoading ] = useState('');
    const [ geoLocation, setGeoLocation ] = useState({});
    const [ access, setAccess ] = useState(false);
    const [ currentEmployee, setCurrentEmployee ] = useState(null);
    const [ lastAccess, setLastAccess ] = useState(null);
    const [ currentStep, setCurrentStep ] = useState(1);
    //const accessPoint = {lat: 4.6235648, lng: -74.1965824};
    const accessPoint = { lat: 4.708702, lng: -74.092866 };
    const [ accessTypes, setAccessTypes ] = useState([
        {
            name: 'INICIO DE JORNADA',
            icon: <FastForwardIcon className='accessTypeIcon'/>,
            value: 'START_WORKING_DAY',
            isVisible: false,
        },
        {
            name: 'LAVADO DE MANOS',
            icon: <PanToolIcon className='accessTypeIcon'/>,
            value: 'HANDS_CLEANING',
            isVisible: false,
        },
        {
            name: 'INICIO DE PAUSA ACTIVA',
            icon: <AccessTimeIcon className='accessTypeIcon'/>,
            value: 'START_ACTIVE_BREAKS',
            isVisible: false,
        },
        {
            name: 'FINALIZACIÓN DE PAUSA ACTIVA',
            icon: <WatchLaterIcon className='accessTypeIcon'/>,
            value: 'END_ACTIVE_BREAKS',
            isVisible: false,
        },
        {
            name: 'INICIO DE BREAK',
            icon: <AccessTimeIcon className='accessTypeIcon'/>,
            value: 'START_BREAK',
            isVisible: false,
        },
        {
            name: 'FINALIZACIÓN DE BREAK',
            icon: <WatchLaterIcon className='accessTypeIcon'/>,
            value: 'END_BREAK',
            isVisible: false,
        },
        {
            name: 'INICIO DE ALMUERZO',
            icon: <AccessTimeIcon className='accessTypeIcon'/>,
            value: 'START_LUNCH',
            isVisible: false,
        },
        {
            name: 'FINALIZACIÓN DE ALMUERZO',
            icon: <WatchLaterIcon className='accessTypeIcon'/>,
            value: 'END_LUNCH',
            isVisible: false,
        },
        {
            name: 'FINALIZACIÓN DE JORNADA',
            icon: <FastRewindIcon className='accessTypeIcon'/>,
            value: 'END_WORKING_DAY',
            isVisible: false,
        }
    ]);

    useEffect(() => {
        checkLocation();
    }, []);

    useEffect(() => {
        if(geoLocation.lat && geoLocation.lng){
            if(calculateDistanceInMeters(accessPoint, geoLocation) < radius){
                setAccess(true);
            }else{
                alert("No puedes hacer el registro fuera de la empresa");
                setAccess(false);
            }
        }
    }, [geoLocation]);

    useEffect(() => {
        if(!lastAccess) return
        let availableTypes = [];
        switch(lastAccess.type){
            case 'START_WORKING_DAY':
                availableTypes = ['HANDS_CLEANING', 'START_ACTIVE_BREAKS', 'START_BREAK', 'START_LUNCH', 'END_WORKING_DAY'];
                break;
            case 'HANDS_CLEANING':
                availableTypes = ['HANDS_CLEANING', 'START_ACTIVE_BREAKS', 'START_BREAK', 'START_LUNCH', 'END_WORKING_DAY'];
                break;
            case 'START_ACTIVE_BREAKS':
                availableTypes = ['END_ACTIVE_BREAKS'];
                break;
            case 'END_ACTIVE_BREAKS':
                availableTypes = ['HANDS_CLEANING', 'START_ACTIVE_BREAKS', 'START_BREAK', 'START_LUNCH', 'END_WORKING_DAY'];
                break;
            case 'START_BREAK':
                availableTypes = ['END_BREAK'];
                break;
            case 'END_BREAK':
                availableTypes = ['HANDS_CLEANING', 'START_ACTIVE_BREAKS', 'START_BREAK', 'START_LUNCH', 'END_WORKING_DAY'];
                break;
            case 'START_LUNCH':
                availableTypes = ['END_LUNCH'];
                break;
            case 'END_LUNCH':
                availableTypes = ['HANDS_CLEANING', 'START_ACTIVE_BREAKS', 'START_BREAK', 'START_LUNCH', 'END_WORKING_DAY'];
                break;
            case 'END_WORKING_DAY':
            case '':
                availableTypes = ['START_WORKING_DAY'];
                break;
        }

        let newAccessTypes = accessTypes.map((type) => {
            type.isVisible = availableTypes.includes(type.value);
            return type
        });

        setAccessTypes(newAccessTypes);

    }, [lastAccess]);

    const checkRegister = () => {
        if(access && geoLocation.lat && geoLocation.lng){
            setLoading(true)
            const formData = {
                code,
                password
            };
            axiosClient.post(ACCESS_CONTROL, formData).then((response) => {
                setLoading(false)
                if(response.data.success){
                    setCurrentEmployee(response.data.data.employee);
                    setLastAccess(response.data.data.access_control);
                    setCurrentStep(2);
                    
                }else{
                    alert(message_responses[response.data.message]);
                    setCurrentEmployee(null);
                    setLastAccess(null);
                }
            }).catch((error) => {
                setLoading(false)
                if(error.response && error.response.data && error.response.data.message){
                    alert(error.response.data.message === 'VALIDATOR_ERROR' ? error.response.data.data.error_message : message_responses[error.response.data.message])
                }else{
                    alert("ERROR: error de servidor validando acceso.")
                }
            })
        }else{
            alert("No puedes hacer el registro fuera de la empresa");
        }
    }

    const sentAccess = (type, name) => {

        const employeeName = currentEmployee.firstName + " " + currentEmployee.lastName;

        if(window.confirm(`¿ ${employeeName} estas seguro de hacer un nuevo registro de tipo: ${name}`)){
            if(access && geoLocation.lat && geoLocation.lng){
                setLoading(true)
                const formData = {
                    code,
                    type,
                };
                axiosClient.post(ACCESS_CONTROL_ENTRY, formData).then((response) => {
                    setLoading(false)
                    if(response.data.success){
                        const name = response.data.data.employee.firstName + " " + response.data.data.employee.lastName;
                        const date = response.data.data.access_control.created_at.substring(0, 10);
                        const time = response.data.data.access_control.created_at.substring(11, 20);
                        alert(name + ", tu registro ha sido guardado exitosamente. FECHA: " + date + " HORA: " + time );
    
                        setCurrentEmployee(null);
                        setLastAccess(null);
                        setCurrentStep(1);
                        setAccessTypes([
                            {
                                name: 'INICIO DE JORNADA',
                                icon: <FastForwardIcon className='accessTypeIcon'/>,
                                value: 'START_WORKING_DAY',
                                isVisible: false,
                            },
                            {
                                name: 'LAVADO DE MANOS',
                                icon: <PanToolIcon className='accessTypeIcon'/>,
                                value: 'HANDS_CLEANING',
                                isVisible: false,
                            },
                            {
                                name: 'INICIO DE PAUSA ACTIVA',
                                icon: <AccessTimeIcon className='accessTypeIcon'/>,
                                value: 'START_ACTIVE_BREAKS',
                                isVisible: false,
                            },
                            {
                                name: 'FINALIZACIÓN DE PAUSA ACTIVA',
                                icon: <WatchLaterIcon className='accessTypeIcon'/>,
                                value: 'END_ACTIVE_BREAKS',
                                isVisible: false,
                            },
                            {
                                name: 'INICIO DE BREAK',
                                icon: <AccessTimeIcon className='accessTypeIcon'/>,
                                value: 'START_BREAK',
                                isVisible: false,
                            },
                            {
                                name: 'FINALIZACIÓN DE BREAK',
                                icon: <WatchLaterIcon className='accessTypeIcon'/>,
                                value: 'END_BREAK',
                                isVisible: false,
                            },
                            {
                                name: 'INICIO DE ALMUERZO',
                                icon: <AccessTimeIcon className='accessTypeIcon'/>,
                                value: 'START_LUNCH',
                                isVisible: false,
                            },
                            {
                                name: 'FINALIZACIÓN DE ALMUERZO',
                                icon: <WatchLaterIcon className='accessTypeIcon'/>,
                                value: 'END_LUNCH',
                                isVisible: false,
                            },
                            {
                                name: 'FINALIZACIÓN DE JORNADA',
                                icon: <FastRewindIcon className='accessTypeIcon'/>,
                                value: 'END_WORKING_DAY',
                                isVisible: false,
                            }
                        ]);
    
    
    
                    }else{
                        alert(message_responses[response.data.message]);
                    }
                }).catch((error) => {
                    setLoading(false)
                    if(error.response && error.response.data && error.response.data.message){
                        alert(error.response.data.message === 'VALIDATOR_ERROR' ? error.response.data.data.error_message : message_responses[error.response.data.message])
                    }else{
                        alert("ERROR: error de servidor registrando acceso.")
                    }
                })
            }else{
                alert("No puedes hacer el registro fuera de la empresa");
            }
        }

    }

    const checkLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(setGeoLocationCallBack);
        } else { 
            alert("Geolocation is not supported by this browser.")
        }
    }

    const setGeoLocationCallBack = (position) => {
        setGeoLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        })
    }

    //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in m)
    const calculateDistanceInMeters = (accessPoint, geoLocation) => {
        let lat1 = accessPoint.lat;
        let lon1 = accessPoint.lng;
        let lat2 = geoLocation.lat;
        let lon2 = geoLocation.lng;

        const R = 6371e3; // meters

        const dLat = toRad(lat2-lat1);
        const dLon = toRad(lon2-lon1);
        lat1 = toRad(lat1);
        lat2 = toRad(lat2);

        const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        const d = R * c;
        return d;
    }

    // Converts numeric degrees to radians
    const toRad = (Value) => {
        return Value * Math.PI / 180;
    }

    return(
        <React.Fragment>
            <LoadingComponent loadingImage={Logo} loadingText={'Registrando Acceso.'} loadingState={loading}/>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>

                    {
                        currentEmployee && 
                        <Typography component="h1" variant="h5" align='center' >
                            {`Hola ${currentEmployee.firstName} ${currentEmployee.lastName}`}
                        </Typography>
                    }
                    
                    <Typography component="h1" variant="h5" align='center' >
                        ACCESSO DE PERSONAL FLOMECOL® | PREVENCIÓN COVID-19
                    </Typography>

                    {
                        currentStep === 1 &&
                        <form className={classes.form} noValidate>

                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="code"
                                label="Codigo de acceso"
                                id="username"
                                autoComplete="username"
                                value={code}
                                onChange={ (e) => setCode(e.target.value)}
                            />

                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Contraseña"
                                type="password"
                                id="password"
                                autoComplete="password"
                                value={password}
                                onChange={ (e) => setPassword(e.target.value)}
                            />

                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                size="large"
                                onClick={() => checkRegister()}
                            >
                                Registrar Acceso
                            </Button>
                        </form>

                    }
                    
                </div>
            </Container>

            {
                currentStep === 2 &&
                <Container component="main" maxWidth="xl">
                    <Grid container spacing={4} justify="space-around" alignItems="center" className='accessTypesContainer'>
                        <div className='accessTypesTitle'>-- TIPO DE REGISTRO --</div>
                        {
                            accessTypes.map((type) => (
                                <AccessTypeButton 
                                    name={type.name} 
                                    value={type.value} 
                                    icon={type.icon}
                                    isVisible={type.isVisible}
                                    sentAccess={sentAccess}
                                />
                            ))
                        }
                    </Grid>
                </Container>
            }

        </React.Fragment>
    )   
}

const AccessTypeButton = props => {
    const { name, value, icon, isVisible, sentAccess } = props;
    if(!isVisible) return null
    return <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={value} onClick={() => sentAccess(value, name)}>
            <div className='accessTypeButtonContainer'>
                <div>{icon}</div>
                <div className='accessTypeButtonName'>{name}</div>
            </div>
        </Grid>
}

export default StaffAccess;